// src/utils/formatCurrency.js

export const formatCurrency = (value) => {
    // Default to 0 if value is undefined or null
    const amount = value !== undefined && value !== null ? value : 0;
  
    // Ensure the amount is treated as a number
    const numberValue = typeof amount === 'string' ? parseFloat(amount) : Number(amount);
  
    // Format as currency
    if (isNaN(numberValue)) return '$0.00';
    return `$${numberValue.toFixed(2)}`;
  };
  