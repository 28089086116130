// src/utils/jobUtils.js

import { formatRecurrenceRule } from './formatRecurrenceRule';
import { doc, setDoc, serverTimestamp, collection } from 'firebase/firestore';
import { formatCurrency } from './formatCurrency';

export const saveJob = async (db, orgId, jobData, jobId = null) => {
  const jobRef = jobId
    ? doc(db, 'organizations', orgId, 'jobs', jobId)
    : doc(collection(db, 'organizations', orgId, 'jobs'));

  await setDoc(jobRef, {
    ...jobData,
    // serviceCost: parseFloat(jobData.serviceCost) || 0,
    updatedAt: serverTimestamp(),
  }, { merge: true }); // Merge to preserve existing fields
};

export const normalizeJobs = (jobs) => {
    return jobs.map(job => ({
      ...job,
      serviceCost: formatCurrency(job.serviceCost), // Format as currency
      serviceType: job.recurring ? 'Recurring' : 'One-Time',
      startTime: job.appointmentDate
        ? new Date(job.appointmentDate.toDate()).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        : 'N/A',
      endTime: job.scheduledEndTime
        ? new Date(job.scheduledEndTime.toDate()).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        : 'N/A',
      formattedRecurrence: job.recurring && job.recurrenceRule
        ? formatRecurrenceRule(job.recurrenceRule)
        : 'No recurrence',
    }));
  };
