// /src/components/MobileHeader.js
import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const MobileHeader = ({ title, showBack = true }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 10,
        backgroundColor: 'background.paper',
        borderBottom: '1px solid',
        borderColor: 'divider',
        px: 2,
        py: 1.5,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        marginBottom: 2,
        borderRadius: 2
      }}
    >
      {showBack && (
        <IconButton onClick={() => navigate(-1)} edge="start">
          <ArrowLeft />
        </IconButton>
      )}
      <Typography variant="h6" component="h1" sx={{ flex: 1 }}>
        {title}
      </Typography>
    </Box>
  );
};

export default MobileHeader;