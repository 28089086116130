// src/components/EditRecurringService.js

import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Typography,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Divider,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { collection, doc, updateDoc, getDocs, query, where, writeBatch } from 'firebase/firestore';
import { db } from '../firebase';
import { useJobContext } from '../context/JobContext';
import moment from 'moment';
import RecurrenceSelect from './RecurrenceSelect';
import { rrulestr } from 'rrule';
import AddRoomsModal from './AddRoomsModal';
import styles from '../styles/EditRecurringService.module.css';
import { Autocomplete } from '@react-google-maps/api';
import { usePlaces } from '../hooks/usePlaces';

const libraries = ['places'];

const EditRecurringService = ({ customer, jobs, orgId, fetchJobs, recurrenceGroupId, service }) => {
    const [isBillingSameAsService, setIsBillingSameAsService] = useState(
        customer.billingAddress === customer.serviceAddress
      );
      
      const [customerData, setCustomerData] = useState({
        phone: customer.phone || '',
        email: customer.email || '',
        serviceAddress: customer.serviceAddress || '',
        billingAddress: customer.billingAddress || customer.serviceAddress || '',
      });
      
      // For service address Autocomplete
      const [serviceSearchBox, setServiceSearchBox] = useState(null);
      
      // For billing address Autocomplete
      const [billingSearchBox, setBillingSearchBox] = useState(null);

      const [serviceData, setServiceData] = useState({
        serviceType: jobs[0]?.serviceType || 'recurring',
        price: jobs[0]?.serviceCost || jobs[0]?.price || service?.serviceCost || service?.price || 0,
      });

  const [scheduleData, setScheduleData] = useState({
    startTime: '', // Will populate in useEffect
    endTime: '',
    recurrenceRule: jobs[0]?.recurrenceRule || '',
  });

  const [cleanerId, setCleanerId] = useState(jobs[0]?.cleanerId || '');
  const [rooms, setRooms] = useState(jobs[0]?.rooms || []);
  const [notes, setNotes] = useState(jobs[0]?.notes || '');
  const [importantNotes, setImportantNotes] = useState(jobs[0]?.importantNotes || '');
  const [cleaners, setCleaners] = useState([]);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [openAddRoomsModal, setOpenAddRoomsModal] = useState(false);
  const [totalSize, setTotalSize] = useState(jobs[0]?.totalSize || service?.totalSize || 0);


  const { fetchRecurringInstances } = useJobContext();

  // Google Places setup
  const [searchBox, setSearchBox] = useState(null);
  const { handlePlaceSelect } = usePlaces();

  // Service Address Handlers
  const onServicePlacesLoad = (searchBoxInstance) => {
    setServiceSearchBox(searchBoxInstance);
  };
  
  const onServicePlaceChanged = () => {
    if (serviceSearchBox) {
      const place = serviceSearchBox.getPlace();
      if (place.formatted_address) {
        setCustomerData((prev) => ({
          ...prev,
          serviceAddress: place.formatted_address,
          billingAddress: isBillingSameAsService ? place.formatted_address : prev.billingAddress,
        }));
      }
    }
  };
  
  // Billing Address Handlers
  const onBillingPlacesLoad = (searchBoxInstance) => {
    setBillingSearchBox(searchBoxInstance);
  };
  
  const onBillingPlaceChanged = () => {
    if (billingSearchBox) {
      const place = billingSearchBox.getPlace();
      if (place.formatted_address) {
        setCustomerData((prev) => ({
          ...prev,
          billingAddress: place.formatted_address,
        }));
      }
    }
  };

  // Fetch cleaners and available rooms
  useEffect(() => {
    const fetchData = async () => {
      // Fetch cleaners
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('orgId', '==', orgId), where('role', '==', 'cleaner'));
      const querySnapshot = await getDocs(q);
      const cleanersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCleaners(cleanersList);

      // Fetch available rooms
      const roomTypesRef = collection(db, 'organizations', orgId, 'roomTypes');
      const roomTypesSnapshot = await getDocs(roomTypesRef);
      const roomTypesList = roomTypesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAvailableRooms(roomTypesList);
    };

    fetchData();
  }, [orgId]);

  // Populate scheduleData with start and end times
  useEffect(() => {
    if (jobs.length > 0) {
      const appointmentDate = jobs[0]?.appointmentDate?.toDate();
      const scheduledEndTime = jobs[0]?.scheduledEndTime?.toDate();
      if (appointmentDate && scheduledEndTime) {
        setScheduleData(prev => ({
          ...prev,
          startTime: moment(appointmentDate).format('HH:mm'),
          endTime: moment(scheduledEndTime).format('HH:mm'),
        }));
      }
    }
  }, [jobs]);

  // Initialize cleanerId after cleaners are fetched
  useEffect(() => {
    if (cleaners.length > 0 && !cleanerId) {
        setCleanerId(jobs[0]?.cleanerId || cleaners[0].id);
        }
    }, [cleaners]);

  const handleAddRooms = (roomsToAdd) => {
    setRooms(prevRooms => {
      const updatedRooms = [...prevRooms];
      roomsToAdd.forEach(newRoom => {
        const formattedTasks = (newRoom.tasks || []).map(task => ({
          description: typeof task === 'string' ? task : task.description,
          completed: false,
        }));

        const roomToAdd = {
          ...newRoom,
          tasks: formattedTasks,
          quantity: newRoom.quantity || 1,
        };

        const existingIndex = updatedRooms.findIndex(room => room.name === newRoom.name);
        if (existingIndex !== -1) {
          updatedRooms[existingIndex] = {
            ...updatedRooms[existingIndex],
            ...roomToAdd,
            tasks: roomToAdd.tasks.length > 0 ? roomToAdd.tasks : updatedRooms[existingIndex].tasks,
            quantity: roomToAdd.quantity || updatedRooms[existingIndex].quantity || 1,
          };
        } else {
          updatedRooms.push(roomToAdd);
        }
      });

      return updatedRooms;
    });
  };

  const handleRemoveRoom = (index) => {
    setRooms(prevRooms => prevRooms.filter((_, i) => i !== index));
  };

  const handleSaveChanges = async () => {
    try {
      // Update customer data
      const customerRef = doc(db, 'organizations', orgId, 'customers', customer.id);
      await updateDoc(customerRef, {
        phone: customerData.phone,
        email: customerData.email,
        serviceAddress: customerData.serviceAddress,
        billingAddress: customerData.billingAddress,
        updatedAt: new Date(),
      });
  
      // Determine if recurrence pattern changed
      const recurrenceChanged = scheduleData.recurrenceRule !== jobs[0]?.recurrenceRule;
  
      // Update jobs in the recurrence group
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const q = query(jobsRef, where('recurrenceGroupId', '==', recurrenceGroupId));
      const querySnapshot = await getDocs(q);
      const batch = writeBatch(db);
  
      // If service type changed from 'recurring' to 'one-time' or vice versa
      if (serviceData.serviceType !== jobs[0]?.serviceType || recurrenceChanged) {
        // Handle service type change
        const confirmChange = window.confirm(
          'You have changed the service type or recurrence pattern. This will regenerate future appointments. Do you want to proceed?'
        );
  
        if (confirmChange) {
          // Delete future jobs
          querySnapshot.docs.forEach(docSnap => {
            batch.delete(docSnap.ref);
          });
  
          // Create new jobs based on new service type and recurrence rule
          if (serviceData.serviceType === 'one-time') {
            // Create a single job
            const jobRef = doc(jobsRef);
            const newJobData = createJobData(); // Function to create job data object
            batch.set(jobRef, newJobData);
          } else {
            // Generate recurring jobs based on new recurrence rule
            const newJobsData = generateRecurringJobs(); // Function to generate jobs array
            newJobsData.forEach(jobData => {
              const jobRef = doc(jobsRef);
              batch.set(jobRef, jobData);
            });
          }
        } else {
          // Abort save operation
          return;
        }
      } else {
        // Update existing jobs
        querySnapshot.docs.forEach(docSnap => {
          const jobRef = docSnap.ref;
          const jobData = docSnap.data();
  
          // Calculate new appointmentDate and scheduledEndTime
          const appointmentDate = jobData.appointmentDate.toDate();
          const newStartTime = moment(appointmentDate).set({
            hour: parseInt(scheduleData.startTime.split(':')[0], 10),
            minute: parseInt(scheduleData.startTime.split(':')[1], 10),
            second: 0,
            millisecond: 0,
          });
          const newEndTime = moment(appointmentDate).set({
            hour: parseInt(scheduleData.endTime.split(':')[0], 10),
            minute: parseInt(scheduleData.endTime.split(':')[1], 10),
            second: 0,
            millisecond: 0,
          });
  
          batch.update(jobRef, {
            serviceType: serviceData.serviceType,
            price: serviceData.price,
            serviceCost: serviceData.price,
            appointmentDate: newStartTime.toDate(),
            scheduledEndTime: newEndTime.toDate(),
            scheduledDuration: newEndTime.diff(newStartTime, 'milliseconds'),
            cleanerId: cleanerId,
            cleanerName: cleaners.find(c => c.id === cleanerId)?.firstName || '',
            totalSize: totalSize,
            rooms: rooms,
            notes: notes,
            importantNotes: importantNotes,
            updatedAt: new Date(),
            recurrenceRule: scheduleData.recurrenceRule,
          });
        });
      }
  
      // Commit batch update
      await batch.commit();
  
      // Optionally, refetch jobs to update state
      await fetchJobs();
  
      alert('Service updated successfully!');
    } catch (error) {
      console.error('Error updating service:', error);
      alert('Failed to update service. Please try again.');
    }
  };

  const createJobData = () => {
    const appointmentDate = moment().set({
      hour: parseInt(scheduleData.startTime.split(':')[0], 10),
      minute: parseInt(scheduleData.startTime.split(':')[1], 10),
      second: 0,
      millisecond: 0,
    }).toDate();
  
    const scheduledEndTime = moment(appointmentDate).set({
      hour: parseInt(scheduleData.endTime.split(':')[0], 10),
      minute: parseInt(scheduleData.endTime.split(':')[1], 10),
    }).toDate();


    return {
      customerId: customer.id,
      customerName: customer.name,
      serviceType: serviceData.serviceType,
      price: serviceData.price,
      appointmentDate: appointmentDate,
      scheduledEndTime: scheduledEndTime,
      scheduledDuration: scheduledEndTime - appointmentDate,
      cleanerId: cleanerId,
      cleanerName: cleaners.find(c => c.id === cleanerId)?.firstName || '',
      totalSize: totalSize,
      rooms: rooms,
      notes: notes,
      importantNotes: importantNotes,
      createdAt: new Date(),
      updatedAt: new Date(),
      recurring: false,
      recurrenceRule: null,
      recurrenceGroupId: null,
    };
  };
  
  const generateRecurringJobs = () => {
    const jobsData = [];
    const rule = rrulestr(scheduleData.recurrenceRule);
    const dates = rule.all(); // Adjust as needed (e.g., limit number of occurrences)
  
    dates.forEach(date => {
      const appointmentDate = moment(date).set({
        hour: parseInt(scheduleData.startTime.split(':')[0], 10),
        minute: parseInt(scheduleData.startTime.split(':')[1], 10),
        second: 0,
        millisecond: 0,
      }).toDate();
  
      const scheduledEndTime = moment(appointmentDate).set({
        hour: parseInt(scheduleData.endTime.split(':')[0], 10),
        minute: parseInt(scheduleData.endTime.split(':')[1], 10),
      }).toDate();
  
      jobsData.push({
        customerId: customer.id,
        customerName: customer.name,
        serviceType: serviceData.serviceType,
        price: serviceData.price,
        appointmentDate: appointmentDate,
        scheduledEndTime: scheduledEndTime,
        scheduledDuration: scheduledEndTime - appointmentDate,
        cleanerId: cleanerId,
        cleanerName: cleaners.find(c => c.id === cleanerId)?.firstName || '',
        totalSize: totalSize,
        rooms: rooms,
        notes: notes,
        importantNotes: importantNotes,
        createdAt: new Date(),
        updatedAt: new Date(),
        recurring: true,
        recurrenceRule: scheduleData.recurrenceRule,
        recurrenceGroupId: recurrenceGroupId,
      });
    });
  
    return jobsData;
  };

  return (
    <Box className={styles.container}>
      {/* Customer Information Section */}
      <Box className={styles.section}>
        <Typography variant="h6">Customer Information</Typography>
        <TextField
          label="Phone Number"
          value={customerData.phone}
          onChange={(e) => setCustomerData({ ...customerData, phone: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          value={customerData.email}
          onChange={(e) => setCustomerData({ ...customerData, email: e.target.value })}
          fullWidth
          margin="normal"
        />
        {/* Service Address with Autocomplete */}
        <Autocomplete
        onLoad={onServicePlacesLoad}
        onPlaceChanged={onServicePlaceChanged}
        >
        <TextField
            label="Service Address"
            value={customerData.serviceAddress}
            onChange={(e) => {
            const newAddress = e.target.value;
            setCustomerData((prev) => ({
                ...prev,
                serviceAddress: newAddress,
                billingAddress: isBillingSameAsService ? newAddress : prev.billingAddress,
            }));
            }}
            fullWidth
            margin="normal"
            required
        />
        </Autocomplete>

        {/* Checkbox for Billing Address */}
        <FormControlLabel
        control={
            <Checkbox
            checked={isBillingSameAsService}
            onChange={(e) => {
                setIsBillingSameAsService(e.target.checked);
                if (e.target.checked) {
                // Set billing address to service address
                setCustomerData((prev) => ({
                    ...prev,
                    billingAddress: prev.serviceAddress,
                }));
                }
            }}
            />
        }
        label="Billing address is the same as service address"
        />

        {/* Billing Address with Autocomplete */}
        {!isBillingSameAsService && (
        <Autocomplete
            onLoad={onBillingPlacesLoad}
            onPlaceChanged={onBillingPlaceChanged}
        >
            <TextField
            label="Billing Address"
            value={customerData.billingAddress}
            onChange={(e) =>
                setCustomerData((prev) => ({
                ...prev,
                billingAddress: e.target.value,
                }))
            }
            fullWidth
            margin="normal"
            required
            />
        </Autocomplete>
        )}
      </Box>

      <Divider sx={{ my: 2 }} />

    {/* // Service Information Section */}
    <Box className={styles.section}>
    <Typography variant="h6">Service Information</Typography>
    
    {/* Service Type (One-Time or Recurring) */}
    <FormControl fullWidth margin="normal">
        <InputLabel>Service Type</InputLabel>
        <Select
        value={serviceData.serviceType}
        onChange={(e) => setServiceData({ ...serviceData, serviceType: e.target.value })}
        label="Service Type"
        >
        <MenuItem value="one-time">One-Time</MenuItem>
        <MenuItem value="recurring">Recurring</MenuItem>
        </Select>
    </FormControl>

    {/* Display Recurrence Options if Service is Recurring */}
    {serviceData.serviceType === 'recurring' && (
        <>
        <Typography variant="subtitle1" sx={{ mt: 2 }}>Recurrence Pattern</Typography>
        <RecurrenceSelect
            value={scheduleData.recurrenceRule}
            onChange={(value) => setScheduleData({ ...scheduleData, recurrenceRule: value })}
        />
        </>
    )}

    <TextField
        label="Service Cost"
        type="number"
        value={serviceData.price}
        onChange={(e) => setServiceData({ ...serviceData, price: parseFloat(e.target.value) })}
        InputProps={{ inputProps: { min: 0, step: 0.01 } }}
        fullWidth
        margin="normal"
    />
    </Box>

      <Divider sx={{ my: 2 }} />

      {/* Schedule Information Section */}
      <Box className={styles.section}>
        <Typography variant="h6">Schedule Information</Typography>
        <TextField
          label="Start Time"
          type="time"
          value={scheduleData.startTime}
          onChange={(e) => setScheduleData({ ...scheduleData, startTime: e.target.value })}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Time"
          type="time"
          value={scheduleData.endTime}
          onChange={(e) => setScheduleData({ ...scheduleData, endTime: e.target.value })}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Cleaner Assignment Section */}
        <Box className={styles.section}>
        <Typography variant="h6">Cleaner Assignment</Typography>
        {cleaners.length > 0 ? (
            <FormControl fullWidth margin="normal">
            <InputLabel>Assign Cleaner</InputLabel>
            <Select
                value={cleanerId}
                onChange={(e) => setCleanerId(e.target.value)}
                label="Assign Cleaner"
            >
                {cleaners.map(cleaner => (
                <MenuItem key={cleaner.id} value={cleaner.id}>
                    {cleaner.firstName && cleaner.lastName
                    ? `${cleaner.firstName} ${cleaner.lastName}`
                    : cleaner.email}
                </MenuItem>
                ))}
            </Select>
            </FormControl>
        ) : (
            <Typography variant="body2" color="textSecondary">
            No cleaners available.
            </Typography>
        )}
        </Box>

      <Divider sx={{ my: 2 }} />

      <TextField
        label="Total Size (SqFt)"
        type="number"
        value={totalSize}
        onChange={(e) => setTotalSize(Math.max(0, parseFloat(e.target.value) || 0))}
        InputProps={{
          inputProps: { min: 0, step: 1 }, // Ensure no negative values
        }}
        fullWidth
        margin="normal"
      />

      {/* Rooms Section */}
      <Box className={styles.section}>
        <Typography variant="h6">Rooms</Typography>
        {rooms.length > 0 ? (
          rooms.map((room, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Typography variant="body1">{room.name}</Typography>
              <Button size="small" onClick={() => handleRemoveRoom(index)} sx={{ ml: 2 }}>
                Remove
              </Button>
            </Box>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">No rooms added.</Typography>
        )}
        <Button variant="outlined" onClick={() => setOpenAddRoomsModal(true)} sx={{ mt: 2 }}>
          Add Rooms
        </Button>
      </Box>

      {/* Add Rooms Modal */}
      <AddRoomsModal
        open={openAddRoomsModal}
        handleClose={() => setOpenAddRoomsModal(false)}
        availableRooms={availableRooms}
        handleAddRooms={handleAddRooms}
        existingRooms={rooms}
        orgId={orgId}
      />

      <Divider sx={{ my: 2 }} />

      {/* Notes Section */}
      <Box className={styles.section}>
        <Typography variant="h6">Notes</Typography>
        <TextField
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={3}
        />
        <TextField
          label="Important Notes"
          value={importantNotes}
          onChange={(e) => setImportantNotes(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={3}
        />
      </Box>

      {/* Save Changes/Delete Service Button */}


        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSaveChanges}
        >
          Save Changes
        </Button>
    </Box>
  );
};

export default EditRecurringService;

