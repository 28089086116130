// /src/components/JobDetails.js

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Chip,
  Grid,
  Divider,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { JOB_STATUSES, normalizeJobStatus } from '../context/JobContext';
import { useTimezone } from '../context/TimeZoneContext';

const JobDetails = ({ job, formatRecurrenceRule, getStatusDetails }) => {
  const [calculatedDuration, setCalculatedDuration] = useState(null);
  const [scheduledDuration, setScheduledDuration] = useState(null);
  const [efficiency, setEfficiency] = useState(null);

  const { formatInOrgTz } = useTimezone();

  const formatDuration = (duration) => {
    const durationInMs = duration < 1000 ? duration * 60 * 60 * 1000 : duration;
    
    const totalSeconds = Math.floor(durationInMs / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
  
    // Convert to decimal hours
    const decimalHours = hours + (minutes / 60) + (seconds / 3600);
    return `${decimalHours.toFixed(2)} hrs`;
  };

  const calculateEfficiency = (scheduledMs, actualMs) => {
    const efficiency = (scheduledMs / actualMs) * 100;
    return Math.round(efficiency * 100) / 100; // Round to 2 decimal places
  };

  const getEfficiencyColor = (rating) => {
    if (rating >= 100) return '#4caf50'; // Green for 100% or better
    if (rating >= 90) return '#ff9800';  // Orange for 90-99%
    return '#f44336'; // Red for below 90%
  };

  const formatDateTime = (timeValue) => {
    if (!timeValue) return null;
    
    // If it's a Firestore Timestamp
    if (timeValue.toDate && typeof timeValue.toDate === 'function') {
      return timeValue.toDate();
    }
    
    // If it's already a Date object
    if (timeValue instanceof Date) {
      return timeValue;
    }
    
    // If it's a time string like "08:30 AM"
    if (typeof timeValue === 'string') {
      // Get today's date and combine with the time
      const [time, meridiem] = timeValue.split(' ');
      const [hours, minutes] = time.split(':');
      const date = new Date();
      
      // Convert to 24-hour format if needed
      let hour = parseInt(hours);
      if (meridiem === 'PM' && hour !== 12) hour += 12;
      if (meridiem === 'AM' && hour === 12) hour = 0;
      
      date.setHours(hour);
      date.setMinutes(parseInt(minutes));
      date.setSeconds(0);
      date.setMilliseconds(0);
      
      return date;
    }

    // If it's a number (timestamp)
    if (typeof timeValue === 'number') {
      return new Date(timeValue);
    }
    
    return null;
  };

  const formatTimeForDisplay = (timeValue) => {
    if (!timeValue) return 'Not set';
    
    try {
      const date = formatDateTime(timeValue);
      if (!date) return 'Invalid date';
      return formatInOrgTz(date, 'LLLL');
    } catch (err) {
      console.error('Error formatting time for display:', err);
      return 'Invalid date';
    }
  };

  useEffect(() => {
    setCalculatedDuration(null);
    setScheduledDuration(null);
    setEfficiency(null);
    
    if (job.appointmentDate && job.scheduledEndTime) {
      const start = formatDateTime(job.appointmentDate);
      const end = formatDateTime(job.scheduledEndTime);
      if (start && end) {
        const scheduledDur = end.getTime() - start.getTime();
        setScheduledDuration(scheduledDur);
      }
    }
    
    if (normalizeJobStatus(job.status) === JOB_STATUSES.COMPLETED && job.startTime && job.endTime) {
      const start = formatDateTime(job.startTime);
      const end = formatDateTime(job.endTime);
      
      if (start && end) {
        const actualDur = end.getTime() - start.getTime();
        setCalculatedDuration(actualDur);

        if (scheduledDuration && actualDur) {
          const efficiencyValue = calculateEfficiency(scheduledDuration, actualDur);
          setEfficiency(efficiencyValue);
        }
      }
    }
  }, [job, scheduledDuration]);

  // Get status details for styling
  const status = normalizeJobStatus(job.status);
  const statusConfig = getStatusDetails(status);


  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
      <Typography variant="h5" gutterBottom>
        Job Details
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <Grid container spacing={2}>
        {/* Customer & Address */}
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" color="textSecondary">
            Customer
          </Typography>
          <Typography variant="body1">{job.customerName}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" color="textSecondary">
            Address
          </Typography>
          <Typography variant="body1">{job.address}</Typography>
        </Grid>

        {/* Scheduled Times */}
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" color="textSecondary">
            Scheduled Start
          </Typography>
          <Typography variant="body1">
            {formatTimeForDisplay(job.appointmentDate)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" color="textSecondary">
            Scheduled End
          </Typography>
          <Typography variant="body1">
            {formatTimeForDisplay(job.scheduledEndTime)}
          </Typography>
        </Grid>

        {/* Scheduled Duration & Cleaner */}
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" color="textSecondary">
            Scheduled Duration
          </Typography>
          <Typography variant="body1">
            {scheduledDuration ? formatDuration(scheduledDuration) : 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" color="textSecondary">
            Cleaner
          </Typography>
          <Typography variant="body1">{job.cleanerName}</Typography>
        </Grid>

        {/* Status */}
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" mt={1}>
            <Typography variant="subtitle1" color="textSecondary">
              Status:
            </Typography>
            <Chip
              label={statusConfig.label}
              size="small"
              sx={{
                ml: 1,
                backgroundColor: statusConfig.chipColor,
                color: 'white',
              }}
            />
          </Box>
        </Grid>

        {/* Service Details */}
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" color="textSecondary">
            Service Type
          </Typography>
          <Typography variant="body1">
            {job.serviceType === 'one-time'
              ? 'One Time Service'
              : 'Recurring Service'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" color="textSecondary">
            Service Cost
          </Typography>
          <Typography variant="body1">
            {job.serviceCost || job.price || '0'}
          </Typography>
        </Grid>
      </Grid>

      {/* Divider before Rooms */}
      <Divider sx={{ my: 2 }} />

      {/* Collapsible Rooms Section */}
      {job.rooms && job.rooms.length > 0 && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="rooms-content"
            id="rooms-header"
          >
            <Typography variant="subtitle1">Rooms</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List dense>
              {job.rooms.map((room, index) => (
                <ListItem key={index} disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body1">
                        {room.name} ({room.quantity})
                      </Typography>
                    }
                    secondary={
                      <Box component="div" ml={2}>
                        {room.tasks?.map((task, taskIndex) => (
                          <Typography
                            key={taskIndex}
                            variant="body2"
                            component="div"
                            sx={{
                              textDecoration: task.completed
                                ? 'line-through'
                                : 'none',
                              color: task.completed
                                ? 'text.secondary'
                                : 'text.primary',
                            }}
                          >
                            • {task.description} -{' '}
                            {task.completed ? 'Done' : 'Not Done'}
                          </Typography>
                        ))}
                      </Box>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      )}

      {/* Notes */}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="textSecondary">
            Notes
          </Typography>
          <Typography variant="body1">
            {job.notes || 'No notes provided.'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="textSecondary">
            Important Notes
          </Typography>
          <Typography variant="body1">
            {job.importantNotes || 'No important notes provided.'}
          </Typography>
        </Grid>
      </Grid>

      {/* Completed Job Details */}
      {status === JOB_STATUSES.COMPLETED && (
        <>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">Completion Details</Typography>

          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="textSecondary">
                Actual Start Time
              </Typography>
              <Typography variant="body1">
                {formatTimeForDisplay(job.startTime)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="textSecondary">
                Actual End Time
              </Typography>
              <Typography variant="body1">
                {formatTimeForDisplay(job.endTime)}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="textSecondary">
                Actual Duration
              </Typography>
              <Typography variant="body1">
                {job.duration
                  ? formatDuration(job.duration)
                  : calculatedDuration
                  ? formatDuration(calculatedDuration)
                  : 'N/A'}
              </Typography>
            </Grid>

            {/* Efficiency Rating */}
            {efficiency && (
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  Efficiency Rating
                </Typography>
                <Chip
                  label={`${efficiency}%`}
                  size="small"
                  sx={{
                    backgroundColor: getEfficiencyColor(efficiency),
                    color: 'white',
                  }}
                />
              </Grid>
            )}

            {/* Cleaner Notes */}
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textSecondary">
                Cleaner Notes
              </Typography>
              <Typography variant="body1">
                {job.cleanerNotes || 'No notes provided.'}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default JobDetails;