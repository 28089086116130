// /src/components/JobsOverview.jsx

import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, getDocs, doc, updateDoc, deleteDoc, addDoc, writeBatch } from 'firebase/firestore';
import { db } from '../firebase';
import { Box, Typography, TextField, Chip, Stack, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';
import { rrulestr } from 'rrule';
import JobDialog from './JobDialog';
import { useTimezone } from '../context/TimeZoneContext';
import { JOB_STATUSES, normalizeJobStatus } from '../context/JobContext';
import AddRoomsModal from './AddRoomsModal';
import WeeklyJobsView from './WeeklyJobsView';
import { formatCurrency } from '../utils/formatCurrency';
import styles from '../styles/JobsOverview.module.css';
import moment from 'moment';

const JobsOverview = ({ orgId }) => {
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [selectedJob, setSelectedJob] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedJob, setEditedJob] = useState(null);
  const [error, setError] = useState(null);
  const [openAddRoomsModal, setOpenAddRoomsModal] = useState(false);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [showingRecurringSeries, setShowingRecurringSeries] = useState(false);
  const [recurringInstances, setRecurringInstances] = useState([]);
  const [applyToRecurring, setApplyToRecurring] = useState(false);
  const [success, setSuccess] = useState('');
  const { formatInOrgTz } = useTimezone();

  // Status filtering state - initialize with all available statuses
  const [selectedStatuses, setSelectedStatuses] = useState(Object.values(JOB_STATUSES));

  // Available statuses with labels and colors
  const availableStatuses = [
    { value: JOB_STATUSES.PENDING, label: 'Pending', color: 'warning', chipColor: '#ff9800' },
    { value: JOB_STATUSES.IN_PROGRESS, label: 'In Progress', color: 'info', chipColor: '#2196f3' },
    { value: JOB_STATUSES.COMPLETED, label: 'Completed', color: 'success', chipColor: '#4caf50' },
    { value: JOB_STATUSES.CANCELLED, label: 'Cancelled', color: 'error', chipColor: '#f44336' },
  ];

  // Helper function to get status details
  const getStatusDetails = (status) => {
    return availableStatuses.find(s => s.value === status) || availableStatuses[0];
  };

  useEffect(() => {
    if (orgId) {
      fetchJobs();
    }
  }, [orgId]);

  const fetchJobs = async () => {
    try {
      // Fetch room types
      const roomTypesRef = collection(db, 'organizations', orgId, 'roomTypes');
      const roomTypesSnapshot = await getDocs(roomTypesRef);
      const roomsList = roomTypesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAvailableRooms(roomsList);
  
      // Fetch jobs
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const jobsQuery = query(
        jobsRef,
        orderBy('appointmentDate', 'asc')
      );
  
      const jobsSnapshot = await getDocs(jobsQuery);
      
      const processedJobs = jobsSnapshot.docs.map(doc => {
        const jobData = { id: doc.id, ...doc.data() };
        
        // Normalize the status
        jobData.status = normalizeJobStatus(jobData.status);
        jobData.serviceCost = formatCurrency(jobData.serviceCost);
        jobData.totalSize = jobData.totalSize || 0;
        
        // Properly process recurring info
        jobData.serviceType = jobData.serviceType || 'one-time';
        jobData.recurring = jobData.serviceType === 'recurring';
        
        if (jobData.serviceType === 'recurring' && jobData.recurrenceRule) {
          jobData.isRecurring = true;
          jobData.formattedRecurrence = formatRecurrenceRule(jobData.recurrenceRule);
        } else {
          jobData.isRecurring = false;
          jobData.formattedRecurrence = null;
        }
        
        // Process rooms...
        if (jobData.rooms) {
          jobData.rooms = jobData.rooms.map(room => ({
            ...room,
            tasks: Array.isArray(room.tasks) ? room.tasks.map(task => ({
              description: typeof task === 'string' ? task : task.description,
              completed: task.completed || false
            })) : []
          }));
        }
        
        return jobData;
      });
  
      setJobs(processedJobs);
      
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setError("An error occurred while fetching jobs. Please try again later.");
    }
  };

  const handleStatusChange = (event) => {
    const { value } = event.target;
    setSelectedStatuses(value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleDateChange = (event) => {
    setFilterDate(event.target.value);
  };

  const filterJobs = (jobs) => {
    
    return jobs.filter(job => {
      const statusMatch = selectedStatuses.includes(job.status);
      
      const searchMatch = (job.customerName?.toLowerCase().includes(searchTerm) || 
        job.cleanerName?.toLowerCase().includes(searchTerm));
        
      const dateMatch = !filterDate || 
        job.appointmentDate.toDate().toISOString().split('T')[0] === filterDate;
        
      return statusMatch && searchMatch && dateMatch;
    });
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedJob(null);
    setIsEditing(false);
    setEditedJob(null);
    setRecurringInstances([]);
    setShowingRecurringSeries(false);
    setSuccess('');
    setError(null);
    setApplyToRecurring(false);  // Add this line
  };

  const handleMarkCompleted = async () => {
    if (selectedJob) {
      const jobRef = doc(db, 'organizations', orgId, 'jobs', selectedJob.id);
      await updateDoc(jobRef, { status: JOB_STATUSES.COMPLETED }); // Use constant
      fetchJobs();
      handleCloseDialog();
    }
  };

  // Initialize edit mode
  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  
    // Format appointment date while preserving the exact time
    const appointmentDateTime = selectedJob.appointmentDate.toDate();
    const formattedAppointmentDate = moment(appointmentDateTime)
      .format('YYYY-MM-DDTHH:mm'); // Use 24-hour format for HTML datetime-local
  
    // Format end time while preserving the exact time
    const endDateTime = selectedJob.scheduledEndTime?.toDate() || 
                       selectedJob.appointmentEndDate?.toDate();
    const formattedEndTime = endDateTime ? 
      moment(endDateTime).format('YYYY-MM-DDTHH:mm') : 
      formattedAppointmentDate;
  
    setEditedJob({
      ...selectedJob,
      customerName: selectedJob.customerName,
      cleanerName: selectedJob.cleanerName,
      address: selectedJob.address,
      totalSize: selectedJob.totalSize || '',
      notes: selectedJob.notes,
      importantNotes: selectedJob.importantNotes,
      appointmentDate: formattedAppointmentDate,  // Use the preserved time
      scheduledEndTime: formattedEndTime,         // Use the preserved time
      recurring: selectedJob.recurring,
      recurrenceRule: selectedJob.recurrenceRule,
      rooms: selectedJob.rooms || []
    });
  };

  // Cancel edit mode
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedJob(null);
  };

  // Handle input changes in edit mode
  const handleEditInputChange = (field, value) => {
    setEditedJob(prev => {
      const updates = { ...prev };
  
      switch (field) {
        case 'appointmentDate':
          // Only update if the value actually changed
          if (value !== updates.appointmentDate) {
            updates.appointmentDate = value;
            
            // If no end time is set, update it to be 2 hours after start by default
            if (!updates.scheduledEndTime) {
              const startDate = new Date(value);
              const endDate = new Date(startDate.getTime() + (2 * 60 * 60 * 1000));
              updates.scheduledEndTime = endDate.toISOString().slice(0, 16);
            }
          }
          break;
          
        case 'scheduledEndTime':
          // Only update if the value actually changed
          if (value !== updates.scheduledEndTime) {
            updates.scheduledEndTime = value;
            
            // Calculate duration only if we have both start and end times
            if (updates.appointmentDate && value) {
              const startDate = new Date(updates.appointmentDate);
              const endDate = new Date(value);
              updates.scheduledDuration = endDate.getTime() - startDate.getTime();
            }
          }
          break;
  
        default:
          updates[field] = value;
      }
  
      return updates;
    });
  };
  

  const formatDateTime = (timeValue) => {
    if (!timeValue) return null;
    
    // If it's a Firestore Timestamp
    if (timeValue.toDate && typeof timeValue.toDate === 'function') {
      return timeValue.toDate();
    }
    
    // If it's already a Date object
    if (timeValue instanceof Date) {
      return timeValue;
    }
    
    // If it's a time string like "08:30 AM"
    if (typeof timeValue === 'string') {
      // Get today's date and combine with the time
      const [time, meridiem] = timeValue.split(' ');
      const [hours, minutes] = time.split(':');
      const date = new Date();
      
      // Convert to 24-hour format if needed
      let hour = parseInt(hours);
      if (meridiem === 'PM' && hour !== 12) hour += 12;
      if (meridiem === 'AM' && hour === 12) hour = 0;
      
      date.setHours(hour);
      date.setMinutes(parseInt(minutes));
      date.setSeconds(0);
      date.setMilliseconds(0);
      
      return date;
    }

    // If it's a number (timestamp)
    if (typeof timeValue === 'number') {
      return new Date(timeValue);
    }
    
    return null;
  };

  const formatTimeForDisplay = (timeValue) => {
    if (!timeValue) return 'Not set';
    
    try {
      console.log('Formatting time value:', timeValue);
      const date = formatDateTime(timeValue);
      console.log('Converted to date:', date);
      if (!date) return 'Invalid date';
      const formatted = formatInOrgTz(date, 'LLLL');
      console.log('Formatted result:', formatted);
      return formatted;
    } catch (err) {
      console.error('Error formatting time for display:', err, timeValue);
      return 'Invalid date';
    }
  };

  
  const handleSaveEdit = async () => {
    try { 
      console.log('Starting job update with applyToRecurring:', editedJob.applyToRecurring);
      console.log('New times being set:', {
        startTime: editedJob.appointmentDate,
        endTime: editedJob.scheduledEndTime
      });
      
      // Convert appointment date from form input (which comes as a datetime-local string)
      let appointmentDate;
      if (typeof editedJob.appointmentDate === 'string') {
        appointmentDate = new Date(editedJob.appointmentDate);
      } else if (editedJob.appointmentDate?.toDate) {
        appointmentDate = editedJob.appointmentDate.toDate();
      } else if (editedJob.appointmentDate instanceof Date) {
        appointmentDate = editedJob.appointmentDate;
      } else {
        throw new Error('Invalid appointment date format');
      }
  
      // Convert scheduled end time from form input
      let scheduledEndTime;
      if (typeof editedJob.scheduledEndTime === 'string') {
        scheduledEndTime = new Date(editedJob.scheduledEndTime);
      } else if (editedJob.scheduledEndTime?.toDate) {
        scheduledEndTime = editedJob.scheduledEndTime.toDate();
      } else if (editedJob.scheduledEndTime instanceof Date) {
        scheduledEndTime = editedJob.scheduledEndTime;
      } else {
        throw new Error('Invalid end time format');
      }
  
      // Calculate scheduled duration from the new times
      const scheduledDuration = scheduledEndTime.getTime() - appointmentDate.getTime();
      console.log('New duration calculated:', {
        scheduledDuration,
        hours: scheduledDuration / (1000 * 60 * 60)
      });
  
      // Extract hours and minutes from the new appointment time
      const newHours = appointmentDate.getHours();
      const newMinutes = appointmentDate.getMinutes();
      
      // Base update data
      const updateData = {
        customerName: editedJob.customerName,
        cleanerName: editedJob.cleanerName,
        address: editedJob.address,
        notes: editedJob.notes,
        importantNotes: editedJob.importantNotes,
        totalSize: parseFloat(editedJob.totalSize) || 0,
        serviceCost: parseFloat(editedJob.serviceCost),
        price: parseFloat(editedJob.serviceCost),
        rooms: editedJob.rooms || [],
        serviceType: editedJob.serviceType || 'one-time',
        recurring: editedJob.serviceType === 'recurring',
        recurrenceRule: editedJob.serviceType === 'recurring' ? editedJob.recurrenceRule : null,
        updatedAt: new Date()
      };
  
      // If applying to recurring instances
      if (editedJob.applyToRecurring && editedJob.serviceType === 'recurring' && selectedJob.recurrenceGroupId) {
        console.log('Updating recurring instances');
        
        const jobsRef = collection(db, 'organizations', orgId, 'jobs');
        const recurringJobsQuery = query(
          jobsRef,
          where('recurrenceGroupId', '==', selectedJob.recurrenceGroupId)
        );
        
        const recurringJobsSnapshot = await getDocs(recurringJobsQuery);
        console.log('Found recurring instances:', recurringJobsSnapshot.size);
  
        const batch = writeBatch(db);
        let updateCount = 0;
        
        recurringJobsSnapshot.docs.forEach(doc => {
          const instanceData = doc.data();
          const instanceDate = instanceData.appointmentDate.toDate();
          
          // Only update future instances that are pending
          if (instanceDate >= appointmentDate && 
              (!instanceData.status || instanceData.status.toLowerCase() === 'pending')) {
            
            // Create new Date objects for this instance's start and end times
            const newInstanceStart = new Date(instanceDate);
            // Set the new hours and minutes while keeping the original date
            newInstanceStart.setHours(newHours);
            newInstanceStart.setMinutes(newMinutes);
            
            // Calculate new end time by adding the duration
            const newInstanceEnd = new Date(newInstanceStart.getTime() + scheduledDuration);
            
            console.log('Updating instance:', {
              id: doc.id,
              originalDate: instanceDate,
              newStart: newInstanceStart,
              newEnd: newInstanceEnd,
              duration: scheduledDuration
            });
            
            batch.update(doc.ref, {
              ...updateData,
              appointmentDate: newInstanceStart,
              scheduledEndTime: newInstanceEnd,
              scheduledDuration: scheduledDuration
            });
            
            updateCount++;
          }
        });
        
        console.log(`Updating ${updateCount} future instances`);
        
        if (updateCount > 0) {
          await batch.commit();
          console.log('Batch update completed successfully');
        }
  
        // Force a complete refresh of the jobs data
        await fetchJobs();
        
      } else {
        // Update single instance
        console.log('Updating single instance:', selectedJob.id);
        const jobRef = doc(db, 'organizations', orgId, 'jobs', selectedJob.id);
        await updateDoc(jobRef, {
          ...updateData,
          appointmentDate,
          scheduledEndTime,
          scheduledDuration
        });
  
        // Force a refresh for the single job update
        await fetchJobs();
      }
      
      setIsEditing(false);
      setEditedJob(null);
      handleCloseDialog();
      setSuccess(`Job${editedJob.applyToRecurring ? 's' : ''} updated successfully.`);
  
    } catch (error) {
      console.error("Error updating job:", error);
      setError("Failed to update job: " + error.message);
    }
  };
// Add a useEffect to force re-render when jobs change
useEffect(() => {
  console.log('Jobs updated in WeeklyJobsView:', jobs.length);
}, [jobs]);
  
  // Helper function to calculate efficiency
  const calculateJobEfficiency = (scheduledDuration, actualDuration) => {
    if (!scheduledDuration || !actualDuration || actualDuration === 0) return 0;
    return Math.round((scheduledDuration / actualDuration) * 100 * 100) / 100; // Round to 2 decimal places
  };

  const handleDeleteJob = async () => {
    if (selectedJob) {
      const confirmDelete = window.confirm("Are you sure you want to delete this job?");
      if (!confirmDelete) return;
      try {
        const jobRef = doc(db, 'organizations', orgId, 'jobs', selectedJob.id);
        await deleteDoc(jobRef);
        fetchJobs();
        handleCloseDialog();
      } catch (error) {
        console.error("Error deleting job:", error);
        setError("Failed to delete job.");
      }
    }
  };

  const handleDeleteRecurringJob = async (recurrenceGroupId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete all instances of this recurring job?"
    );
    
    if (!confirmDelete) return;
  
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const recurringJobsQuery = query(
        jobsRef,
        where('recurrenceGroupId', '==', recurrenceGroupId),
        where('status', '==', 'pending')
      );
      
      const recurringJobsSnapshot = await getDocs(recurringJobsQuery);
      
      const deletePromises = recurringJobsSnapshot.docs.map(doc => 
        deleteDoc(doc.ref)
      );
      
      await Promise.all(deletePromises);
      
      // Refresh the jobs list
      fetchJobs();
      handleCloseDialog();
      
    } catch (error) {
      console.error("Error deleting recurring jobs:", error);
      setError("Failed to delete recurring jobs.");
    }
  };

  const handleApplyToRecurringChange = (checked) => {
    setApplyToRecurring(checked);
    setEditedJob(prev => ({
      ...prev,
      applyToRecurring: checked
    }));
  };

  // Add/Edit Rooms for Jobs
  const handleAddRooms = (roomsToAdd) => {
    setEditedJob(prev => ({
      ...prev,
      rooms: roomsToAdd
    }));
  };
  
  // Add this handler for room deletion
  const handleDeleteRoom = (roomIndex) => {
    setEditedJob(prev => ({
      ...prev,
      rooms: prev.rooms.filter((_, index) => index !== roomIndex)
    }));
  };

  const fetchRecurringInstances = async (recurrenceGroupId) => {
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const recurringQuery = query(
        jobsRef,
        where('recurrenceGroupId', '==', recurrenceGroupId),
        orderBy('appointmentDate', 'asc')
      );
      
      const snapshot = await getDocs(recurringQuery);
      const instances = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setRecurringInstances(instances);
      setShowingRecurringSeries(true);
    } catch (error) {
      console.error("Error fetching recurring instances:", error);
      setError("Failed to fetch recurring instances.");
    }
  };

  const generateMoreInstances = async (job) => {
    try {
      if (!job.recurring || !job.recurrenceRule) return;
  
      // Ensure the recurrenceRule is properly formatted
      let rruleString = job.recurrenceRule;
      
      // If it's just "WEEKLY", convert it to proper RRULE format
      if (!rruleString.startsWith('FREQ=')) {
        const patternMap = {
          'WEEKLY': 'FREQ=WEEKLY;INTERVAL=1',
          'BIWEEKLY': 'FREQ=WEEKLY;INTERVAL=2',
          'MONTHLY': 'FREQ=MONTHLY;INTERVAL=1',
          'BIMONTHLY': 'FREQ=MONTHLY;INTERVAL=2',
          'QUARTERLY': 'FREQ=MONTHLY;INTERVAL=3',
        };
        rruleString = patternMap[rruleString] || `FREQ=${rruleString};INTERVAL=1`;
      }
  
      const dtstart = job.appointmentDate.toDate();
  
      // Parse the RRULE string with dtstart option
      const rule = rrulestr(rruleString, { dtstart });
  
      // Get the last existing instance date
      const lastInstance = recurringInstances[recurringInstances.length - 1];
      const startDate = lastInstance.appointmentDate.toDate();
  
      // Generate dates for the next 3 months
      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 3);
  
      // Get occurrences between startDate and endDate
      const newDates = rule.between(startDate, endDate);
  
      // Create jobs for new dates
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const jobPromises = newDates.map((date) => {
        // Create a new job object with only the fields we want to copy
        const newJobData = {
          customerName: job.customerName,
          cleanerName: job.cleanerName,
          address: job.address,
          notes: job.notes,
          importantNotes: job.importantNotes,
          appointmentDate: date,
          status: 'pending',
          recurring: job.recurring,
          recurrenceRule: job.recurrenceRule,
          recurrenceGroupId: job.recurrenceGroupId || job.id,
          rooms: job.rooms ? [...job.rooms] : [], // Create a new array if rooms exist
          organizationId: job.organizationId,
          createdAt: new Date(),
          updatedAt: new Date()
        };
  
        return addDoc(jobsRef, newJobData);
      });
  
      await Promise.all(jobPromises);
      await fetchRecurringInstances(job.recurrenceGroupId);
      setSuccess('Generated new instances successfully.');
    } catch (error) {
      console.error('Error generating more instances:', error);
      setError('Failed to generate more instances: ' + error.message);
    }
  };

  const formatRecurrenceRule = (rule) => {
    if (!rule) return 'No recurrence pattern';
    
    const frequency = rule.match(/FREQ=([^;]*)/)?.[1];
    const interval = rule.match(/INTERVAL=([^;]*)/)?.[1] || '1';
    const byDay = rule.match(/BYDAY=([^;]*)/)?.[1];
    
    let description = '';
    
    switch (frequency) {
      case 'DAILY':
        description = `Every ${interval === '1' ? 'day' : `${interval} days`}`;
        break;
      case 'WEEKLY':
        if (byDay) {
          const days = byDay.split(',').map(day => {
            const dayNames = {
              MO: 'Monday', TU: 'Tuesday', WE: 'Wednesday',
              TH: 'Thursday', FR: 'Friday', SA: 'Saturday', SU: 'Sunday'
            };
            return dayNames[day];
          });
          description = `Every ${interval === '1' ? 'week' : `${interval} weeks`} on ${days.join(', ')}`;
        } else {
          description = `Every ${interval === '1' ? 'week' : `${interval} weeks`}`;
        }
        break;
      case 'MONTHLY':
        description = `Every ${interval === '1' ? 'month' : `${interval} months`}`;
        break;
      case 'YEARLY':
        description = `Every ${interval === '1' ? 'year' : `${interval} years`}`;
        break;
      default:
        description = rule;
    }
    
    return description;
  };

  return (
    <div className={styles.JobsOverview}>
      {error && (
        <Typography className={styles.errorMessage} color="error">
          {error}
        </Typography>
      )}
      {success && (
        <Typography className={styles.successMessage} color="success.main">
          {success}
        </Typography>
      )}
      
      {/* Filters Section */}
      <Stack spacing={2} sx={{ mb: 3 }}>
        {/* Search Field - Top Row */}
        <TextField
          label="Search by Customer or Cleaner" 
          variant="outlined" 
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
        />
        
        {/* Date and Status Filters - Bottom Row */}
        <Stack 
          direction="row" 
          spacing={2}
          sx={{
            width: '100%',
            '& > *': { flex: 1 }
          }}
        >
          {/* Date Filter */}
          <TextField
            type="date"
            label="Filter by date"
            value={filterDate}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          />

          {/* Status Filter Dropdown */}
          <FormControl>
            <InputLabel id="status-filter-label">Status</InputLabel>
            <Select
              labelId="status-filter-label"
              multiple
              value={selectedStatuses}
              onChange={handleStatusChange}
              input={<OutlinedInput label="Status" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => {
                    const statusDetails = getStatusDetails(value);
                    return (
                      <Chip 
                        key={value} 
                        label={statusDetails.label} 
                        size="small"
                        sx={{
                          backgroundColor: statusDetails.chipColor,
                          color: 'white',
                          '&:hover': {
                            backgroundColor: statusDetails.chipColor,
                            opacity: 0.9
                          }
                        }}
                      />
                    );
                  })}
                </Box>
              )}
            >
              {availableStatuses.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      backgroundColor: status.chipColor
                    }}
                  />
                  {status.label}
                </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>

      {/* Weekly View */}
      <WeeklyJobsView 
        jobs={filterJobs(jobs)} 
        onJobClick={handleJobClick}
        statusColors={availableStatuses}
        getStatusDetails={getStatusDetails}
      />
      
      <JobDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        job={selectedJob}
        isEditing={isEditing}
        handleEditClick={handleEditClick}
        handleDeleteJob={handleDeleteJob}
        handleMarkCompleted={handleMarkCompleted}
        handleDeleteRecurringJob={handleDeleteRecurringJob}
        fetchRecurringInstances={fetchRecurringInstances}
        showingRecurringSeries={showingRecurringSeries}
        recurringInstances={recurringInstances}
        generateMoreInstances={generateMoreInstances}
        handleSaveEdit={handleSaveEdit}
        handleCancelEdit={handleCancelEdit}
        editedJob={editedJob}
        handleEditInputChange={handleEditInputChange}
        handleAddRooms={handleAddRooms}
        handleDeleteRoom={handleDeleteRoom}
        openAddRoomsModal={openAddRoomsModal}
        setOpenAddRoomsModal={setOpenAddRoomsModal}
        availableRooms={availableRooms}
        formatRecurrenceRule={formatRecurrenceRule}
        getStatusDetails={getStatusDetails}
        handleApplyToRecurringChange={handleApplyToRecurringChange}  // Add this
        applyToRecurring={applyToRecurring}  // Add this
      />
  
      {openAddRoomsModal && (
        <AddRoomsModal
          open={openAddRoomsModal}
          handleClose={() => setOpenAddRoomsModal(false)}
          availableRooms={availableRooms}
          handleAddRooms={handleAddRooms}
          existingRooms={editedJob?.rooms || []}
          className={styles.modalOverlay}
        />
      )}
    </div>
  );
};

export default JobsOverview;