// src/components/MobileNavigation.js
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaTasks, FaUser, FaMoon, FaSun, FaSignOutAlt, FaArrowLeft } from 'react-icons/fa';
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useAuth } from '../context/AuthContext';  // Add this import
import styles from '../styles/MobileNavigation.module.css';

const MobileNavigation = ({ isDarkMode, toggleDarkMode }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();  // Get user context

  // Check if we're on a standalone page
  const isStandalonePage = location.pathname.includes('/org/') || 
                          location.pathname.includes('/cleaner-dashboard/');

  const handleSignOut = () => {
    localStorage.removeItem('authToken');
    sessionStorage.removeItem('authToken');
    navigate('/login');
    setMenuOpen(false);
  };

  // If on a standalone job view page, show minimal navigation
  if (isStandalonePage && location.pathname.includes('/job/')) {
    return (
      <nav className={`${styles.mobileNav} ${styles.standaloneNav}`}>
        <button
          className={styles.navItem}
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft className={styles.icon} />
          <span className={styles.label}>Back</span>
        </button>
        <div className={styles.spacer} />
        <button
          className={styles.navItem}
          onClick={() => setMenuOpen(true)}
        >
          <FaUser className={styles.icon} />
          <span className={styles.label}>Menu</span>
        </button>

        <Dialog
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          className={styles.menuDialog}
        >
          <DialogTitle>Menu</DialogTitle>
          <DialogContent>
            <List>
              <ListItem button onClick={toggleDarkMode}>
                <ListItemIcon>
                  {isDarkMode ? <FaSun /> : <FaMoon />}
                </ListItemIcon>
                <ListItemText primary={`${isDarkMode ? 'Light' : 'Dark'} Mode`} />
              </ListItem>
              <ListItem button onClick={handleSignOut}>
                <ListItemIcon>
                  <FaSignOutAlt />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
              </ListItem>
            </List>
          </DialogContent>
        </Dialog>
      </nav>
    );
  }

  // If user is a cleaner, show only cleaner-specific navigation
  if (user?.role === 'cleaner') {
    return (
      <nav className={styles.mobileNav}>
        <Link
          to={`/cleaner-jobs/${user.uid}`}
          className={`${styles.navItem} ${location.pathname.includes('/cleaner-jobs') ? styles.active : ''}`}
        >
          <FaTasks className={styles.icon} />
          <span className={styles.label}>My Jobs</span>
        </Link>
        <button
          className={styles.navItem}
          onClick={() => setMenuOpen(true)}
        >
          <FaUser className={styles.icon} />
          <span className={styles.label}>Menu</span>
        </button>

        <Dialog
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          className={styles.menuDialog}
        >
          <DialogTitle>Menu</DialogTitle>
          <DialogContent>
            <List>
              <ListItem button onClick={toggleDarkMode}>
                <ListItemIcon>
                  {isDarkMode ? <FaSun /> : <FaMoon />}
                </ListItemIcon>
                <ListItemText primary={`${isDarkMode ? 'Light' : 'Dark'} Mode`} />
              </ListItem>
              <ListItem button onClick={handleSignOut}>
                <ListItemIcon>
                  <FaSignOutAlt />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
              </ListItem>
            </List>
          </DialogContent>
        </Dialog>
      </nav>
    );
  }

  // No navigation for other scenarios
  return null;
};

export default MobileNavigation;