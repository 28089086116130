// ./src/utils/api.js

import config from "../config";
import { transcribeAudioPrompt } from "../settings/prompts";
import axios from "axios";
import { auth } from "../firebase";
import { db } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

const baseUrl = "https://service.gemcitycleaningtools.com/dev/api";
// const baseUrl = 'https://gemcitycleaningtools.vercel.app'

export const searchContacts = async (searchTerm) => {
  if (searchTerm.length < 3) {
    console.error("Min. 3 characters required for applying contains filter");
    throw new Error("Min. 3 characters required for applying contains filter");
  }

  const requestBody = {
    locationId: config.hlLocationId,
    page: 1,
    pageLimit: 20,
    filters: [
      {
        group: "OR",
        filters: [
          {
            field: "firstNameLowerCase",
            operator: "contains",
            value: searchTerm.toLowerCase(),
          },
          {
            field: "lastNameLowerCase",
            operator: "contains",
            value: searchTerm.toLowerCase(),
          },
          {
            field: "email",
            operator: "contains",
            value: searchTerm.toLowerCase(),
          },
        ],
      },
    ],
  };

  try {
    const response = await fetch(`${config.hlBaseUrl}/contacts/search`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${config.hlApiToken}`,
        "Content-Type": "application/json",
        Version: "2021-07-28",
      },
      body: JSON.stringify(requestBody),
    });

    const responseBody = await response.json();

    if (!response.ok) {
      throw new Error(
        `Error fetching contact details: ${
          responseBody.message || response.statusText
        }`
      );
    }

    return responseBody.contacts;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

// Function to fetch contact details by contact ID
export const fetchContactDetails = async (contactId) => {
  const url = `${config.hlBaseUrl}/contacts/${contactId}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${config.hlApiToken}`,
      Version: "2021-07-28",
      Accept: "application/json",
    },
  };
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error("Failed to fetch contact details");
    }
    const data = await response.json();
    return data.contact;
  } catch (error) {
    console.error("Error fetching contact details:", error);
    throw error;
  }
};

// Function to update contact details
export const updateContactDetails = async (contactId, updatedData) => {
  const url = `${config.hlBaseUrl}/contacts/${contactId}`;
  const options = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${config.hlApiToken}`,
      "Content-Type": "application/json",
      Version: "2021-07-28",
      Accept: "application/json",
    },
    body: JSON.stringify(updatedData),
  };

  console.log("Making PUT request to:", url, "with data:", updatedData);

  try {
    const response = await fetch(url, options);
    const responseData = await response.json();

    if (!response.ok) {
      console.error("Error response from server:", responseData);
      throw new Error("Failed to update contact details");
    }

    console.log("API response:", responseData); // Log the full response for better debugging
    return responseData;
  } catch (error) {
    console.error("Error updating contact details:", error);
    throw error;
  }
};

export const getMessagesByContactId = async (
  locationId,
  contactId,
  apiToken
) => {
  const url = `${config.hlBaseUrl}/conversations/search?locationId=${locationId}&contactId=${contactId}`;
  const headers = {
    Authorization: `Bearer ${apiToken}`,
    Version: "2021-04-15",
    Accept: "application/json",
  };

  try {
    const response = await fetch(url, { headers });
    if (!response.ok)
      throw new Error(`Failed to fetch conversations: ${response.statusText}`);

    const data = await response.json();

    return data.conversations || [];
  } catch (error) {
    console.error("Error fetching conversations:", error);
    throw error;
  }
};

export const getConversationMessages = async (conversationId) => {
  const url = `${config.hlBaseUrl}/conversations/${conversationId}/messages`;
  const headers = {
    Authorization: `Bearer ${config.hlApiToken}`,
    Version: "2021-07-28", // Ensure you're using the correct API version
    Accept: "application/json",
  };

  try {
    const response = await fetch(url, { headers });
    if (!response.ok)
      throw new Error(`Failed to fetch messages: ${response.statusText}`);
    const data = await response.json();

    // Extract the messages array from data.messages.messages
    const messagesArray = data.messages?.messages || [];
    return messagesArray;
  } catch (error) {
    console.error("Error fetching messages:", error);
    throw error;
  }
};

export const getCallRecording = async (messageId) => {
  const url = `${config.hlBaseUrl}/conversations/messages/${messageId}/locations/${config.hlLocationId}/recording`;
  const headers = {
    Authorization: `Bearer ${config.hlApiToken}`,
    Version: "2021-04-15",
    Accept: "application/json",
  };

  try {
    const response = await fetch(url, { headers });
    if (!response.ok)
      throw new Error(`Failed to fetch call recording: ${response.statusText}`);
    return await response.blob();
  } catch (error) {
    console.error("Error fetching call recording:", error);
    throw error;
  }
};

export const getCallRecordingByMessageId = async (messageId, locationId) => {
  const url = `${config.hlBaseUrl}/conversations/messages/${messageId}/locations/${locationId}/recording`;
  const headers = {
    Authorization: `Bearer ${config.hlApiToken}`,
    Version: "2021-07-28", // Ensure you're using the correct API version
    Accept: "*/*",
  };

  try {
    const response = await fetch(url, { method: "GET", headers });
    if (!response.ok)
      throw new Error(`Failed to fetch call recording: ${response.statusText}`);

    // The response is a blob (audio file)
    const blob = await response.blob();
    // Create a URL for the blob to use in the audio element
    const recordingUrl = URL.createObjectURL(blob);
    return recordingUrl;
  } catch (error) {
    console.error("Error fetching call recording:", error);
    throw error;
  }
};

export const fetchCompletedCalls = async (
  locationId,
  apiToken,
  log = console.log
) => {
  log("info", `Fetching all conversations for locationId: ${locationId}`);
  const url = `${config.hlBaseUrl}/conversations/search`;
  const headers = {
    Authorization: `Bearer ${apiToken}`,
    Version: "2021-04-15",
    Accept: "application/json",
  };

  let allConversations = [];
  let params = new URLSearchParams({
    locationId: locationId,
    limit: 100,
    sortBy: "last_message_date",
    sort: "asc",
  });

  try {
    while (true) {
      log("debug", `CRM API Request - URL: ${url}, Params: ${params}`);
      const response = await fetch(`${url}?${params}`, { headers });
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      const conversations = data.conversations || [];

      allConversations = allConversations.concat(conversations);

      if (conversations.length < 100) break;

      if (conversations.length > 0) {
        const lastMessageDate =
          conversations[conversations.length - 1].lastMessageDate;
        params.set("startAfterDate", lastMessageDate);
        log(
          "debug",
          `Setting startAfterDate to ${lastMessageDate} for next request`
        );
      } else {
        break;
      }
    }
    log("info", `Fetched a total of ${allConversations.length} conversations`);
    return allConversations;
  } catch (error) {
    log("error", `Failed to fetch conversations: ${error.message}`);
    throw error;
  }
};

export const fetchConversationsByDate = async (selectedDate) => {
  const url = `${config.hlBaseUrl}/conversations/search`;
  const headers = {
    Authorization: `Bearer ${config.hlApiToken}`,
    Version: "2021-07-28",
    Accept: "application/json",
  };

  const limit = 100;
  const sortBy = "last_message_date";
  const sort = "asc"; // Start from the earliest date
  let startAfterDate = null;
  let hasMore = true;
  let allConversations = [];

  const selectedDateStart = new Date(selectedDate);
  selectedDateStart.setHours(0, 0, 0, 0);
  const selectedDateEnd = new Date(selectedDate);
  selectedDateEnd.setHours(23, 59, 59, 999);

  try {
    while (hasMore) {
      const params = new URLSearchParams({
        locationId: config.hlLocationId,
        limit: limit.toString(),
        sortBy,
        sort,
      });

      if (startAfterDate) {
        params.append("startAfterDate", startAfterDate.toString());
      }

      const response = await fetch(`${url}?${params}`, { headers });
      if (!response.ok)
        throw new Error(
          `Failed to fetch conversations: ${response.statusText}`
        );

      const data = await response.json();
      const conversations = data.conversations || [];
      if (conversations.length === 0) break;

      // Filter conversations by selected date
      const filteredConversations = conversations.filter((conversation) => {
        const lastMessageDate = new Date(conversation.lastMessageDate);
        return (
          lastMessageDate >= selectedDateStart &&
          lastMessageDate <= selectedDateEnd
        );
      });

      allConversations = allConversations.concat(filteredConversations);

      // Check if we've passed the selected date
      const lastConversationDate = new Date(
        conversations[conversations.length - 1].lastMessageDate
      );
      if (lastConversationDate > selectedDateEnd) {
        hasMore = false;
      } else {
        startAfterDate = conversations[conversations.length - 1].sort[0];
      }
    }

    return allConversations;
  } catch (error) {
    console.error("Error fetching conversations by date:", error);
    throw error;
  }
};

export const fetchOpportunityDetails = async (contactId, stageIdToNameMap) => {
  const url = `${config.hlBaseUrl}/opportunities/search`;
  const headers = {
    Authorization: `Bearer ${config.hlApiToken}`,
    Version: "2021-07-28",
    Accept: "application/json",
  };

  const params = new URLSearchParams({
    location_id: config.hlLocationId,
    contact_id: contactId,
    status: "all",
  });

  try {
    const response = await fetch(`${url}?${params}`, { headers });
    if (!response.ok)
      throw new Error(`Failed to fetch opportunities: ${response.statusText}`);
    const data = await response.json();

    if (data.opportunities && data.opportunities.length > 0) {
      const opportunity = data.opportunities[0];
      const stageId = opportunity.pipelineStageId;

      const stageName =
        (stageIdToNameMap && stageIdToNameMap[stageId]) || "Unknown Stage";

      return {
        id: opportunity.id,
        stageId: stageId,
        stageName: stageName,
        status: opportunity.status,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching opportunity details:", error);
    throw error;
  }
};

export const fetchPipelineStages = async () => {
  const url = `${config.hlBaseUrl}/opportunities/pipelines`;
  const headers = {
    Authorization: `Bearer ${config.hlApiToken}`,
    Version: "2021-07-28",
    Accept: "application/json",
  };

  const params = new URLSearchParams({
    locationId: config.hlLocationId,
  });

  try {
    const response = await fetch(`${url}?${params}`, { headers });
    if (!response.ok)
      throw new Error(
        `Failed to fetch pipeline stages: ${response.statusText}`
      );
    const data = await response.json();

    // Create a mapping from stage ID to stage name
    const stageIdToNameMap = {};
    data.pipelines.forEach((pipeline) => {
      if (pipeline.stages && Array.isArray(pipeline.stages)) {
        pipeline.stages.forEach((stage) => {
          if (stage && stage.id && stage.name) {
            stageIdToNameMap[stage.id] = stage.name;
          }
        });
      }
    });

    return stageIdToNameMap;
  } catch (error) {
    console.error("Error fetching pipeline stages:", error);
    throw error;
  }
};

export const transcribeAudioAPI = async (audioBlob) => {
  const audioBlobWithType = audioBlob.slice(0, audioBlob.size, "audio/wav");
  const formData = new FormData();
  formData.append("file", audioBlobWithType, "audio.wav");
  formData.append("model", "whisper-1");
  formData.append("response_format", "verbose_json");
  formData.append("timestamp_granularities", "word");

  const response = await fetch(
    "https://api.openai.com/v1/audio/transcriptions",
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${config.openaiApiKey}`,
      },
      body: formData,
    }
  );

  if (!response.ok) {
    throw new Error(`Transcription API error: ${response.statusText}`);
  }

  return response.json();
};

export const extractDataAPI = async (text) => {
  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${config.openaiApiKey}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      model: "gpt-4o-mini",
      messages: [
        { role: "system", content: transcribeAudioPrompt },
        { role: "user", content: text },
      ],
    }),
  });

  if (!response.ok) {
    throw new Error(`Data extraction API error: ${response.statusText}`);
  }

  return response.json();
};

export const processWithGPT4oAPI = async (transcriptText) => {
  const systemPrompt = `You are a helpful assistant tasked to identify different speakers in a transcript. The transcript contains timestamps and each speaker switch should be identified.`;

  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${config.openaiApiKey}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      model: "gpt-4o-mini",
      messages: [
        { role: "system", content: systemPrompt },
        { role: "user", content: transcriptText },
      ],
    }),
  });

  if (!response.ok) {
    throw new Error(`Speaker recognition API error: ${response.statusText}`);
  }

  return response.json();
};

// Get custom fields from location
export const getAllCustomFields = async () => {
  const url = `${config.hlBaseUrl}/locations/${config.hlLocationId}/customFields`;
  const headers = {
    Authorization: `Bearer ${config.hlApiToken}`,
    Version: "2021-07-28",
    Accept: "application/json",
  };

  try {
    const response = await fetch(url, { headers });
    if (!response.ok) {
      throw new Error(`Failed to fetch custom fields: ${response.statusText}`);
    }
    const data = await response.json();

    return data.customFields || []; // Assuming response has customFields property
  } catch (error) {
    console.error("Error fetching custom fields:", error);
    throw error;
  }
};

// Fetch custom fields for a specific client
export const getClientCustomFields = async (clientId) => {
  const url = `${config.hlBaseUrl}/contacts/${clientId}?include=customFields`; // Added query parameter
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${config.hlApiToken}`,
      Version: "2021-07-28",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to fetch client custom fields: ${errorText}`);
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error fetching client custom fields:", error);
    throw error;
  }
};

// Update custom fields for a specific client
export const updateClientCustomField = async (clientId, updatedFields) => {
  const url = `${config.hlBaseUrl}/contacts/${clientId}`;
  const options = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${config.hlApiToken}`,
      Version: "2021-07-28",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      customFields: updatedFields.map((field) => ({
        id: field.id,
        value: field.value,
      })),
    }),
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to update custom fields: ${errorText}`);
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error updating custom fields:", error);
    throw error;
  }
};

// User preference functions (using Firestore)

export const saveSectionsForUser = async (userId, sections) => {
  try {
    const userRef = doc(db, "users", userId);
    await setDoc(userRef, { sections }, { merge: true });
    return { success: true, message: "Sections saved successfully" };
  } catch (error) {
    console.error("Error saving sections:", error);
    throw new Error("Failed to save sections");
  }
};

export const saveFieldsForUser = async (userId, fields) => {
  try {
    const userRef = doc(db, "users", userId);
    await setDoc(userRef, { customFields: fields }, { merge: true });
    return { success: true, message: "Fields saved successfully" };
  } catch (error) {
    console.error("Error saving fields:", error);
    throw new Error("Failed to save fields");
  }
};

export const getUserSections = async (userId) => {
  try {
    const userRef = db.collection("users").doc(userId);
    const doc = await userRef.get();
    if (doc.exists) {
      return doc.data().sections || [];
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching user sections:", error);
    throw new Error("Failed to fetch user sections");
  }
};

export const getUserFields = async (userId) => {
  try {
    const userRef = db.collection("users").doc(userId);
    const doc = await userRef.get();
    if (doc.exists) {
      return doc.data().customFields || {};
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error fetching user fields:", error);
    throw new Error("Failed to fetch user fields");
  }
};

// Fetch user preferences via backend endpoint
export const getUserPreferences = async () => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const idToken = await user.getIdToken();
    const response = await axios.get(
      "https://service.gemcitycleaningtools.com/dev/api/userPreferences",
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching user preferences:", error);
    throw error;
  }
};

// Save user preferences via backend endpoint
export const saveUserPreferences = async (preferences) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");

    const idToken = await user.getIdToken();
    const response = await axios.put("/userPreferences", preferences, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error saving user preferences:", error);
    throw error;
  }
};

// Fetch distance data for a specific client
const BASE_ADDRESS = "278 Mertland Ave, Dayton, OH 45431"; // Use the base address from config

export const fetchGeocodeData = async (address) => {
  try {
    if (!address) {
      throw new Error("Address is required for geocoding");
    }

    const response = await axios.get("/geocode", {
      params: { address },
    });

    if (!response.data || !response.data.lat || !response.data.lng) {
      throw new Error("Geocode data not found in response");
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching geocode:", error.message);
    throw error;
  }
};

export const fetchDistanceData = async (origins, destinations) => {
  try {
    const response = await axios.get("/distance", {
      params: { origins, destinations },
    });

    if (!response.data || !response.data.distanceText) {
      throw new Error("Distance data not found in response");
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching distance:", error.message);
    throw error;
  }
};

export const uploadCSVFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (!Array.isArray(response.data)) {
      throw new Error("Unexpected response from server");
    }

    return response.data;
  } catch (error) {
    console.error("Error uploading CSV file:", error.message);
    throw error;
  }
};

export const placesApi = {
  async searchPlaces(searchText) {
    try {
      const response = await axios.get(`${baseUrl}/places/search`, {
        params: { searchText },
      });
      return response.data;
    } catch (error) {
      console.error("Error searching places:", error);
      throw error;
    }
  },

  async getPlaceDetails(placeId) {
    try {
      const response = await axios.get(`${baseUrl}/places/details`, {
        params: { placeId },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching place details:", error);
      throw error;
    }
  },
};

// Define the test email function - keeping this in case I might need later.
// const testEmail = async (token) => {
//   if (!token) {
//     throw new Error('Authorization token is required');
//   }

//   try {
//     const response = await axios.post(
//       `${baseUrl}/test-email`,
//       {},
//       {
//         headers: {
//           'Authorization': `Bearer ${token}`,
//           'Content-Type': 'application/json'
//         }
//       }
//     );
//     return response.data;
//   } catch (error) {
//     console.error('Test email error:', error.response || error);
//     throw error;
//   }
// };

export const organizationApi = {
  async createUser(token, data) {
    return axios.post(`${baseUrl}/createOrganizationUser`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  async updateUser(token, data) {
    return axios.post(`${baseUrl}/updateUser`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  async deleteUser(token, data) {
    return axios.post(`${baseUrl}/deleteUser`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  async resetPassword(token, data) {
    return axios.post(`${baseUrl}/resetUserPassword`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  async updateOrgName(token, data) {
    return axios.post(`${baseUrl}/updateOrgName`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};
