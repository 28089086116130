// src/components/CustomerProfileManagement.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Chip,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton
} from '@mui/material';
import { ArrowLeft } from 'lucide-react';
import { Divider } from '@mui/material';
import CreateCustomerModal from './CreateCustomerModal';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CreateRecurringService from './CreateRecurringService';
import { styled } from '@mui/material/styles';
import { 
    doc, 
    getDoc, 
    collection, 
    query, 
    where, 
    getDocs, 
    orderBy, 
    limit, 
    deleteDoc, 
    updateDoc, 
    writeBatch,
    serverTimestamp 
} from 'firebase/firestore';
import { db, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import moment from 'moment';
import { rrulestr } from 'rrule';  
import JobDialog from './JobDialog';
import EditRecurringService from './EditRecurringService';
import { useJobContext } from '../context/JobContext';
import { normalizeJobs } from '../utils/jobUtils';
import { formatRecurrenceRule } from '../utils/formatRecurrenceRule';
import RecurrenceSelect from './RecurrenceSelect';
import styles from '../styles/CustomerProfileManagement.module.css';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-foreground)' : 'var(--light-foreground)',
  color: theme.palette.mode === 'dark' ? 'var(--dark-text)' : 'var(--light-text)',
  boxShadow: theme.palette.mode === 'dark' ? 'var(--shadow-md-dark)' : 'var(--shadow-md-light)',
  borderRadius: 'var(--border-radius-base)',
  transition: 'all var(--transition-duration) var(--transition-ease)',
  '&:hover': {
    boxShadow: theme.palette.mode === 'dark' ? 'var(--shadow-lg-dark)' : 'var(--shadow-lg-light)',
  }
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: '16px',
  borderRadius: 'var(--border-radius-base)',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'var(--dark-foreground-accent)' 
    : 'var(--light-foreground-accent)',
  boxShadow: theme.palette.mode === 'dark'
    ? 'var(--shadow-sm-dark)'
    : 'var(--shadow-sm-light)',
}));

const ServiceMetricBox = styled(Box)(({ theme }) => ({
    padding: '16px',
    borderRadius: 'var(--border-radius-base)',
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'var(--dark-foreground-accent)' 
      : 'var(--light-foreground-accent)',
    boxShadow: theme.palette.mode === 'dark'
      ? 'var(--shadow-sm-dark)'
      : 'var(--shadow-sm-light)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '180px',
    flex: 1,
  }));

const StyledMetricBox = styled(Box)(({ theme }) => ({
    padding: '16px',
    borderRadius: 'var(--border-radius-base)',
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'var(--dark-foreground-accent)' 
      : 'var(--light-foreground-accent)',
    boxShadow: theme.palette.mode === 'dark'
      ? 'var(--shadow-sm-dark)'
      : 'var(--shadow-sm-light)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '200px',
    flex: 1,
  }));
  
  const MetricValue = styled(Typography)({
    fontSize: '1.5rem',
    fontWeight: 500,
    marginTop: '8px',
  });
  
  const MetricLabel = styled(Typography)({
    color: 'var(--dark-text-light)',
    fontSize: '0.875rem',
    textAlign: 'center',
  });
  

  
const StyledTabs = styled(Tabs)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.mode === 'dark' ? 'var(--dark-border)' : 'var(--light-border)'}`,
    marginBottom: '16px',
  }));
  
  const StyledTab = styled(Tab)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? 'var(--dark-text)' : 'var(--light-text)',
    '&.Mui-selected': {
      color: theme.palette.mode === 'dark' ? 'var(--dark-primary)' : 'var(--light-primary)',
    },
  }));
  
  const ServiceListItem = styled(ListItem)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-foreground-accent)' : 'var(--light-foreground-accent)',
    borderRadius: 'var(--border-radius-base)',
    marginBottom: '8px',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-dropdowns)' : 'var(--light-foreground-accent)',
    },
  }));

const CustomerProfileManagement = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const [orgId, setOrgId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [firstAppointment, setFirstAppointment] = useState(null);
  const [metrics, setMetrics] = useState({
    revenueToDate: 0,
    unpaidBalance: 0,
    completedJobsCount: 0,
    avgDuration: 0,
    avgPayrollExpense: 0,
  });
  const [activeTab, setActiveTab] = useState('profile');
  const [servicesTab, setServicesTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editCustomerData, setEditCustomerData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedJob, setEditedJob] = useState(null);
  const [success, setSuccess] = useState('');
  const [showingRecurringSeries, setShowingRecurringSeries] = useState(false);
  const [isAddingNewService, setIsAddingNewService] = useState(false);
  const [recurringInstances, setRecurringInstances] = useState([]);
  const [recurringServices, setRecurringServices] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [cleaners, setCleaners] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [serviceInfo, setServiceInfo] = useState({
    type: 'One-Time',
    recurrenceDetails: null,
  });
  const { 
    startJob, 
    completeJob, 
    handleDeleteJob,
    handleDeleteRecurringJob,
    fetchRecurringInstances,
    generateMoreInstances,
    JOB_STATUSES 
  } = useJobContext();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setOrgId(userDoc.data().orgId);
          } else {
            setError('Organization ID not found');
          }
        } catch (err) {
          console.error('Error fetching user data:', err);
          setError('Failed to retrieve organization ID');
        }
      } else {
        setError('No authenticated user');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const fetchCustomer = async () => {
    if (!orgId || !customerId) return;
  
    try {
      const customerRef = doc(db, 'organizations', orgId, 'customers', customerId);
      const customerSnap = await getDoc(customerRef);
  
      if (!customerSnap.exists()) {
        setError(`Customer ${customerId} not found`);
        setCustomer(null);
        return;
      }
  
      const customerData = customerSnap.data();
      setCustomer({
        id: customerSnap.id,
        name: customerData.name || 'Unknown Customer',
        email: customerData.email || 'N/A',
        phone: customerData.phone || 'N/A',
        serviceAddress: customerData.serviceAddress?.complete || 'N/A',
        serviceType: customerData.serviceType || 'N/A',
        serviceCost: customerData.serviceCost || customerData.price || 0,  // Add price as fallback
      });
    } catch (err) {
      console.error('Error fetching customer:', err);
      setError('Failed to fetch customer details');
      setCustomer(null);
    }
  };

  const handleUpdateCustomer = async (updatedCustomerData) => {
    if (!orgId || !customerId) return;

    try {
      const customerRef = doc(db, 'organizations', orgId, 'customers', customerId);
      await updateDoc(customerRef, {
        ...updatedCustomerData,
        updatedAt: serverTimestamp()
      });

      // Update local state
      setCustomer({
        name: updatedCustomerData.name,
        email: updatedCustomerData.email,
        phone: updatedCustomerData.phone,
        serviceAddress: updatedCustomerData.serviceAddress.complete,
        serviceType: customer.serviceType, // Preserve existing service type
        serviceCost: customer.serviceCost // Preserve existing service cost
      });

      setSuccess('Customer updated successfully');
    } catch (err) {
      console.error('Error updating customer:', err);
      setError('Failed to update customer details');
    }
  };

  const handleTotalSizeChange = (newSize) => {
    setTotalSize(Math.max(0, parseFloat(newSize) || 0)); // Ensure no negative values
  };

  const handleEditCustomerClick = () => {
    // Format customer data for the edit modal
    const formattedCustomerData = {
      firstName: customer.name.split(' ')[0] || '',
      lastName: customer.name.split(' ').slice(1).join(' ') || '',
      email: customer.email,
      phone: customer.phone,
      serviceAddress: {
        ...customer.serviceAddress,
        complete: customer.serviceAddress
      },
      useSameAddress: true,
      billingAddress: {
        ...customer.billingAddress,
        complete: customer.billingAddress?.complete || customer.serviceAddress
      },
      customFieldValues: customer.customFieldValues || {},
      notes: customer.notes || '',
      isPrivateNote: customer.isPrivateNote || false
    };
  
    setEditCustomerData(formattedCustomerData);
    setIsEditModalOpen(true);
  };

  const fetchJobs = async () => {
    if (!orgId || !customerId) return;
  
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const jobsQuery = query(jobsRef, where('customerId', '==', customerId));
      const jobsSnap = await getDocs(jobsQuery);
  
      const rawJobs = jobsSnap.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
  
      const normalizedJobs = normalizeJobs(rawJobs);
      setJobs(normalizedJobs);
    } catch (err) {
      console.error('Error fetching jobs:', err);
      setError('Failed to fetch jobs');
    }
  };

  const fetchFirstAppointment = async () => {
    if (!orgId || !customerId) return;
    
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const q = query(
        jobsRef,
        where('customerId', '==', customerId)
      );
      
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        // Get all jobs' appointment dates and find the earliest one
        const dates = querySnapshot.docs
          .map(doc => {
            const data = doc.data();
            return data.appointmentDate;
          })
          .filter(Boolean); // Remove any undefined/null dates
        
        if (dates.length > 0) {
          // Sort timestamps and get the earliest one
          const earliestDate = dates.reduce((earliest, current) => {
            if (!earliest) return current;
            return current.toDate() < earliest.toDate() ? current : earliest;
          }, null);
          
          setFirstAppointment(earliestDate);
        }
      }
    } catch (err) {
      console.error('Error fetching first appointment:', err);
    }
  };

  useEffect(() => {
    if (orgId && customerId) {
      fetchFirstAppointment();
    }
  }, [orgId, customerId]);

  const calculateServiceMetrics = (serviceJobs) => {
    if (!Array.isArray(serviceJobs) || serviceJobs.length === 0) {
      return {
        firstAppointment: null,
        serviceCost: 0,
        completedJobsCount: 0,
        avgDuration: 0,
        avgRevenuePerService: 0,
        avgPayrollCost: 0,
        avgProfit: 0
      };
    }
  
    // Sum up all service costs for completed jobs
    const completedJobs = serviceJobs.filter(job => 
      job?.status?.toLowerCase() === 'completed'
    );
  
    const completedJobsCount = completedJobs.length;
  
    const totalServiceCost = completedJobs.reduce((sum, job) => {
      const price = parseFloat((job.serviceCost || job.price || '0').replace('$', ''));
      return sum + price;
    }, 0);
  
    const avgRevenuePerService = completedJobsCount > 0 
      ? Number((totalServiceCost / completedJobsCount).toFixed(2)) 
      : 0;
  
    // Calculate total and average duration
    const totalDuration = completedJobs.reduce((sum, job) => {
      if (typeof job.duration === 'number') {
        return sum + job.duration;
      }
      return sum;
    }, 0);
  
    const avgDuration = completedJobsCount > 0 
      ? Number((totalDuration / completedJobsCount).toFixed(2)) 
      : 0;
  
    // Calculate average payroll cost
    let totalManHourCost = 0;
    let cleanerCount = 0;
  
    completedJobs.forEach(job => {
      if (job.cleanerId && cleaners) {
        const cleaner = cleaners.find(c => c.id === job.cleanerId);
        if (cleaner?.manHourCost) {
          totalManHourCost += parseFloat(cleaner.manHourCost);
          cleanerCount++;
        }
      }
    });
  
    const avgManHourCost = cleanerCount > 0 
      ? Number((totalManHourCost / cleanerCount).toFixed(2)) 
      : 0;
  
    const avgPayrollCost = Number((avgDuration * avgManHourCost).toFixed(2));
    const avgProfit = Number((avgRevenuePerService - avgPayrollCost).toFixed(2));
  
    // Return calculated metrics
    return {
      firstAppointment: null,
      serviceCost: totalServiceCost, // Total service cost from completed jobs
      completedJobsCount,
      avgDuration,
      avgRevenuePerService,
      avgPayrollCost,
      avgProfit
    };
  };
  

  const formatCurrency = (amount) => {
    // Convert to number if it's a string, default to 0 if invalid
    const value = typeof amount === 'string' ? parseFloat(amount) : Number(amount);
    
    // Check if it's a valid number
    if (isNaN(value)) return '$0.00';
    
    // Format the number to always show 2 decimal places
    return `$${value.toFixed(2)}`;
  };

  // Get status color for appointments
  const getStatusColor = (status) => {
    const statusLower = status?.toLowerCase();
    switch (statusLower) {
      case 'completed':
        return 'var(--success)';
      case 'pending':
        return 'var(--warning)';
      case 'in progress':
        return 'var(--light-primary)';
      case 'cancelled':
        return 'var(--error)';
      default:
        return 'inherit';
    }
  };

  const handleTabChange = (event, newValue) => {
    setServicesTab(newValue);
};

  const handleUpdateService = async (updates) => {
    try {
      if (!orgId || !customerId) return;

      const customerRef = doc(db, 'organizations', orgId, 'customers', customerId);
      await updateDoc(customerRef, {
        ...updates,
        updatedAt: serverTimestamp()
      });

      // If we're updating recurrence settings, also update relevant jobs
      if (updates.recurrenceRule) {
        // Find the current recurring job
        const recurringJob = jobs.find(job => job.recurring && job.recurrenceRule);
        if (recurringJob) {
          const jobRef = doc(db, 'organizations', orgId, 'jobs', recurringJob.id);
          await updateDoc(jobRef, {
            recurrenceRule: updates.recurrenceRule,
            updatedAt: serverTimestamp()
          });
        }
      }

      await fetchCustomer();
      await fetchJobs();
      setSuccess('Service updated successfully');
    } catch (err) {
      console.error('Error updating service:', err);
      setError('Failed to update service');
    }
  };

  const handleCancelService = async (recurrenceGroupId) => {
    try {
      const confirmCancel = window.confirm(
        'Are you sure you want to cancel this recurring service? This will delete all future appointments.'
      );

      if (!confirmCancel) return;

      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const q = query(
        jobsRef,
        where('recurrenceGroupId', '==', recurrenceGroupId),
        where('appointmentDate', '>', new Date())
      );

      const snapshot = await getDocs(q);
      const batch = writeBatch(db);

      snapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });

      await batch.commit();
      await fetchJobs();
      setSuccess('Service cancelled successfully');
    } catch (err) {
      console.error('Error cancelling service:', err);
      setError('Failed to cancel service');
    }
  };

  // Handling opening and closing of job dialog
  const handleJobClick = (job) => {
    setSelectedJob(job);
    setIsDialogOpen(true);
    setShowingRecurringSeries(false);
  };
  
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedJob(null);
    setIsEditing(false);
    setEditedJob(null);
    setShowingRecurringSeries(false);
  };
  
  const handleEditClick = () => {
    setEditedJob({ ...selectedJob });
    setIsEditing(true);
  };
  
  const handleSaveEdit = async () => {
    try { 
      // Convert appointment date
      let appointmentDate;
      if (editedJob.appointmentDate instanceof Date) {
        appointmentDate = editedJob.appointmentDate;
      } else if (editedJob.appointmentDate?.toDate) {
        appointmentDate = editedJob.appointmentDate.toDate();
      } else {
        appointmentDate = new Date(editedJob.appointmentDate);
      }
  
      // Convert scheduled end time
      let scheduledEndTime;
      if (editedJob.scheduledEndTime instanceof Date) {
        scheduledEndTime = editedJob.scheduledEndTime;
      } else if (editedJob.scheduledEndTime?.toDate) {
        scheduledEndTime = editedJob.scheduledEndTime.toDate();
      } else {
        scheduledEndTime = new Date(editedJob.scheduledEndTime);
      }
  
      if (isNaN(appointmentDate?.getTime()) || isNaN(scheduledEndTime?.getTime())) {
        throw new Error('Invalid date values');
      }
  
      const duration = scheduledEndTime.getTime() - appointmentDate.getTime();
  
      // Handle price changes for recurring jobs
      if (editedJob.recurring && 
          editedJob.recurrenceGroupId && 
          (editedJob.serviceCost !== selectedJob.serviceCost || 
           editedJob.price !== selectedJob.price)) {
        
        const priceUpdateChoice = window.confirm(
          'You have changed the price for a recurring job. Would you like to:\n' +
          '• Click OK to update the price for all future instances\n' +
          '• Click Cancel to only update this instance'
        );
  
        if (priceUpdateChoice) {
          const recurringJobsQuery = query(
            collection(db, 'organizations', orgId, 'jobs'),
            where('recurrenceGroupId', '==', editedJob.recurrenceGroupId)
          );
          
          const recurringJobsSnapshot = await getDocs(recurringJobsQuery);
          const batch = writeBatch(db);
          
          const currentDate = new Date();
          
          recurringJobsSnapshot.docs.forEach(doc => {
            const jobDate = doc.data().appointmentDate.toDate();
            if (jobDate >= currentDate) {
              batch.update(doc.ref, {
                serviceCost: parseFloat(editedJob.serviceCost),
                price: parseFloat(editedJob.serviceCost),
                updatedAt: new Date()
              });
            }
          });
  
          await batch.commit();
        }
      }
  
      // Check if recurrence rule has changed
      if (editedJob.recurring && editedJob.recurrenceGroupId && 
          editedJob.recurrenceRule !== selectedJob.recurrenceRule) {
  
        const updateRecurrence = window.confirm(
          'You have changed the recurrence pattern. Would you like to:\n' +
          '• Delete all future occurrences and create new ones with the new pattern?\n' +
          '• Click Cancel to only update this instance.'
        );
  
        if (updateRecurrence) {
          // 1. Delete all future instances
          const recurringJobsQuery = query(
            collection(db, 'organizations', orgId, 'jobs'),
            where('recurrenceGroupId', '==', editedJob.recurrenceGroupId)
          );
          
          const recurringJobsSnapshot = await getDocs(recurringJobsQuery);
          const batch = writeBatch(db);
          
          const jobsToDelete = recurringJobsSnapshot.docs
            .filter(doc => {
              const jobDate = doc.data().appointmentDate.toDate();
              return jobDate > appointmentDate;
            });
          
          jobsToDelete.forEach(doc => {
            batch.delete(doc.ref);
          });
  
          // 2. Create new instances with new pattern
          const formattedDate = appointmentDate.toISOString()
            .replace(/[-:]/g, '')
            .split('.')[0] + 'Z';
            
          const rruleString = `DTSTART:${formattedDate}\nRRULE:${editedJob.recurrenceRule}`;
          
          const rule = rrulestr(rruleString);
          
          // Generate dates for the next 3 months
          const endDate = new Date();
          endDate.setMonth(endDate.getMonth() + 3);
          
          const newDates = rule.between(appointmentDate, endDate, true);
  
          // Create new jobs for each date (excluding the first one)
          const updateData = {
            customerName: editedJob.customerName,
            cleanerName: editedJob.cleanerName,
            address: editedJob.address,
            notes: editedJob.notes,
            importantNotes: editedJob.importantNotes,
            scheduledDuration: duration,
            totalSize: parseFloat(editedJob.totalSize) || 0,
            serviceCost: parseFloat(editedJob.serviceCost),
            price: parseFloat(editedJob.serviceCost),
            rooms: editedJob.rooms || [],
            recurring: true,
            recurrenceRule: editedJob.recurrenceRule,
            recurrenceGroupId: editedJob.recurrenceGroupId,
            status: 'Pending',
            customerId: selectedJob.customerId,
            organizationId: orgId,
            updatedAt: new Date()
          };
  
          newDates.slice(1).forEach(date => {
            const newJobRef = doc(collection(db, 'organizations', orgId, 'jobs'));
            batch.set(newJobRef, {
              ...updateData,
              appointmentDate: date,
              scheduledEndTime: new Date(date.getTime() + duration),
              createdAt: new Date(),
            });
          });
  
          await batch.commit();
        }
      }
  
      // Update the current instance
      const updateData = {
        customerName: editedJob.customerName,
        cleanerName: editedJob.cleanerName,
        address: editedJob.address,
        notes: editedJob.notes,
        importantNotes: editedJob.importantNotes,
        appointmentDate: appointmentDate,
        scheduledEndTime: scheduledEndTime,
        scheduledDuration: duration,
        totalSize: parseFloat(editedJob.totalSize) || 0,
        serviceCost: parseFloat(editedJob.serviceCost),
        price: parseFloat(editedJob.serviceCost),
        rooms: editedJob.rooms || [],
        recurring: editedJob.recurring,
        recurrenceRule: editedJob.recurring ? editedJob.recurrenceRule : null,
        updatedAt: new Date()
      };
  
      // Update the current instance
      const jobRef = doc(db, 'organizations', orgId, 'jobs', selectedJob.id);
      await updateDoc(jobRef, updateData);
      
      await fetchJobs();
      setIsEditing(false);
      setEditedJob(null);
      handleCloseDialog();
      setSuccess('Job updated successfully.');
    } catch (error) {
      console.error("Error updating job:", error);
      setError("Failed to update job: " + error.message);
    }
  };
  
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedJob(null);
  };
  
  const handleEditInputChange = (field, value) => {
    setEditedJob(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const fetchServiceData = async () => {
    try {
      if (!orgId) return;
  
      // Fetch cleaners from users collection
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);
      const cleanersList = usersSnapshot.docs
        .map(doc => ({ 
          id: doc.id, 
          ...doc.data() 
        }))
        .filter(user => 
          user.orgId === orgId && 
          user.role === 'cleaner' &&
          user.isActive !== false
        );
      setCleaners(cleanersList);
  
      // Fetch room types
      const roomTypesRef = collection(db, 'organizations', orgId, 'roomTypes');
      const roomTypesSnapshot = await getDocs(roomTypesRef);
      const roomTypesList = roomTypesSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.name.localeCompare(b.name));
      setAvailableRooms(roomTypesList);
  
    } catch (err) {
      console.error('Error fetching service data:', err);
      setError('Failed to fetch service data');
    }
  };

  const handleAddRooms = async (rooms) => {
    try {
      if (!editedJob) return;
      setEditedJob(prev => ({
        ...prev,
        rooms: [...(prev.rooms || []), ...rooms]
      }));
    } catch (error) {
      console.error('Error adding rooms:', error);
    }
  };
  
  const handleDeleteRoom = (index) => {
    if (!editedJob) return;
    setEditedJob(prev => ({
      ...prev,
      rooms: prev.rooms.filter((_, i) => i !== index)
    }));
  };

  const handleMarkCompleted = async () => {
    try {
      await completeJob(orgId, selectedJob.id);
      handleCloseDialog();
      await fetchJobs();
    } catch (error) {
      console.error('Error completing job:', error);
    }
  };

  const handleJobDelete = async () => {
    try {
      await handleDeleteJob(orgId, selectedJob.id);
      handleCloseDialog();
      await fetchJobs();
    } catch (error) {
      setError('Failed to delete job');
    }
  };

  const handleRecurringDelete = async () => {
    try {
      await handleDeleteRecurringJob(orgId, selectedJob.recurrenceGroupId);
      handleCloseDialog();
      await fetchJobs();
    } catch (error) {
      setError('Failed to delete recurring jobs');
    }
  };

  const handleDeleteService = async (serviceId) => {
    try {
      const confirmDelete = window.confirm(
        'Are you sure you want to delete this recurring service? This will delete all associated appointments.'
      );
  
      if (!confirmDelete) return;
  
      const batch = writeBatch(db);
      
      // Delete all jobs with this recurrenceGroupId
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const q = query(jobsRef, where('recurrenceGroupId', '==', serviceId));
      const querySnapshot = await getDocs(q);
      
      querySnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });
  
      await batch.commit();
      await fetchJobs();
      setSelectedServiceId(null);
      setSuccess('Service deleted successfully');
    } catch (error) {
      console.error('Error deleting service:', error);
      setError('Failed to delete service');
    }
  };

  const handleFetchRecurring = async () => {
    try {
      const instances = await fetchRecurringInstances(orgId, selectedJob.recurrenceGroupId);
      setRecurringInstances(instances);
      setShowingRecurringSeries(true);
    } catch (error) {
      setError('Failed to fetch recurring instances');
    }
  };

  const handleGenerateMore = async () => {
    try {
      await generateMoreInstances(orgId, selectedJob);
      await handleFetchRecurring();
    } catch (error) {
      setError('Failed to generate more instances');
    }
  };
  
  const getStatusDetails = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return { label: 'Completed', chipColor: '#4caf50', color: 'success' };
      case 'in progress':
        return { label: 'In Progress', chipColor: '#2196f3', color: 'info' };
      case 'pending':
        return { label: 'Pending', chipColor: '#ff9800', color: 'warning' };
      case 'cancelled':
        return { label: 'Cancelled', chipColor: '#f44336', color: 'error' };
      default:
        return { label: status, chipColor: '#757575', color: 'default' };
    }
  };

  const determineServiceType = (jobs) => {
    const recurringJob = jobs.find(job => job.recurring && job.recurrenceRule);
    
    if (recurringJob) {
      return {
        type: 'Recurring',
        recurrenceDetails: formatRecurrenceRule(recurringJob.recurrenceRule)
      };
    }
    
    return {
      type: 'One-Time',
      recurrenceDetails: null
    };
  };

  const groupRecurringServices = (jobsList) => {
    const servicesMap = jobsList.reduce((acc, job) => {
      if (job.recurring && job.recurrenceGroupId) {
        if (!acc[job.recurrenceGroupId]) {
          acc[job.recurrenceGroupId] = {
            id: job.recurrenceGroupId,
            serviceType: job.serviceType || 'Standard Service',
            recurrenceRule: job.recurrenceRule,
            serviceCost: job.serviceCost || job.price || 0,
            status: job.status,
            metrics: {
              revenueToDate: 0,
              completedJobsCount: 0,
              avgDuration: 0,
              totalDuration: 0,
              nextAppointment: null,
              jobs: []
            }
          };
        } else {
          if (!acc[job.recurrenceGroupId].serviceType && job.serviceType) {
            acc[job.recurrenceGroupId].serviceType = job.serviceType;
          }
        }
  
        // Update metrics for this service
        const metrics = acc[job.recurrenceGroupId].metrics;
        metrics.jobs.push(job);
  
        if (job.status.toLowerCase() === 'completed') {
          metrics.completedJobsCount++;
          metrics.revenueToDate += job.price || 0;
          
          // Safely handle time calculations with proper null checks
          if (job.startTime && job.endTime) {
            try {
              // Convert to Date object if Timestamp
              const startTime = job.startTime?.toDate?.() || job.startTime;
              const endTime = job.endTime?.toDate?.() || job.endTime;
              
              if (startTime instanceof Date && endTime instanceof Date) {
                const duration = moment(endTime).diff(moment(startTime), 'minutes');
                if (!isNaN(duration) && duration > 0) {
                  metrics.totalDuration += duration;
                }
              }
            } catch (err) {
              console.error('Error calculating duration:', err);
            }
          }
        }
  
        // Track next appointment with proper null checks
        if (job.appointmentDate) {
          try {
            const appointmentDate = job.appointmentDate?.toDate?.() || job.appointmentDate;
            
            if (appointmentDate instanceof Date && appointmentDate > new Date()) {
              if (!metrics.nextAppointment || appointmentDate < metrics.nextAppointment) {
                metrics.nextAppointment = appointmentDate;
              }
            }
          } catch (err) {
            console.error('Error processing appointment date:', err);
          }
        }
      }
      return acc;
    }, {});
  
    // Calculate averages and sort jobs for each service
    Object.values(servicesMap).forEach(service => {
      const metrics = service.metrics;
      
      // Safely calculate average duration
      if (metrics.completedJobsCount > 0 && metrics.totalDuration > 0) {
        metrics.avgDuration = metrics.totalDuration / metrics.completedJobsCount;
      } else {
        metrics.avgDuration = 0;
      }
  
      // Sort jobs by appointment date with null checks
      metrics.jobs.sort((a, b) => {
        try {
          const dateA = a.appointmentDate?.toDate?.() || a.appointmentDate;
          const dateB = b.appointmentDate?.toDate?.() || b.appointmentDate;
          
          if (dateA instanceof Date && dateB instanceof Date) {
            return moment(dateA).diff(moment(dateB));
          }
          return 0;
        } catch (err) {
          console.error('Error sorting jobs:', err);
          return 0;
        }
      });
    });
  
    return Object.values(servicesMap);
  };
  
  
  // Add a function to handle service selection
  const handleServiceSelect = (serviceId) => {
    setSelectedServiceId(serviceId);
  };

  useEffect(() => {
    if (orgId && customerId) {
      fetchCustomer();
      fetchJobs();
      fetchFirstAppointment();
      fetchServiceData(); // Add this line
    }
  }, [orgId, customerId]);

  useEffect(() => {
    if (jobs.length > 0) {
      const services = groupRecurringServices(jobs);
      setRecurringServices(services);
      
      // Set the first service as selected if none is selected
      if (services.length > 0 && !selectedServiceId) {
        setSelectedServiceId(services[0].id);
      }
    }
  }, [jobs]);

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">Error: {error}</Typography>;
  if (!customer) return <Typography>No customer details available.</Typography>;

  return (
    <div className={styles.container}>
      <div className={styles.profileHeader}>
        <button 
          onClick={() => navigate('/jobs/clients')}
          className={styles.backButton}
        >
          <ArrowLeft className={styles.backIcon} />
          Back to Clients
        </button>
        <h1 className={styles.customerName}>{customer?.name || 'Customer Profile'}</h1>
      </div>
      <div className={styles.tabs}>
        <button
          className={`${styles.tabButton} ${activeTab === 'profile' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('profile')}
        >
          Customer Profile
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'recurring' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('recurring')}
        >
          Recurring Services
        </button>
      </div>
  
      <div className={styles.tabContent}>
        {activeTab === 'profile' ? (
          <div className={styles.profileContainer}>
            <div className={styles.topSection}>
              {/* Customer Information Card */}
              <StyledCard className={styles.topLeftCard}>
                <CardContent>
                  <StyledBox>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      <Typography variant="h6">Client Information</Typography>
                      <IconButton 
                        onClick={handleEditCustomerClick}
                        size="small"
                        sx={{ 
                          color: 'var(--dark-primary)',
                          '&:hover': {
                            backgroundColor: 'rgba(var(--dark-primary-rgb), 0.04)'
                          }
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                    <Box>
                      <Typography component="span"><strong>Name:</strong> {customer.name}</Typography>
                    </Box>
                    <Box>
                      <Typography component="span"><strong>Phone:</strong> {customer.phone}</Typography>
                    </Box>
                    <Box>
                      <Typography component="span"><strong>Email:</strong> {customer.email}</Typography>
                    </Box>
                    <Box>
                      <Typography component="span"><strong>Address:</strong> {customer.serviceAddress}</Typography>
                    </Box>
                  </StyledBox>
                </CardContent>
              </StyledCard>
  
              {/* Financial Summary Card */}
              <StyledCard className={styles.topRightCard}>
                <CardContent>
                  <StyledBox>
                    <Typography variant="h6" gutterBottom>Financial Summary</Typography>
                    <Box>
                      <Typography component="span">
                        <strong>Revenue to Date:</strong> ${metrics.revenueToDate.toFixed(2)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography component="span">
                        <strong>Unpaid Balance:</strong> ${metrics.unpaidBalance.toFixed(2)}
                      </Typography>
                    </Box>
                  </StyledBox>
                </CardContent>
              </StyledCard>
            </div>
  
            {recurringServices.map(service => {
            const serviceJobs = jobs.filter(job => job.recurrenceGroupId === service.id);
            const metrics = calculateServiceMetrics(serviceJobs);
            
            // Get the latest job to get the current service cost
            const latestJob = serviceJobs.reduce((latest, job) => {
              if (!latest || (job.updatedAt && latest.updatedAt && job.updatedAt.toDate() > latest.updatedAt.toDate())) {
                return job;
              }
              return latest;
            }, null);

            const currentServiceCost = parseFloat(latestJob?.serviceCost) 
              || parseFloat(latestJob?.price) 
              || parseFloat(service.serviceCost) 
              || 0;
            
            return (
              <div key={service.id} className={styles.serviceMetricsSection}>
                <Typography 
                  variant="h6" 
                  gutterBottom 
                  sx={{ 
                    px: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    mb: 2
                  }}
                >
                  {service.recurrenceRule ? 'Recurring' : 'One-Time'} Services Information
                </Typography>

                {/* Service Metrics Section */}
                <StyledCard className={styles.jobInfoCard}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom sx={{ px: 2 }}>Service Information</Typography>
                    <div className={styles.metricsRow}>
                      <ServiceMetricBox>
                        <MetricLabel>First Service Date</MetricLabel>
                        <MetricValue>
                          {firstAppointment 
                            ? moment(firstAppointment.toDate()).format('MM/DD/YYYY')
                            : 'N/A'}
                        </MetricValue>
                      </ServiceMetricBox>

                      <ServiceMetricBox>
                        <MetricLabel>Service Type</MetricLabel>
                        <MetricValue>
                          {service.recurrenceRule ? 'Recurring' : 'One-Time'}
                        </MetricValue>
                        {service.recurrenceRule && (
                          <Typography 
                            variant="caption" 
                            display="block" 
                            sx={{ 
                              color: 'var(--dark-text-light)',
                              fontSize: '0.75rem',
                              marginTop: '4px'
                            }}
                          >
                            {formatRecurrenceRule(service.recurrenceRule)}
                          </Typography>
                        )}
                      </ServiceMetricBox>

                      <ServiceMetricBox>
                        <MetricLabel>Completed Services</MetricLabel>
                        <MetricValue>
                          {metrics?.completedJobsCount || 0}
                        </MetricValue>
                      </ServiceMetricBox>

                      <ServiceMetricBox>
                        <MetricLabel>Average Duration</MetricLabel>
                        <MetricValue>
                          {Number(metrics?.avgDuration || 0).toFixed(2)} hrs
                        </MetricValue>
                      </ServiceMetricBox>
                    </div>
                  </CardContent>
                </StyledCard>

                {/* Financial Metrics Card */}
                <StyledCard className={styles.jobInfoCard}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom sx={{ px: 2 }}>Financial Metrics</Typography>
                    <div className={styles.metricsRow}>
                      <StyledMetricBox>
                        <MetricLabel>Service Cost</MetricLabel>

                        <MetricValue>
                          {service.serviceCost || 0}
                        </MetricValue>
                      </StyledMetricBox>

                      <StyledMetricBox>
                        <MetricLabel>Average Revenue Per Service</MetricLabel>
                        <MetricValue>
                          ${Number(metrics?.avgRevenuePerService || 0).toFixed(2)}
                        </MetricValue>
                      </StyledMetricBox>

                      <StyledMetricBox>
                        <MetricLabel>Average Payroll Cost</MetricLabel>
                        <MetricValue>
                          ${Number(metrics?.avgPayrollCost || 0).toFixed(2)}
                        </MetricValue>
                      </StyledMetricBox>

                      <StyledMetricBox>
                        <MetricLabel>Average Profit Per Service</MetricLabel>
                        <MetricValue>
                          ${Number(metrics?.avgProfit || 0).toFixed(2)}
                        </MetricValue>
                      </StyledMetricBox>
                    </div>
                  </CardContent>
                </StyledCard>
                
                <Divider sx={{ my: 4 }} />
              </div>
            );
          })}
  
            {/* Services List Card */}
            <StyledCard className={styles.servicesCard}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ px: 2 }}>Services</Typography>
                <StyledTabs 
                  value={servicesTab} 
                  onChange={handleTabChange}
                >
                  <StyledTab label="Upcoming Services" />
                  <StyledTab label="Past Services" />
                </StyledTabs>
                
                <List>
                {jobs
                  .filter((job) => {
                    const jobDate = job.appointmentDate;
                    if (!jobDate) return false;
                    
                    try {
                      const now = moment();
                      // Safely handle date conversion
                      const appointmentDate = jobDate.toDate?.() 
                        ? moment(jobDate.toDate()) 
                        : moment(jobDate);
                      
                      return servicesTab === 0 
                        ? appointmentDate.isSameOrAfter(now, 'day')
                        : appointmentDate.isBefore(now, 'day');
                    } catch (err) {
                      console.error('Error processing date:', err);
                      return false;
                    }
                  })
                  .sort((a, b) => {
                    try {
                      // Safely handle date conversion for sorting
                      const dateA = a.appointmentDate.toDate?.() 
                        ? moment(a.appointmentDate.toDate()) 
                        : moment(a.appointmentDate);
                      const dateB = b.appointmentDate.toDate?.() 
                        ? moment(b.appointmentDate.toDate()) 
                        : moment(b.appointmentDate);
                      
                      return servicesTab === 0 
                        ? dateA.valueOf() - dateB.valueOf()
                        : dateB.valueOf() - dateA.valueOf();
                    } catch (err) {
                      console.error('Error sorting dates:', err);
                      return 0;
                    }
                  })
                  .map((job) => (
                    <ServiceListItem 
                      key={job.id}
                      onClick={() => handleJobClick(job)}
                    >
                      <ListItemText
                        primary={
                          <Typography component="div" variant="subtitle1">
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <span>
                                {job.appointmentDate.toDate?.() 
                                  ? moment(job.appointmentDate.toDate()).format('MM/DD/YYYY hh:mm A')
                                  : moment(job.appointmentDate).format('MM/DD/YYYY hh:mm A')}
                              </span>
                              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                {job.recurring && (
                                  <Chip
                                    label="Recurring"
                                    size="small"
                                    sx={{ backgroundColor: '#2196f3', color: 'white' }}
                                  />
                                )}
                                <Chip
                                  label={getStatusDetails(job.status).label}
                                  size="small"
                                  sx={{ 
                                    backgroundColor: getStatusDetails(job.status).chipColor,
                                    color: 'white'
                                  }}
                                />
                              </Box>
                            </Box>
                          </Typography>
                        }
                        secondary={
                          <Typography component="div" variant="body2" sx={{ mt: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <span>Cleaner: {job.cleanerName || 'Unassigned'}</span>
                              {job.recurring && (
                                <span>
                                  Service Type: {job.serviceType || 'Standard Service'}
                                </span>
                              )}
                            </Box>
                          </Typography>
                        }
                      />
                    </ServiceListItem>
                  ))}
                </List>
              </CardContent>
            </StyledCard>
          </div>
        ) : (
          // Recurring Services Tab Content
          <div className={styles.recurringServicesContainer}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError('')}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess('')}>
                {success}
              </Alert>
            )}
  
            {recurringServices.length > 0 ? (
              <>
            {recurringServices.length > 1 && (
              <StyledCard sx={{ mb: 3 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">Active Recurring Services</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setIsAddingNewService(true)}
                      startIcon={<AddIcon />}
                      size="small"
                    >
                      Add New Service
                    </Button>
                  </Box>
                  <div className={styles.serviceCards}>
                    {recurringServices.map((service) => (
                      <StyledCard 
                        key={service.id}
                        className={`${styles.serviceCard} ${selectedServiceId === service.id ? styles.selectedCard : ''}`}
                        onClick={() => handleServiceSelect(service.id)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <CardContent>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="subtitle1">{service.recurrenceRule ? 'Recurring' : 'One-Time'}</Typography>
                            <Button
                              variant="contained"
                              color="error"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent card selection when clicking delete
                                handleDeleteService(service.id);
                              }}
                            >
                              Delete
                            </Button>
                          </Box>
                          <Typography variant="body2" sx={{ mb: 1 }}>
                            {formatRecurrenceRule(service.recurrenceRule)}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Next Service: {service.metrics.nextAppointment 
                              ? moment(service.metrics.nextAppointment).format('MM/DD/YYYY')
                              : 'None scheduled'}
                          </Typography>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                            <Chip
                              label={getStatusDetails(service.status).label}
                              sx={{ 
                                backgroundColor: getStatusDetails(service.status).chipColor,
                                color: 'white'
                              }}
                              size="small"
                            />
                            <Typography variant="body2">
                              {service.serviceCost}/service
                            </Typography>
                          </Box>
                        </CardContent>
                      </StyledCard>
                    ))}
                  </div>
                </CardContent>
              </StyledCard>
            )}
  
                {selectedServiceId && (
                <EditRecurringService
                  customer={customer}
                  jobs={recurringServices.find(s => s.id === selectedServiceId).metrics.jobs}
                  orgId={orgId}
                  onCancelService={handleCancelService}
                  fetchJobs={fetchJobs}
                  recurrenceGroupId={selectedServiceId}
                  service={recurringServices.find(s => s.id === selectedServiceId)} // Passing 'service' prop
                />
              )}
              </>
            ) : (
              <div className={styles.noServices}>
                <Typography variant="h6" gutterBottom>No Recurring Services</Typography>
                <Typography color="textSecondary" sx={{ mb: 3 }}>
                  This customer doesn't have any recurring services set up yet.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setIsAddingNewService(true)}
                  startIcon={<AddIcon />}
                >
                  Create Recurring Service
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
  
      {/* Modals */}
      <JobDialog
      open={isDialogOpen}
      onClose={handleCloseDialog}
      job={selectedJob}
      isEditing={isEditing}
      handleEditClick={handleEditClick}
      handleDeleteJob={handleJobDelete}
      handleMarkCompleted={handleMarkCompleted}
      handleDeleteRecurringJob={handleRecurringDelete}
      fetchRecurringInstances={handleFetchRecurring}
      showingRecurringSeries={showingRecurringSeries}
      recurringInstances={recurringInstances}
      generateMoreInstances={handleGenerateMore}
      handleSaveEdit={handleSaveEdit}
      handleCancelEdit={handleCancelEdit}
      editedJob={editedJob}
      handleEditInputChange={handleEditInputChange}
      handleTotalSizeChange={handleTotalSizeChange} // Pass the function
      totalSize={totalSize} // Pass totalSize
      setTotalSize={setTotalSize} // Pass setTotalSize
      openAddRoomsModal={false}
      setOpenAddRoomsModal={() => {}}
      availableRooms={[]}
      formatRecurrenceRule={formatRecurrenceRule}
      getStatusDetails={getStatusDetails}
      RecurrenceSelect={RecurrenceSelect}
    />
  
      <CreateCustomerModal
        open={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}
        handleCreateCustomer={handleUpdateCustomer}
        isEditing={true}
        initialData={editCustomerData}
      />
  
      {/* Add New Service Modal */}
      <Dialog
        open={isAddingNewService}
        onClose={() => setIsAddingNewService(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create New Recurring Service</DialogTitle>
        <DialogContent>
        <CreateRecurringService
          customer={customer}
          orgId={orgId}
          cleaners={cleaners}
          totalSize={totalSize} // Pass totalSize
          setTotalSize={setTotalSize} // Pass setter for totalSize
          availableRooms={availableRooms}
          onServiceCreated={async () => {
            await fetchJobs();
            setIsAddingNewService(false);
            setSuccess('New recurring service created successfully');
          }}
          onCancel={() => setIsAddingNewService(false)}
        />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CustomerProfileManagement;






