// /src/context/TimezoneContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment-timezone';

const TimezoneContext = createContext();

export const useTimezone = () => useContext(TimezoneContext);

export const TimezoneProvider = ({ children, orgId }) => {
  const [timezone, setTimezone] = useState('America/New_York');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (orgId) {
      fetchOrgTimezone();
    }
  }, [orgId]);

  const fetchOrgTimezone = async () => {
    try {
      const orgRef = doc(db, 'organizations', orgId);
      const orgDoc = await getDoc(orgRef);
      
      if (orgDoc.exists()) {
        const data = orgDoc.data();
        setTimezone(data.timezone || 'America/New_York');
      }
      setLoading(false);
    } catch (err) {
      console.error('Error fetching timezone:', err);
      setLoading(false);
    }
  };

  // Helper functions for timezone conversion
  const formatInOrgTz = (date, format = 'YYYY-MM-DD hh:mm A') => {
    if (!date) return '';
    // Handle Firestore Timestamp
    const momentDate = date.toDate ? moment(date.toDate()) : moment(date);
    return momentDate.tz(timezone).format(format);
  };

  const convertToOrgTz = (date) => {
    if (!date) return null;
    const momentDate = date.toDate ? moment(date.toDate()) : moment(date);
    return moment.tz(momentDate, timezone);
  };

  const convertFromOrgTz = (date) => {
    if (!date) return null;
    return moment.tz(date, timezone).utc();
  };

  return (
    <TimezoneContext.Provider value={{
      timezone,
      loading,
      formatInOrgTz,
      convertToOrgTz,
      convertFromOrgTz,
      moment // Expose moment for additional formatting if needed
    }}>
      {children}
    </TimezoneContext.Provider>
  );
};