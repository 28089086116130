// src/utils/formatRecurrenceRule.js

import { rrulestr } from 'rrule';

export const parseRecurrenceRule = (rule) => {
  if (!rule) return 'No recurrence set';
  
  try {
    const rrule = rrulestr(`RRULE:${rule}`);
    const text = rrule.toText();
    
    // Capitalize first letter and make text more readable
    const formattedText = text.charAt(0).toUpperCase() + text.slice(1)
      .replace('on the', 'every')
      .replace(' day of the week', '')
      .replace(' day of the month', '');
    
    return formattedText;
  } catch (error) {
    console.error('Error parsing recurrence rule:', error);
    return 'Invalid recurrence rule';
  }
};

export const formatRecurrenceRule = (rule) => {
  if (!rule) return 'No recurrence set';
  
  try {
    return parseRecurrenceRule(rule);
  } catch (error) {
    console.error('Error formatting recurrence rule:', error);
    return 'Invalid recurrence rule';
  }
};