// src/components/CleanersReporting.js

import React, { useEffect, useState } from 'react';
import { 
  Typography, Card, CardContent, Avatar, 
  Select, MenuItem, FormControl, InputLabel,
  Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, IconButton,
  Collapse, Chip
} from '@mui/material';
import { Grid, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { ChevronDown, ChevronUp } from 'lucide-react';
import moment from 'moment';
import styles from '../styles/CleanersReporting.module.css';

const MetricCard = ({ title, value, percentage, unit = '' }) => (
  <Card className={styles.metricCard}>
    <CardContent className={styles.metricCardContent}>
      <Typography className={styles.metricTitle}>
        {title}
      </Typography>
      <Typography className={styles.metricValue}>
        {value === 'N/A' ? 'N/A' : (unit === '$' ? `${unit}${value}` : `${value}${unit}`)}
      </Typography>
      {percentage !== undefined && (
        <Typography className={styles.metricPercentage} 
          sx={{ 
            color: percentage >= 0 ? 'success.main' : 'error.main',
            fontSize: '0.875rem',
            fontWeight: 500
          }}>
          ({percentage > 0 ? '+' : ''}{percentage.toFixed(1)}%)
        </Typography>
      )}
    </CardContent>
  </Card>
);

// Add these period options
const PERIOD_OPTIONS = {
  MONTH: 'month',
  QUARTER: 'quarter',
  ALL_TIME: 'all-time'
};

const JobDetailsTable = ({ jobs, payRate }) => {
  // Helper function to safely handle dates
  const formatDate = (dateValue) => {
    if (!dateValue) return null;
    
    if (dateValue?.toDate) {
      return dateValue.toDate();
    }
    
    if (dateValue instanceof Date) {
      return dateValue;
    }
    
    if (typeof dateValue === 'number') {
      return new Date(dateValue);
    }

    return new Date(dateValue);
  };

  // Sort jobs by appointment date
  const sortedJobs = [...jobs].sort((a, b) => {
    const dateA = formatDate(a.appointmentDate);
    const dateB = formatDate(b.appointmentDate);
    
    if (!dateA || !dateB) return 0;
    return dateA - dateB;
  });

  // Helper function to get status chip color
  const getStatusColor = (status) => {
    if (!status) return 'default';
    
    switch (status.toLowerCase()) {
      case 'completed':
        return 'success';
      case 'in progress':
        return 'primary';
      case 'pending':
        return 'warning';
      case 'cancelled':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <TableContainer component={Paper} className={styles.tableContainer}>
      <Table size="small" aria-label="job details">
        <TableHead>
          <TableRow>
            <TableCell>Client</TableCell>
            <TableCell>Apt Date</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>End Time</TableCell>
            <TableCell>Efficiency Rating</TableCell>
            <TableCell>Cost of Job</TableCell>
            <TableCell>Profit</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedJobs.map((job) => {
            const startTime = formatDate(job.startTime);
            const endTime = formatDate(job.endTime);
            const appointmentDate = formatDate(job.appointmentDate);
            const cost = parseFloat(job.serviceCost || job.price || 0);
            
            let duration = 0;
            if (startTime && endTime) {
              duration = (endTime - startTime) / (1000 * 60 * 60);
            }
            
            const laborCost = duration * (payRate || 0);
            const profit = cost - laborCost;

            const efficiency = job.efficiencyRating || 
              (job.scheduledDuration && duration ? 
                (job.scheduledDuration / (duration * 1000 * 60 * 60)) * 100 : 
                null);

            return (
              <TableRow key={job.id}>
                <TableCell>{job.customerName || 'N/A'}</TableCell>
                <TableCell>
                  {appointmentDate ? moment(appointmentDate).format('MM/DD/YYYY') : 'N/A'}
                </TableCell>
                <TableCell>
                  {startTime ? moment(startTime).format('hh:mm A') : 'N/A'}
                </TableCell>
                <TableCell>
                  {endTime ? moment(endTime).format('hh:mm A') : 'N/A'}
                </TableCell>
                <TableCell>
                  {efficiency ? `${efficiency.toFixed(1)}%` : 'N/A'}
                </TableCell>
                <TableCell>${cost.toFixed(2)}</TableCell>
                <TableCell className={profit < 0 ? styles.negativeProfit : styles.positiveProfit}>
                  ${profit.toFixed(2)}
                </TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    label={job.status || 'N/A'}
                    color={getStatusColor(job.status)}
                    sx={{ 
                      minWidth: '90px',
                      justifyContent: 'center'
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CleanersReporting = ({ orgId }) => {
  const [cleaners, setCleaners] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM')); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedCleaner, setExpandedCleaner] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(PERIOD_OPTIONS.MONTH);
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM'));

  const fetchCleaners = async () => {
    try {
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);
      const cleanersList = usersSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(user =>
          user.orgId === orgId &&
          user.role === 'cleaner' &&
          user.isActive !== false
        );
      setCleaners(cleanersList);
    } catch (err) {
      console.error('Error fetching cleaners:', err);
      setError('Failed to load cleaners.');
    }
  };

  const fetchJobs = async () => {
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const jobsSnapshot = await getDocs(jobsRef);
      const jobsList = jobsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          appointmentDate: data.appointmentDate?.toDate ? data.appointmentDate.toDate() : data.appointmentDate
        };
      });
      setJobs(jobsList);
    } catch (err) {
      console.error('Error fetching jobs:', err);
      setError('Failed to load jobs.');
    }
  };

  const getDateRange = () => {
    const now = moment();
    switch (selectedPeriod) {
      case PERIOD_OPTIONS.MONTH:
        return {
          start: moment(selectedDate).startOf('month'),
          end: moment(selectedDate).endOf('month')
        };
      case PERIOD_OPTIONS.QUARTER:
        return {
          start: moment(selectedDate).startOf('quarter'),
          end: moment(selectedDate).endOf('quarter')
        };
      case PERIOD_OPTIONS.ALL_TIME:
        return {
          start: moment(0), // Beginning of time
          end: now
        };
      default:
        return {
          start: moment(selectedDate).startOf('month'),
          end: moment(selectedDate).endOf('month')
        };
    }
  };

  useEffect(() => {
    if (!orgId) return;
    setLoading(true);
    Promise.all([fetchCleaners(), fetchJobs()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [orgId]);

  const handleExpandClick = (cleanerId) => {
    setExpandedCleaner(expandedCleaner === cleanerId ? null : cleanerId);
  };

  // Updated jobs filter
  const getFilteredJobs = (jobs, period) => {
    const { start, end } = getDateRange();
    return jobs.filter(job => {
      if (!job.appointmentDate) return false;
      const jobDate = moment(job.appointmentDate);
      return jobDate.isBetween(start, end, 'day', '[]');
    });
  };

  const getCleanerMetrics = (cleaner, filteredJobs) => {
    const cleanerJobs = filteredJobs.filter(job => job.cleanerId === cleaner.id);
    const completedJobs = cleanerJobs.filter(job => job.status?.toLowerCase() === 'completed');
    
    let totalRevenue = 0;
    let totalProfit = 0;
    let totalDuration = 0;
    let totalEfficiency = 0;
    let jobsWithEfficiency = 0;
  
    completedJobs.forEach(job => {
      // Calculate revenue and profit
      const cost = parseFloat(job.serviceCost || job.price || 0);
      totalRevenue += cost;
      
      // Calculate duration and labor cost
      if (job.startTime && job.endTime) {
        const startTime = moment(job.startTime.toDate?.() || job.startTime);
        const endTime = moment(job.endTime.toDate?.() || job.endTime);
        const duration = endTime.diff(startTime, 'hours', true);
        totalDuration += duration;
        
        const laborCost = duration * (cleaner.payRate || 0);
        totalProfit += (cost - laborCost);
  
        // Calculate efficiency for this job if it has scheduled duration
        if (job.scheduledDuration) {
          const scheduledHours = job.scheduledDuration / (1000 * 60 * 60);
          const actualHours = duration;
          const jobEfficiency = (scheduledHours / actualHours) * 100;
          
          if (!isNaN(jobEfficiency) && isFinite(jobEfficiency)) {
            totalEfficiency += jobEfficiency;
            jobsWithEfficiency++;
          }
        }
      } else {
        totalProfit += cost;
      }
    });
  
    const avgEfficiency = jobsWithEfficiency > 0 ? 
      (totalEfficiency / jobsWithEfficiency) : 0;
  
    // Calculate profit percentages
    const totalProfitPercentage = totalRevenue > 0 ? (totalProfit / totalRevenue) * 100 : 0;
    const avgProfitPerJob = completedJobs.length > 0 ? totalProfit / completedJobs.length : 0;
    const avgRevenuePerJob = completedJobs.length > 0 ? totalRevenue / completedJobs.length : 0;
    const avgProfitPercentage = avgRevenuePerJob > 0 ? (avgProfitPerJob / avgRevenuePerJob) * 100 : 0;
  
    return {
      totalJobs: cleanerJobs.length,
      completedJobs: completedJobs.length,
      totalRevenue: totalRevenue.toFixed(2),
      totalProfit: totalProfit.toFixed(2),
      totalProfitPercentage,
      avgProfit: avgProfitPerJob.toFixed(2),
      avgProfitPercentage,
      efficiency: avgEfficiency.toFixed(1),
      avgDuration: completedJobs.length > 0 ? 
        (totalDuration / completedJobs.length).toFixed(2) : '0.00',
      fiveStarReviews: 0
    };
  };

    // Period selection handler
    const handlePeriodChange = (event, newPeriod) => {
      if (newPeriod !== null) {
        setSelectedPeriod(newPeriod);
        // Reset date selection when switching to all-time
        if (newPeriod === PERIOD_OPTIONS.ALL_TIME) {
          setSelectedDate(moment().format('YYYY-MM'));
        }
      }
    };

  if (loading) return <div className="flex justify-center items-center h-64"><div className="animate-spin h-8 w-8 border-4 border-blue-500 rounded-full border-t-transparent"></div></div>;
  if (error) return <div className="p-4 text-red-500">{error}</div>;

  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <Typography variant="h5">Cleaner Metrics</Typography>
        <div className={styles.periodSelector}>
          <ToggleButtonGroup
            value={selectedPeriod}
            exclusive
            onChange={handlePeriodChange}
            size="small"
          >
            <ToggleButton value={PERIOD_OPTIONS.MONTH}>Monthly</ToggleButton>
            <ToggleButton value={PERIOD_OPTIONS.QUARTER}>Quarterly</ToggleButton>
            <ToggleButton value={PERIOD_OPTIONS.ALL_TIME}>All Time</ToggleButton>
          </ToggleButtonGroup>
          
          {selectedPeriod !== PERIOD_OPTIONS.ALL_TIME && (
            <FormControl variant="outlined" size="small" className={styles.dateSelect}>
              <InputLabel id="date-select-label">
                {selectedPeriod === PERIOD_OPTIONS.MONTH ? 'Month' : 'Quarter'}
              </InputLabel>
              <Select
                labelId="date-select-label"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                label={selectedPeriod === PERIOD_OPTIONS.MONTH ? 'Month' : 'Quarter'}
              >
                {/* Generate appropriate options based on period type */}
                {[...Array(6)].map((_, i) => {
                  const date = moment().subtract(i, selectedPeriod === PERIOD_OPTIONS.MONTH ? 'months' : 'quarters');
                  const value = date.format('YYYY-MM');
                  const label = selectedPeriod === PERIOD_OPTIONS.MONTH ? 
                    date.format('MMMM YYYY') :
                    `Q${date.quarter()} ${date.year()}`;
                  return (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </div>
      </div>


      {cleaners.length === 0 ? (
      <Typography>No cleaners found for this organization.</Typography>
    ) : (
      <div className={styles.cleanersList}>
        {cleaners.map(cleaner => {
          // Get filtered jobs for the selected period
          const filteredJobs = getFilteredJobs(jobs, selectedPeriod);
          const metrics = getCleanerMetrics(cleaner, filteredJobs);
          const cleanerName = cleaner.firstName && cleaner.lastName 
            ? `${cleaner.firstName} ${cleaner.lastName}` 
            : cleaner.email;
          
          // Filter jobs for this specific cleaner
          const cleanerJobs = filteredJobs.filter(
            job => job.cleanerId === cleaner.id
          );

          return (
            <div key={cleaner.id} className={styles.cleanerSection}>
              <div className={styles.cleanerHeader}>
                <div className={styles.cleanerInfo}>
                  <Avatar className={styles.cleanerAvatar}>
                    {cleanerName.charAt(0).toUpperCase()}
                  </Avatar>
                  <Typography variant="h6">{cleanerName}</Typography>
                </div>
                <IconButton
                  onClick={() => handleExpandClick(cleaner.id)}
                  className={styles.expandButton}
                  aria-expanded={expandedCleaner === cleaner.id}
                  aria-label="show job details"
                >
                  {expandedCleaner === cleaner.id ? (
                    <ChevronUp />
                  ) : (
                    <ChevronDown />
                  )}
                </IconButton>
              </div>
              
              <div className={styles.metricsGrid}>
                
              <MetricCard 
                  title="Completed Jobs" 
                  value={metrics.completedJobs}
                />
                <MetricCard 
                  title="TotalRevenue" 
                  value={metrics.totalRevenue} 
                  unit="$"
                />

                <MetricCard 
                  title="Total Profit" 
                  value={metrics.totalProfit}
                  percentage={metrics.totalProfitPercentage}
                  unit="$"
                />

                <MetricCard 
                  title="Average Profit per Job" 
                  value={metrics.avgProfit}
                  percentage={metrics.avgProfitPercentage}
                  unit="$"
                />
                <MetricCard 
                  title="Efficiency Rating" 
                  value={metrics.efficiency}
                  unit="%"
                />
                <MetricCard 
                  title="Average Duration" 
                  value={metrics.avgDuration}
                  unit=" hrs"
                />
              </div>

              <Collapse in={expandedCleaner === cleaner.id} timeout="auto" unmountOnExit>
                <div className={styles.jobDetailsSection}>
                  <JobDetailsTable 
                    jobs={cleanerJobs} 
                    payRate={cleaner.payRate}
                  />
                </div>
              </Collapse>
            </div>
          );
        })}
      </div>
    )}
  </div>
);
};

export default CleanersReporting;
