// src/hooks/usePlaces.js
// Autocomplete for Searching for an Address

import { useState, useCallback } from 'react';
import { placesApi } from '../utils/api';

export const usePlaces = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handlePlaceSelect = useCallback(async (place) => {
    if (!place.address_components) return null;

    const addressData = {
      streetNumber: '',
      streetName: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
      formatted: place.formatted_address || ''
    };

    place.address_components.forEach(component => {
      const types = component.types;

      if (types.includes('street_number')) {
        addressData.streetNumber = component.long_name;
      }
      if (types.includes('route')) {
        addressData.streetName = component.long_name;
      }
      if (types.includes('subpremise')) {
        addressData.line2 = component.long_name;
      }
      if (types.includes('locality')) {
        addressData.city = component.long_name;
      }
      if (types.includes('administrative_area_level_1')) {
        addressData.state = component.short_name;
      }
      if (types.includes('postal_code')) {
        addressData.zipCode = component.long_name;
      }
    });

    return {
      line1: `${addressData.streetNumber} ${addressData.streetName}`.trim(),
      line2: addressData.line2,
      city: addressData.city,
      state: addressData.state,
      zip: addressData.zipCode,
      complete: addressData.formatted
    };
  }, []);

  return {
    loading,
    error,
    handlePlaceSelect
  };
};