// /src/components/CleanerJobsOverview.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useTimezone } from '../context/TimeZoneContext';
import { 
  Box,
  Typography,
  Chip,
  Card,
  CardContent,
  CircularProgress,
  useMediaQuery,
  Skeleton
} from '@mui/material';
import { Clock, MapPin, ChevronLeft, ChevronRight } from 'lucide-react';
import MobileHeader from './MobileHeader';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment-timezone';
import styles from '../styles/CleanerJobsOverview.module.css';

const WEEKS_TO_LOAD = 2;

const CleanerJobsOverview = () => {
  const { cleanerId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:768px)');
  const dateScrollRef = useRef(null);
  const jobsScrollRef = useRef(null);
  const { formatInOrgTz, timezone } = useTimezone();
  
  const [jobs, setJobs] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment().tz(timezone).startOf('day'));
  const [visibleDates, setVisibleDates] = useState([]);
  const [weekDates, setWeekDates] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().tz(timezone).startOf('day').subtract(WEEKS_TO_LOAD, 'weeks')
  );
  const [endDate, setEndDate] = useState(
    moment().tz(timezone).startOf('day').add(WEEKS_TO_LOAD, 'weeks')
  );

  useEffect(() => {
    const root = document.body; // Adjust based on where you're applying the theme
    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    root.classList.toggle('dark-mode', isDarkMode); // Apply dark-mode class to <body>
  }, []);

  // Generate week dates for the date picker
  useEffect(() => {
    const dates = [];
    let currentDate = selectedDate.clone().startOf('week');
    for (let i = 0; i < 7; i++) {
      dates.push(currentDate.clone());
      currentDate.add(1, 'day');
    }
    setWeekDates(dates);
  }, [selectedDate]);

  // Generate visible dates for job listing
  useEffect(() => {
    const dates = [];
    let currentDate = startDate.clone();
    while (currentDate.isSameOrBefore(endDate)) {
      dates.push(currentDate.clone());
      currentDate.add(1, 'day');
    }
    setVisibleDates(dates);
  }, [startDate, endDate]);

  const fetchJobsForDateRange = async (start, end) => {
    if (!user?.orgId || !cleanerId) return;

    try {
      setLoadingMore(true);
      const jobsRef = collection(db, 'organizations', user.orgId, 'jobs');
      
      const jobQuery = query(
        jobsRef,
        where('cleanerId', '==', cleanerId)
      );

      const querySnapshot = await getDocs(jobQuery);
      const fetchedJobs = {};
      
      let currentDate = start.clone();
      while (currentDate.isSameOrBefore(end)) {
        fetchedJobs[currentDate.format('YYYY-MM-DD')] = [];
        currentDate.add(1, 'day');
      }

      querySnapshot.docs.forEach(doc => {
        const job = { id: doc.id, ...doc.data() };
        if (!job.appointmentDate) return;

        const jobMoment = moment(job.appointmentDate.toDate()).tz(timezone);
        
        if (jobMoment.isBetween(start, end, 'day', '[]')) {
          const dateKey = jobMoment.format('YYYY-MM-DD');
          if (!fetchedJobs[dateKey]) {
            fetchedJobs[dateKey] = [];
          }
          fetchedJobs[dateKey].push(job);
        }
      });

      Object.keys(fetchedJobs).forEach(dateKey => {
        fetchedJobs[dateKey].sort((a, b) => 
          moment(a.appointmentDate.toDate()).valueOf() - 
          moment(b.appointmentDate.toDate()).valueOf()
        );
      });

      setJobs(prevJobs => ({ ...prevJobs, ...fetchedJobs }));
    } catch (err) {
      console.error('Error fetching jobs:', err);
      setError('Failed to load jobs');
    } finally {
      setLoadingMore(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobsForDateRange(startDate, endDate);
  }, [user?.orgId, cleanerId]);

  const handleScroll = useCallback((e) => {
    if (loadingMore) return;

    const container = e.target;
    const scrollPosition = container.scrollLeft;
    const containerWidth = container.clientWidth;
    const scrollWidth = container.scrollWidth;

    if (scrollPosition > scrollWidth - containerWidth * 1.2) {
      const newEndDate = endDate.clone().add(WEEKS_TO_LOAD, 'weeks');
      setEndDate(newEndDate);
      fetchJobsForDateRange(endDate, newEndDate);
    }
  }, [loadingMore, endDate]);

  const navigateWeek = (direction) => {
    const newDate = selectedDate.clone().add(direction * 7, 'days');
    setSelectedDate(newDate);
  };

  const DateHeader = () => {
    const today = moment().tz(timezone);
    
    return (
      <div className={styles.dateHeader}>
        <div className={styles.monthRow}>
          <Typography className={styles.monthTitle}>
            {selectedDate.format('MMMM YYYY')}
          </Typography>
          <div className={styles.headerControls}>
            <button 
              onClick={() => setSelectedDate(today)} 
              className={styles.todayButton}
            >
              Today
            </button>
            <div className={styles.navigationButtons}>
              <button 
                onClick={() => navigateWeek(-1)} 
                className={styles.navButton}
              >
                <ChevronLeft size={20} />
              </button>
              <button 
                onClick={() => navigateWeek(1)} 
                className={styles.navButton}
              >
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
        </div>
        <div className={styles.weekDays}>
          {weekDates.map((date) => {
            const isToday = date.isSame(today, 'day');
            const isSelected = date.isSame(selectedDate, 'day');
            
            return (
              <div
                key={date.format('YYYY-MM-DD')}
                onClick={() => setSelectedDate(date)}
                className={styles.dayColumn}
              >
                <span className={styles.dayName}>
                  {date.format('ddd')}
                </span>
                <span 
                  className={`${styles.dayNumber} 
                    ${isToday ? styles.today : ''} 
                    ${isSelected ? styles.selected : ''}`}
                >
                  {date.format('D')}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const JobCard = ({ job }) => (
    <Card 
      className="mb-3 cursor-pointer hover:shadow-md transition-shadow"
      onClick={() => navigate(`/org/${user?.orgId}/job/${job.id}`)}
    >
      <CardContent className="p-4">
        <div className="flex justify-between items-start">
          <div className="flex-1 min-w-0">
            <Typography className="text-lg font-medium truncate">
              {job.customerName}
            </Typography>
            <div className="flex items-center mt-1 text-gray-600 text-sm">
              <Clock size={14} className="mr-1" />
              <span>{formatInOrgTz(job.appointmentDate, 'h:mm A')}</span>
            </div>
            <div className="flex items-start mt-2">
              <MapPin size={14} className="mr-1 mt-0.5 text-gray-500 flex-shrink-0" />
              <Typography className="text-sm text-gray-600 truncate">
                {job.address}
              </Typography>
            </div>
          </div>
          <div className="ml-2">
            <Chip 
              label={job.status}
              size="small"
              className={`${job.status?.toLowerCase() === 'completed' ? 
                'bg-green-100 text-green-800' : 
                'bg-blue-100 text-blue-800'} text-xs`}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );

  const EmptyJobCard = () => (
    <Card className="mb-3 bg-gray-50 border border-dashed border-gray-300">
      <CardContent className="p-4 flex items-center justify-center">
        <Typography className="text-gray-500 text-center">
          No jobs scheduled
        </Typography>
      </CardContent>
    </Card>
  );

  if (!isMobile) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50">
        <CircularProgress />
      </div>
    );
  }

  const selectedDateKey = selectedDate.format('YYYY-MM-DD');
  const selectedJobs = jobs[selectedDateKey] || [];

  return (
    <div className="min-h-screen bg-gray-50">
      <MobileHeader title="My Jobs" />
      <DateHeader />
      
      <div className="p-4">
        {selectedJobs.length > 0 ? (
          selectedJobs.map(job => (
            <JobCard key={job.id} job={job} />
          ))
        ) : (
          <EmptyJobCard />
        )}
      </div>

      {loadingMore && (
        <div className="p-4">
          <Skeleton variant="rectangular" height={100} className="mb-3" />
          <Skeleton variant="rectangular" height={100} />
        </div>
      )}
    </div>
  );
};

export default CleanerJobsOverview;