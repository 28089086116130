// src/components/CreateRecurringService.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Stack,
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Divider
} from '@mui/material';
import { collection, addDoc, serverTimestamp, writeBatch, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { Autocomplete } from '@react-google-maps/api';
import RecurrenceSelect from './RecurrenceSelect';
import { rrulestr } from 'rrule';
import moment from 'moment';
import AddRoomsModal from './AddRoomsModal';
import { GOOGLE_MAPS_CONFIG } from '../constants/googleMaps';
import { usePlaces } from '../hooks/usePlaces';
import styles from '../styles/CreateJobForm.module.css';

const libraries = ['places'];

const CreateRecurringService = ({ 
  customer, 
  orgId, 
  onServiceCreated, 
  onCancel,
  cleaners = [],
  availableRooms = []
}) => {
  // Customer information state
  const [customerName, setCustomerName] = useState(customer?.name || '');
  const [customerEmail, setCustomerEmail] = useState(customer?.email || '');
  const [customerPhone, setCustomerPhone] = useState(customer?.phone || '');
  const [address, setAddress] = useState(customer?.serviceAddress || '');
  
  // Form state
  const [serviceType, setServiceType] = useState('one-time');
  const [selectedCleaner, setSelectedCleaner] = useState('');
  const [price, setPrice] = useState(customer?.serviceCost?.toString() || '');
  const [recurrenceRule, setRecurrenceRule] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('09:00');
  const [endTime, setEndTime] = useState('10:00');
  const [notes, setNotes] = useState('');
  const [importantNotes, setImportantNotes] = useState('');
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [error, setError] = useState('');
  const [totalSize, setTotalSize] = useState(0);
  // Modal state
  const [openAddRoomsModal, setOpenAddRoomsModal] = useState(false);

  // Google Places setup
  const [searchBox, setSearchBox] = useState(null);
  const { handlePlaceSelect } = usePlaces();

  // This ref will hold our input element
  const [addressInputRef, setAddressInputRef] = useState(null);

  const onPlacesLoad = (searchBoxInstance) => {
    setSearchBox(searchBoxInstance);
  };

  const onPlaceChanged = () => {
    if (searchBox) {
      const place = searchBox.getPlace();
      if (place.formatted_address) {
        setAddress(place.formatted_address);
      }
    }
  };

  const handleAddressInputRef = (ref) => {
    setAddressInputRef(ref);
  };

  const handleAddRooms = (roomsToAdd) => {
    setSelectedRooms(prevRooms => {
      const updatedRooms = [...prevRooms];
      roomsToAdd.forEach(newRoom => {
        const formattedTasks = (newRoom.tasks || []).map(task => ({
          description: typeof task === 'string' ? task : task.description,
          completed: false
        }));

        const roomToAdd = {
          ...newRoom,
          tasks: formattedTasks,
          quantity: newRoom.quantity || 1
        };
        
        const existingIndex = updatedRooms.findIndex(room => room.name === newRoom.name);
        if (existingIndex !== -1) {
          updatedRooms[existingIndex] = {
            ...updatedRooms[existingIndex],
            ...roomToAdd,
            tasks: roomToAdd.tasks.length > 0 ? roomToAdd.tasks : updatedRooms[existingIndex].tasks,
            quantity: roomToAdd.quantity || updatedRooms[existingIndex].quantity || 1
          };
        } else {
          updatedRooms.push(roomToAdd);
        }
      });
      
      return updatedRooms;
    });
  };

  const calculateDuration = (startTimeStr, endTimeStr) => {
    const startDate = new Date(`2000-01-01T${startTimeStr}`);
    const endDate = new Date(`2000-01-01T${endTimeStr}`);
    return endDate.getTime() - startDate.getTime();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Validation
      if (!startDate) throw new Error("Please select a start date");
      if (!recurrenceRule) throw new Error("Please select a recurrence pattern");
      if (!price) throw new Error("Please enter a service price");
      if (!selectedCleaner) throw new Error("Please select a cleaner");
      if (endTime <= startTime) throw new Error("End time must be after start time");
      if (!customerName) throw new Error("Please enter customer name");
      if (!address) throw new Error("Please enter service address");

      const cleaner = cleaners.find(c => c.id === selectedCleaner);
      if (!cleaner) throw new Error("Selected cleaner not found");

      const appointmentDate = moment(`${startDate}T${startTime}`).toDate();
      const scheduledEndTime = moment(`${startDate}T${endTime}`).toDate();
      const duration = calculateDuration(startTime, endTime);

      const recurrenceGroupId = doc(collection(db, 'temp')).id;

      const baseJobData = {
        customerId: customer.id,
        customerName,
        customerEmail,
        customerPhone,
        address,
        totalSize,
        rooms: selectedRooms,
        notes,
        importantNotes,
        status: 'pending',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        organizationId: orgId,
        cleanerId: cleaner.id,
        cleanerName: cleaner.firstName && cleaner.lastName 
          ? `${cleaner.firstName} ${cleaner.lastName}`
          : cleaner.email,
        cleanerEmail: cleaner.email,
        serviceType,
        recurring: true,
        recurrenceRule,
        recurrenceGroupId,
        price: parseFloat(price)
      };

      const dtstartStr = appointmentDate.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
      const rruleString = `DTSTART:${dtstartStr}\nRRULE:${recurrenceRule}`;
      const rule = rrulestr(rruleString);
      const dates = rule.between(
        appointmentDate,
        moment(appointmentDate).add(6, 'months').toDate(),
        true
      );

      const batch = writeBatch(db);
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');

      dates.forEach((date) => {
        const jobRef = doc(jobsRef);
        const jobData = {
          ...baseJobData,
          appointmentDate: date,
          scheduledEndTime: new Date(date.getTime() + duration)
        };
        batch.set(jobRef, jobData);
      });

      await batch.commit();
      onServiceCreated();
    } catch (err) {
      console.error('Error creating recurring service:', err);
      setError(err.message);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError('')}>
          {error}
        </Alert>
      )}

      <Stack spacing={3}>
        {/* Customer Information Section */}
        <Typography variant="h6">Customer Information</Typography>
        <TextField
          label="Customer Name"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          fullWidth
          required
        />

        <TextField
          label="Email"
          type="email"
          value={customerEmail}
          onChange={(e) => setCustomerEmail(e.target.value)}
          fullWidth
        />

        <TextField
          label="Phone"
          value={customerPhone}
          onChange={(e) => setCustomerPhone(e.target.value)}
          fullWidth
        />

        <Autocomplete
          onLoad={onPlacesLoad}
          onPlaceChanged={onPlaceChanged}
        >
          <div className={styles.formSection}>
            <div className={styles.inputGroup}>
              <div className={styles.autocompleteContainer}>
                <label className={styles.label}>Service Address</label>
                <input
                  type="text"
                  placeholder="Enter Service Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className={styles.input}
                  required
                />
              </div>
            </div>
          </div>
        </Autocomplete>

        <Divider />

        {/* Service Details Section */}
        <Typography variant="h6">Service Details</Typography>
        
        <FormControl fullWidth>
          <InputLabel>Service Type</InputLabel>
          <Select
            value={serviceType}
            onChange={(e) => setServiceType(e.target.value)}
            label="Service Type"
            required
          >
            <MenuItem value="one-time">One Time Service</MenuItem>
            <MenuItem value="recurring">Recurring Service</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Assign Cleaner</InputLabel>
          <Select
            value={selectedCleaner}
            onChange={(e) => setSelectedCleaner(e.target.value)}
            label="Assign Cleaner"
            required
          >
            {cleaners.map(cleaner => (
              <MenuItem key={cleaner.id} value={cleaner.id}>
                {cleaner.firstName && cleaner.lastName
                  ? `${cleaner.firstName} ${cleaner.lastName}`
                  : cleaner.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 2 }}>
          <TextField
            type="date"
            label="Start Date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            required
          />
          
          <TextField
            type="time"
            label="Start Time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
            required
          />

          <TextField
            type="time"
            label="End Time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
            required
          />
        </Box>

        <TextField
          label="Service Price"
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required
          InputProps={{
            startAdornment: '$'
          }}
        />

        <RecurrenceSelect
          value={recurrenceRule}
          onChange={setRecurrenceRule}
        />

        <Divider />

        <TextField
          label="Total Size (SqFt)"
          type="number"
          value={totalSize}
          onChange={(e) => setTotalSize(Math.max(0, parseFloat(e.target.value) || 0))}
          InputProps={{
            inputProps: { min: 0, step: 1 }, // Ensure no negative values
          }}
          fullWidth
          margin="normal"
        />

        {/* Rooms Section */}
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Rooms</Typography>
            <Button 
              variant="outlined" 
              onClick={() => setOpenAddRoomsModal(true)}
            >
              Add Rooms
            </Button>
          </Box>
          
          {selectedRooms.length > 0 ? (
            <Stack spacing={1}>
              {selectedRooms.map((room, index) => (
                <Box key={index} sx={{ p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
                  <Typography variant="subtitle1">{room.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {room.tasks.length} tasks
                  </Typography>
                </Box>
              ))}
            </Stack>
          ) : (
            <Typography color="text.secondary">No rooms added yet</Typography>
          )}
        </Box>

        <TextField
          label="Notes"
          multiline
          rows={3}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />

        <TextField
          label="Important Notes"
          multiline
          rows={3}
          value={importantNotes}
          onChange={(e) => setImportantNotes(e.target.value)}
        />

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            Create Recurring Service
          </Button>
        </Box>
      </Stack>

      {/* Add Rooms Modal */}
      <AddRoomsModal
          open={openAddRoomsModal}
          handleClose={() => setOpenAddRoomsModal(false)}
          availableRooms={availableRooms}
          handleAddRooms={handleAddRooms}
          existingRooms={selectedRooms}
          orgId={orgId}
        />
    </Box>
  );
};

export default CreateRecurringService;