// src/context/JobContext.js

import React, { createContext, useContext, useState } from 'react';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  updateDoc, 
  doc, 
  serverTimestamp,
  deleteDoc,
  orderBy,
  writeBatch,
  getDoc,
} from 'firebase/firestore';
import { db } from '../firebase';
import { rrulestr } from 'rrule';

const JobContext = createContext();

export const useJobContext = () => useContext(JobContext);

// Job status constants to ensure consistency across the app
export const JOB_STATUSES = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled'
};

// Helper function to normalize status
export const normalizeJobStatus = (status) => {
  if (!status) return JOB_STATUSES.PENDING;

  // Convert to lowercase for comparison
  const statusLower = status.toLowerCase();

  switch (statusLower) {
    case 'pending':
    case 'Pending':
      return JOB_STATUSES.PENDING;
    
    case 'in progress':
    case 'in-progress':
    case 'inprogress':
    case 'progress':
      return JOB_STATUSES.IN_PROGRESS;
    
    case 'completed':
    case 'complete':
    case 'done':
      return JOB_STATUSES.COMPLETED;
    
    case 'cancelled':
    case 'canceled':
      return JOB_STATUSES.CANCELLED;
    
    default:
      return JOB_STATUSES.PENDING;
  }
};

const calculateJobEfficiency = (scheduledMs, actualMs) => {
  return Math.round((scheduledMs / actualMs) * 10000) / 100;
};

export const JobProvider = ({ children }) => {
  const [jobs, setJobs] = useState([]);

  const calculateDuration = (startTime, endTime) => {
    if (!startTime || !endTime) return null;
    
    const start = startTime.toDate ? startTime.toDate() : new Date(startTime);
    const end = endTime.toDate ? endTime.toDate() : new Date(endTime);
    
    return end.getTime() - start.getTime();
  };

  const fetchJobs = async (orgId, date) => {
    const jobsRef = collection(db, 'organizations', orgId, 'jobs');
    const q = query(
      jobsRef,
      where('appointmentDate', '>=', new Date(date)),
      where('appointmentDate', '<', new Date(new Date(date).setDate(new Date(date).getDate() + 1)))
    );

    const querySnapshot = await getDocs(q);
    const fetchedJobs = querySnapshot.docs.map(doc => {
      const data = doc.data();
      if (data.status === JOB_STATUSES.COMPLETED && data.startTime && data.endTime) {
        const durationMs = calculateDuration(data.startTime, data.endTime);
        if (durationMs !== null) {
          data.duration = durationMs;
        }
      }
      return {
        id: doc.id,
        ...data
      };
    });

    setJobs(fetchedJobs);
  };

  const startJob = async (orgId, jobId) => {
    try {
      const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
      const startTime = serverTimestamp();
      
      const updateData = {
        startTime,
        status: JOB_STATUSES.IN_PROGRESS,
        updatedAt: serverTimestamp()
      };

      await updateDoc(jobRef, updateData);
      
      // Update local state
      setJobs(prevJobs => prevJobs.map(job => 
        job.id === jobId 
          ? { 
              ...job, 
              startTime: new Date(), 
              status: JOB_STATUSES.IN_PROGRESS,
              updatedAt: new Date()
            } 
          : job
      ));

      return true;
    } catch (error) {
      console.error('Error starting job:', error);
      throw error;
    }
  };

  const completeJob = async (orgId, jobId, notes) => {
    try {
      const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
      
      const currentJob = jobs.find(job => job.id === jobId);
      if (!currentJob || !currentJob.startTime) {
        throw new Error('Job not found or start time not set');
      }

      const endTime = serverTimestamp();
      
      // Calculate actual duration
      const startTimeMs = currentJob.startTime.toDate().getTime();
      const endTimeMs = new Date().getTime();
      const actualDuration = endTimeMs - startTimeMs;

      // Calculate scheduled duration
      const scheduledStartMs = currentJob.appointmentDate.toDate().getTime();
      const scheduledEndMs = currentJob.scheduledEndTime.toDate().getTime();
      const scheduledDuration = scheduledEndMs - scheduledStartMs;

      // Calculate efficiency
      const efficiencyRating = calculateJobEfficiency(scheduledDuration, actualDuration);

      const updateData = {
        endTime,
        status: JOB_STATUSES.COMPLETED,
        notes,
        actualDuration,
        scheduledDuration,
        efficiencyRating,
        updatedAt: serverTimestamp()
      };

      // Update the job
      await updateDoc(jobRef, updateData);

      // Update cleaner's efficiency metrics
      if (currentJob.cleanerId) {
        const cleanerRef = doc(db, 'organizations', orgId, 'cleaners', currentJob.cleanerId);
        const cleanerSnapshot = await getDoc(cleanerRef);
        
        if (cleanerSnapshot.exists()) {
          const cleanerData = cleanerSnapshot.data();
          const completedJobs = (cleanerData.completedJobs || 0) + 1;
          const totalEfficiency = (cleanerData.totalEfficiency || 0) + efficiencyRating;
          const averageEfficiency = totalEfficiency / completedJobs;

          await updateDoc(cleanerRef, {
            completedJobs,
            totalEfficiency,
            averageEfficiency,
            updatedAt: serverTimestamp()
          });
        }
      }

      // Update local state
      setJobs(prevJobs => prevJobs.map(job => 
        job.id === jobId ? {
          ...job,
          endTime: new Date(),
          status: JOB_STATUSES.COMPLETED,
          notes,
          actualDuration,
          scheduledDuration,
          efficiencyRating
        } : job
      ));

      return true;
    } catch (error) {
      console.error('Error completing job:', error);
      throw error;
    }
  };

  const updateHistoricalDurations = async (orgId) => {
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const q = query(
        jobsRef,
        where('status', '==', JOB_STATUSES.COMPLETED)
      );

      const querySnapshot = await getDocs(q);
      const batch = writeBatch(db);
      let updateCount = 0;

      querySnapshot.docs.forEach(docSnapshot => {
        const data = docSnapshot.data();
        if (data.startTime && data.endTime) {
          const durationMs = calculateDuration(data.startTime, data.endTime);
          if (durationMs !== null) {
            const jobRef = doc(db, 'organizations', orgId, 'jobs', docSnapshot.id);
            batch.update(jobRef, { 
              duration: durationMs,
              updatedAt: serverTimestamp()
            });
            updateCount++;
          }
        }
      });

      if (updateCount > 0) {
        await batch.commit();
        await fetchJobs(orgId, new Date());
      }

      return updateCount;
    } catch (error) {
      console.error('Error updating historical durations:', error);
      throw error;
    }
  };

  const getCleanerMetrics = async (orgId, cleanerId) => {
    try {
      console.log('Starting metrics calculation with status:', JOB_STATUSES.COMPLETED);
      
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      
      // Get all jobs first to check their statuses
      const allJobsQuery = query(
        jobsRef,
        where('cleanerId', '==', cleanerId)
      );
  
      const allJobsSnapshot = await getDocs(allJobsQuery);
      
      // Log all jobs and their statuses
      console.log('All jobs found:', allJobsSnapshot.docs.length);
      allJobsSnapshot.docs.forEach(doc => {
        const data = doc.data();
        console.log('Job:', {
          id: doc.id,
          status: data.status,
          startTime: data.startTime,
          endTime: data.endTime,
          appointmentDate: data.appointmentDate,
          scheduledEndTime: data.scheduledEndTime
        });
      });
  
      // Filter completed jobs in memory to handle case sensitivity
      const completedJobs = allJobsSnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter(job => normalizeJobStatus(job.status) === JOB_STATUSES.COMPLETED);
  
      console.log('Completed jobs found:', completedJobs.length);
  
      // Get unique clients from all jobs
      const uniqueClients = new Set(
        allJobsSnapshot.docs.map(doc => doc.data().customerName)
      );
  
      console.log('Unique clients found:', uniqueClients.size);
  
      // Calculate efficiency for jobs with valid timestamps
      const jobsWithEfficiency = completedJobs.filter(job => {
        const hasRequiredFields = job.startTime && 
                                job.endTime && 
                                job.appointmentDate && 
                                job.scheduledEndTime;
        
        if (!hasRequiredFields) {
          console.log('Job missing required fields for efficiency:', job.id);
        }
        return hasRequiredFields;
      });
  
      console.log('Jobs with valid timestamps for efficiency:', jobsWithEfficiency.length);
  
      let totalEfficiency = 0;
      let validEfficiencyCount = 0;
  
      jobsWithEfficiency.forEach(job => {
        try {
          const scheduledDuration = job.scheduledEndTime.toDate().getTime() - 
                                  job.appointmentDate.toDate().getTime();
          const actualDuration = job.endTime.toDate().getTime() - 
                               job.startTime.toDate().getTime();
          
          if (scheduledDuration > 0 && actualDuration > 0) {
            const efficiency = (scheduledDuration / actualDuration) * 100;
            if (efficiency > 0) {
              totalEfficiency += efficiency;
              validEfficiencyCount++;
              console.log('Calculated efficiency for job:', {
                jobId: job.id,
                efficiency,
                scheduledDuration: scheduledDuration / (1000 * 60 * 60), // hours
                actualDuration: actualDuration / (1000 * 60 * 60) // hours
              });
            }
          }
        } catch (err) {
          console.warn('Error calculating efficiency for job:', job.id, err);
        }
      });
  
      // Calculate on-time arrivals
      let onTimeCount = 0;
      completedJobs.forEach(job => {
        try {
          if (job.startTime && job.appointmentDate) {
            const startTime = job.startTime.toDate().getTime();
            const scheduledTime = job.appointmentDate.toDate().getTime();
            // Consider 15 minutes grace period
            if (startTime <= scheduledTime + (15 * 60 * 1000)) {
              onTimeCount++;
              console.log('Job counted as on-time:', job.id);
            }
          }
        } catch (err) {
          console.warn('Error calculating on-time status for job:', job.id, err);
        }
      });
  
      // Calculate average job duration
      let totalDuration = 0;
      let validDurationCount = 0;
  
      completedJobs.forEach(job => {
        try {
          if (job.startTime && job.endTime) {
            const duration = (job.endTime.toDate().getTime() - 
                            job.startTime.toDate().getTime()) / (1000 * 60 * 60); // Convert to hours
            if (duration > 0) {
              totalDuration += duration;
              validDurationCount++;
              console.log('Calculated duration for job:', {
                jobId: job.id,
                duration,
                startTime: job.startTime.toDate(),
                endTime: job.endTime.toDate()
              });
            }
          }
        } catch (err) {
          console.warn('Error calculating duration for job:', job.id, err);
        }
      });
  
      const metrics = {
        totalCompletedJobs: completedJobs.length,
        totalClients: uniqueClients.size,
        avgEfficiency: validEfficiencyCount > 0 
          ? Number((totalEfficiency / validEfficiencyCount).toFixed(1)) 
          : 0,
        onTimeRate: completedJobs.length > 0 
          ? Number(((onTimeCount / completedJobs.length) * 100).toFixed(1)) 
          : 0,
        avgJobDuration: validDurationCount > 0 
          ? Number((totalDuration / validDurationCount).toFixed(1)) 
          : 0
      };
  
      console.log('Final calculated metrics:', {
        ...metrics,
        completedJobs: completedJobs.length,
        jobsWithEfficiency: jobsWithEfficiency.length,
        validEfficiencyCount,
        onTimeCount,
        validDurationCount
      });
  
      return metrics;
  
    } catch (error) {
      console.error('Error calculating cleaner metrics:', error);
      throw error;
    }
  };

  const handleDeleteJob = async (orgId, jobId) => {
    try {
      const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
      await deleteDoc(jobRef);
      return true;
    } catch (error) {
      console.error('Error deleting job:', error);
      throw error;
    }
  };

  const handleDeleteRecurringJob = async (orgId, recurrenceGroupId) => {
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const q = query(jobsRef, where('recurrenceGroupId', '==', recurrenceGroupId));
      const querySnapshot = await getDocs(q);
      
      const batch = writeBatch(db);
      querySnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      
      await batch.commit();
      return true;
    } catch (error) {
      console.error('Error deleting recurring jobs:', error);
      throw error;
    }
  };

  const fetchRecurringInstances = async (orgId, recurrenceGroupId) => {
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const q = query(
        jobsRef, 
        where('recurrenceGroupId', '==', recurrenceGroupId),
        orderBy('appointmentDate', 'asc')
      );
      const querySnapshot = await getDocs(q);
      
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error fetching recurring instances:', error);
      throw error;
    }
  };

  const generateMoreInstances = async (orgId, job) => {
    try {
      if (!job.recurring || !job.recurrenceRule) {
        throw new Error('Job is not recurring or missing recurrence rule');
      }

      // Parse the appointment date
      const startDate = job.appointmentDate.toDate();
      const duration = job.scheduledDuration;
      
      // Create RRule instance
      const rruleString = `DTSTART:${startDate.toISOString().replace(/[-:]/g, '').split('.')[0]}Z\nRRULE:${job.recurrenceRule}`;
      const rule = rrulestr(rruleString);

      // Generate dates for the next 6 months
      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 6);
      
      const dates = rule.between(startDate, endDate);
      const batch = writeBatch(db);
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');

      dates.forEach((date) => {
        if (date.getTime() === startDate.getTime()) return;

        const newJobRef = doc(jobsRef);
        const jobData = {
          ...job,
          id: newJobRef.id,
          appointmentDate: date,
          scheduledEndTime: new Date(date.getTime() + duration),
          status: 'pending',
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        };

        delete jobData.startTime;
        delete jobData.endTime;
        delete jobData.duration;
        delete jobData.actualDuration;
        delete jobData.efficiencyRating;

        batch.set(newJobRef, jobData);
      });

      await batch.commit();
      return dates.length - 1;
    } catch (error) {
      console.error('Error generating instances:', error);
      throw error;
    }
  };

  return (
    <JobContext.Provider value={{ 
      jobs, 
      fetchJobs, 
      startJob, 
      completeJob,
      updateHistoricalDurations,
      getCleanerMetrics,
      handleDeleteJob,           // Add these new functions
      handleDeleteRecurringJob,  // to the provider value
      fetchRecurringInstances,
      generateMoreInstances,
      JOB_STATUSES
    }}>
      {children}
    </JobContext.Provider>
  );
};

export default JobContext;