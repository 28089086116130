import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBSFN6Xd1iKv5XdEoR1nHUTgglpgC1k9xw",
  authDomain: "gem-city-cleaning-tools.firebaseapp.com",
  projectId: "gem-city-cleaning-tools",
  storageBucket: "gem-city-cleaning-tools.appspot.com",
  messagingSenderId: "76185964211",
  appId: "1:76185964211:web:a2d3ef3e101d476c102ac6",
  measurementId: "G-PYNG6MT50T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and Analytics
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);

// Export the instances
export { auth, analytics, db, functions };