import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useJobContext } from '../context/JobContext';
import { useAuth } from '../context/AuthContext';
import { Card, CardContent, Typography, CircularProgress, useMediaQuery } from '@mui/material';
import { Users, TrendingUp, Clock, CheckCircle } from 'lucide-react';
import MobileHeader from './MobileHeader';
import styles from '../styles/CleanerDashboard.module.css';

const CleanerDashboard = () => {
  const { cleanerId } = useParams();
  const { user } = useAuth();
  const { getCleanerMetrics } = useJobContext();
  const isMobile = useMediaQuery('(max-width:768px)');
  const [metrics, setMetrics] = useState({
    totalCompletedJobs: 0,
    totalClients: 0,
    avgEfficiency: 0,
    onTimeRate: 0,
    avgJobDuration: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      if (!user?.orgId || !cleanerId) {
        setLoading(false);
        setError('Missing organization or cleaner ID');
        return;
      }

      try {
        const cleanerMetrics = await getCleanerMetrics(user.orgId, cleanerId);
        setMetrics(cleanerMetrics || {
          totalCompletedJobs: 0,
          totalClients: 0,
          avgEfficiency: 0,
          onTimeRate: 0,
          avgJobDuration: 0
        });
      } catch (error) {
        setError('Failed to load metrics');
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, [user?.orgId, cleanerId, getCleanerMetrics]);

  if (!isMobile) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.dashboard}>
        <Typography color="error">{error}</Typography>
      </div>
    );
  }

  return (
    <div className={styles.dashboard}>
      <MobileHeader title="Dashboard" showBack={false} />

      <Card className={styles.statsCard}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Your Stats
          </Typography>

          <div className={styles.metricRow}>
            <div className={styles.metricItem}>
              <div className={styles.metricIcon}>
                <Users />
              </div>
              <div>
                <Typography className={styles.metricTitle}>Total Clients</Typography>
                <Typography className={styles.metricValue}>{metrics.totalClients}</Typography>
              </div>
            </div>

            <div className={styles.metricItem}>
              <div className={styles.metricIcon}>
                <CheckCircle />
              </div>
              <div>
                <Typography className={styles.metricTitle}>Jobs Completed</Typography>
                <Typography className={styles.metricValue}>{metrics.totalCompletedJobs}</Typography>
              </div>
            </div>
          </div>

          <div className={styles.metricRow}>
            <div className={styles.metricItem}>
              <div className={styles.metricIcon}>
                <TrendingUp />
              </div>
              <div>
                <Typography className={styles.metricTitle}>Efficiency</Typography>
                <Typography className={styles.metricValue}>{metrics.avgEfficiency}%</Typography>
              </div>
            </div>

            <div className={styles.metricItem}>
              <div className={styles.metricIcon}>
                <Clock />
              </div>
              <div>
                <Typography className={styles.metricTitle}>On-Time Rate</Typography>
                <Typography className={styles.metricValue}>{metrics.onTimeRate}%</Typography>
              </div>
            </div>
          </div>

          <div className={styles.metricRow}>
            <div className={styles.metricItem}>
              <div className={styles.metricIcon}>
                <Clock />
              </div>
              <div>
                <Typography className={styles.metricTitle}>Avg Job Duration</Typography>
                <Typography className={styles.metricValue}>{metrics.avgJobDuration} hrs</Typography>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CleanerDashboard;
