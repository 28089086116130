// src/constants/googleMaps.js
// Google Maps Constants for autocomplete in CreateCustomerModal, CreateJobForm, and CustomerManagement

export const GOOGLE_MAPS_CONFIG = {
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['places'],
  };
  
  // Export individual constants for direct use
  export const GOOGLE_API_KEY = GOOGLE_MAPS_CONFIG.apiKey;
  export const GOOGLE_MAPS_LIBRARIES = GOOGLE_MAPS_CONFIG.libraries;