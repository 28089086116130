// /src/components/JobDialog.js

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { normalizeJobStatus, JOB_STATUSES } from '../context/JobContext'; 
import JobDetails from './JobDetails';
import JobEditForm from './JobEditForm';
import RecurringInstancesList from './RecurringInstancesList';
import styles from '../styles/JobsOverview.module.css';

const JobDialog = ({
  open,
  onClose,
  job,
  isEditing,
  handleEditClick,
  handleDeleteJob,
  handleMarkCompleted,
  handleDeleteRecurringJob,
  fetchRecurringInstances,
  showingRecurringSeries,
  recurringInstances,
  generateMoreInstances,
  handleSaveEdit,
  handleCancelEdit,
  editedJob,
  handleEditInputChange,
  handleTotalSizeChange,
  handleAddRooms,
  handleDeleteRoom,
  openAddRoomsModal,
  setOpenAddRoomsModal,
  availableRooms,
  formatRecurrenceRule,
  getStatusDetails,
  handleApplyToRecurringChange,
  applyToRecurring
}) => {

  const renderDialogActions = () => (
    <DialogActions className={styles.dialogActions}>
      {isEditing ? (
        <>
          <Button
            onClick={handleSaveEdit}
            variant="contained"
            color="primary"
            className={styles.actionButton}
          >
            Save Changes
          </Button>
          <Button
            onClick={handleCancelEdit}
            variant="outlined"
            color="secondary"
            className={styles.actionButton}
          >
            Cancel
          </Button>
        </>
      ) : (
        <>
          <Button
            onClick={handleEditClick}
            variant="contained"
            color="primary"
            className={styles.actionButton}
          >
            Edit
          </Button>
          <Button
            onClick={handleDeleteJob}
            variant="outlined"
            color="error"
            className={styles.actionButton}
          >
            Delete
          </Button>
          {normalizeJobStatus(job?.status) === JOB_STATUSES.PENDING && (
            <Button
              onClick={handleMarkCompleted}
              variant="contained"
              color="success"
              className={styles.actionButton}
            >
              Mark Complete
            </Button>
          )}
          {job?.recurring && normalizeJobStatus(job?.status) === JOB_STATUSES.PENDING && (
            <Button
              onClick={() => handleDeleteRecurringJob(job.recurrenceGroupId)}
              variant="outlined"
              color="error"
              className={styles.actionButton}
            >
              Delete All Recurring
            </Button>
          )}
          {job?.recurring && (
            <Button
              onClick={() => fetchRecurringInstances(job.recurrenceGroupId)}
              variant="contained"
              color="secondary"
              className={styles.actionButton}
            >
              View Series
            </Button>
          )}
        </>
      )}
      <Button 
        onClick={onClose} 
        variant="outlined"
        className={styles.closeButton}
      >
        Close
      </Button>
    </DialogActions>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth className={styles.dialog}>
      {job && (
        <>
          <DialogTitle className={styles.dialogTitle}>
            {isEditing ? 'Edit Job' : job.customerName}
          </DialogTitle>
          <DialogContent className={styles.dialogContent}>
            {isEditing ? (
              <JobEditForm
                job={editedJob}
                handleEditInputChange={handleEditInputChange}
                handleTotalSizeChange={handleTotalSizeChange}
                handleAddRooms={handleAddRooms}
                handleDeleteRoom={handleDeleteRoom}
                openAddRoomsModal={openAddRoomsModal}
                setOpenAddRoomsModal={setOpenAddRoomsModal}
                availableRooms={availableRooms}
                handleApplyToRecurringChange={handleApplyToRecurringChange}
                applyToRecurring={applyToRecurring}
              />
            ) : (
              <>
                <JobDetails 
                  job={job} 
                  formatRecurrenceRule={formatRecurrenceRule} 
                  getStatusDetails={getStatusDetails} 
                />
                <Typography className={styles.jobLink}>
                  <strong>Job Link:</strong>{' '}
                  {`${window.location.origin}/org/${job.organizationId}/job/${job.id}`}
                </Typography>
                {showingRecurringSeries && (
                  <>
                    <RecurringInstancesList instances={recurringInstances} />
                    <Button
                      onClick={() => generateMoreInstances(job)}
                      variant="outlined"
                      sx={{ mt: 2 }}
                    >
                      Generate More Instances
                    </Button>
                  </>
                )}
              </>
            )}
          </DialogContent>
          {renderDialogActions()}
        </>
      )}
    </Dialog>
  );
};

export default JobDialog;
