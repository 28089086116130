// /src/components/RecurringInstancesList.js

import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Chip } from '@mui/material';
import styles from '../styles/JobsOverview.module.css';

const RecurringInstancesList = ({ instances }) => (
  <Box sx={{ mt: 2 }}>
    <Typography variant="h6">Recurring Instances</Typography>
    <List>
      {instances.map((instance) => (
        <ListItem 
          key={instance.id}
          secondaryAction={
            <Chip 
              label={instance.status}
              color={instance.status === 'pending' ? 'warning' : 'success'}
              size="small"
            />
          }
        >
          <ListItemText
            primary={new Date(instance.appointmentDate.toDate()).toLocaleString()}
            secondary={`Cleaner: ${instance.cleanerName}`}
          />
        </ListItem>
      ))}
    </List>
  </Box>
);

export default RecurringInstancesList;
