// Function to parse number words into numeric values
function parseNumberWords(text) {
  const numberWords = {
    'zero': 0, 'one': 1, 'two': 2, 'three': 3, 'four': 4,
    'five':5, 'six':6, 'seven':7, 'eight':8, 'nine':9,
    'ten':10, 'eleven':11, 'twelve':12, 'thirteen':13,
    'fourteen':14, 'fifteen':15, 'sixteen':16, 'seventeen':17,
    'eighteen':18, 'nineteen':19, 'twenty':20
  };
  
  text = text.toLowerCase();
  let tokens = text.split(/[\s-]+/);
  let total = 0;
  let i = 0;
  
  while (i < tokens.length) {
    let word = tokens[i];
    if (numberWords[word] !== undefined) {
      total += numberWords[word];
      i++;
    } else if (word === 'half') {
      total += 0.5;
      i++;
    } else if (word === 'and' || word === 'a') {
      i++; // Skip 'and' and 'a'
    } else {
      break; // Stop if word is not a number word
    }
  }
  
  return total || null;
}

// Function to extract room information from a line
export function extractRoomInfo(line) {
  // Remove leading hyphens or bullet points
  line = line.replace(/^[-•*]\s*/, '');

  const petMatch = line.match(/(\d+)\s*(dogs?|cats?|pets?)/i);
  if (petMatch) {
    return {
      roomType: 'Pet Fee (Per Pet)',
      quantity: parseInt(petMatch[1]),
      details: line
    };
  }

  // Check for "sqft" or "square feet" pattern
  const sqftMatch = line.match(/(\d+(?:\.\d+)?)\s*(sqft|square\s*feet)/i);
  if (sqftMatch) {
    return {
      roomType: 'Square Foot',
      quantity: parseFloat(sqftMatch[1]),
      details: line
    };
  }

  // Try to extract room type and quantity
  const parts = line.split(':');
  let roomType, quantity, details;

  if (parts.length > 1) {
    // Format: "Room Type: quantity details"
    roomType = parts[0].trim();
    const quantityMatch = parts[1].match(/(\d+(?:\.\d+)?)/);
    if (quantityMatch) {
      quantity = parseFloat(quantityMatch[1]);
    } else {
      // Try to parse number words
      quantity = parseNumberWords(parts[1]);
      if (quantity === null) quantity = 1;
    }
    details = parts[1].trim();
  } else {
    // Try to extract quantity and room type without colon
    const numberMatch = line.match(/^(\d+(?:\.\d+)?)(.*)/);
    if (numberMatch) {
      quantity = parseFloat(numberMatch[1]);
      roomType = numberMatch[2].trim();
    } else {
      // Try to extract number words
      const quantityFromWords = parseNumberWords(line);
      if (quantityFromWords !== null) {
        quantity = quantityFromWords;
        // Remove number words from line to get roomType
        const numberWordsPattern = /^(zero|one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty|and|half|\s|-)+/i;
        roomType = line.replace(numberWordsPattern, '').trim();
      } else {
        // If no quantity found, assume it's just a room type
        roomType = line;
        quantity = 1;
      }
    }
    details = line;
  }

  return { roomType, quantity, details };
}

// Function to normalize room types
export function normalizeRoomType(roomType) {
  // Extract number words first
  const numberWords = {
    'one': 1, 'two': 2, 'three': 3, 'four': 4, 'five': 5,
    'six': 6, 'seven': 7, 'eight': 8, 'nine': 9
  };
  
  let quantity = 1;
  const lowerRoom = roomType.toLowerCase();
  
  for (const [word, num] of Object.entries(numberWords)) {
    if (lowerRoom.startsWith(word)) {
      quantity = num;
      roomType = roomType.substring(word.length).trim();
      break;
    }
  }

  const normalizedRoom = roomType.trim().toLowerCase();

  
  
  // Return both type and quantity
  const getTypeAndQuantity = (type) => ({ type, quantity });

  switch (true) {
    case /pantry/.test(normalizedRoom):
      return getTypeAndQuantity('Pantry');
    case /bed|beds|bedroom|guest room|bedrooms/.test(normalizedRoom):
      return getTypeAndQuantity('Bedrooms');
    case /office/.test(normalizedRoom):
      return getTypeAndQuantity('Office');
    case /game room|gameroom|playroom|play room/.test(normalizedRoom):
      return getTypeAndQuantity('Playroom');
    case /full bath|full bathroom/.test(normalizedRoom):
      return getTypeAndQuantity('Full Bath');
    case /half bath|half bathroom/.test(normalizedRoom):
      return getTypeAndQuantity('Half Bath');
    case /master bath|master bathroom/.test(normalizedRoom):
      return getTypeAndQuantity('Master Bath');
    case /bath|baths|bathroom/.test(normalizedRoom):
      return getTypeAndQuantity('Bathroom');
    case /hallway|hall|foyer|foyers|hallways|halls/.test(normalizedRoom):
      return getTypeAndQuantity('Foyers/Halls');
    case /living room|family room|living room/.test(normalizedRoom):
      return getTypeAndQuantity('Living Room');
    case /dining room/.test(normalizedRoom):
      return getTypeAndQuantity('Dining Room');
    case /kitchen/.test(normalizedRoom):
      return getTypeAndQuantity('Kitchen');
    case /basement/.test(normalizedRoom):
      return getTypeAndQuantity('Basement');
    case /stairs|staircase/.test(normalizedRoom):
      return getTypeAndQuantity('Stairs');
    case /sqft|square feet|sq ft/.test(normalizedRoom):
      return getTypeAndQuantity('Square Foot');
    case /closet|utility room/.test(normalizedRoom):
      return getTypeAndQuantity('Closet/Utility Room');
    case /entry way|entryway/.test(normalizedRoom):
      return getTypeAndQuantity('Entry Way');
    case /florida room/.test(normalizedRoom):
      return getTypeAndQuantity('Florida Room');
    case /in home salon/.test(normalizedRoom):
      return getTypeAndQuantity('In Home Salon');
    case /laundry room/.test(normalizedRoom):
      return getTypeAndQuantity('Laundry Room');
    case /loft/.test(normalizedRoom):
      return getTypeAndQuantity('Loft');
    case /mini bars|mini kitchens|mini bar|mini kitchen/.test(normalizedRoom):
      return getTypeAndQuantity('Mini Bars/Kitchens');
    case /monthly fee/.test(normalizedRoom):
      return getTypeAndQuantity('Monthly Fee');
    case /morning room/.test(normalizedRoom):
      return getTypeAndQuantity('Morning Room');
    case /mud room/.test(normalizedRoom):
      return getTypeAndQuantity('Mud Room');
    case /pet fee|pet|dog|dogs|cat|cats|animal|animals/.test(normalizedRoom):
      return getTypeAndQuantity('Pet Fee (Per Pet)');
    case /sitting room/.test(normalizedRoom):
      return getTypeAndQuantity('Sitting Room');
    case /workout room/.test(normalizedRoom):
      return getTypeAndQuantity('Workout Room');
    default:
      return getTypeAndQuantity(roomType);
  }
}

// Function to process bathrooms
export function processBathrooms(bathrooms) {
  let masterBath = 1;
  let fullBath = 0;
  let halfBath = 0;

  if (typeof bathrooms === 'string') {
    const text = bathrooms.toLowerCase();
    let total = parseFloat(bathrooms);

    if (isNaN(total)) {
      // Handle number words
      total = parseNumberWords(text);
      if (total === null) total = 1; // Default to 1 if parsing fails
    }

    let remainingBathrooms = total - 1;
    fullBath = Math.floor(remainingBathrooms);
    halfBath = (remainingBathrooms % 1 >= 0.5) ? 1 : 0;

  } else if (typeof bathrooms === 'number') {
    let remainingBathrooms = bathrooms - 1;
    fullBath = Math.floor(remainingBathrooms);
    halfBath = (remainingBathrooms % 1 >= 0.5) ? 1 : 0;

  } else if (Array.isArray(bathrooms)) {
    bathrooms.forEach(bath => {
      if (bath.name.toLowerCase().includes('master')) {
        masterBath += bath.quantity;
      } else if (bath.name.toLowerCase().includes('full')) {
        fullBath += bath.quantity;
      } else if (bath.name.toLowerCase().includes('half')) {
        halfBath += bath.quantity;
      }
    });

    if (masterBath === 0 && (fullBath > 0 || halfBath > 0)) {
      if (fullBath > 0) {
        masterBath = 1;
        fullBath -= 1;
      } else {
        masterBath = 1;
        halfBath -= 1;
      }
    }
  }

  return [
    { name: 'Master Bath', quantity: masterBath },
    { name: 'Full Bath', quantity: fullBath },
    { name: 'Half Bath', quantity: halfBath },
  ];
}

// Function to parse the rooms list
export function parseRoomsList(roomInput) {
  const lines = roomInput.split('\n');
  const parsedRooms = [];
  let currentCategory = '';
  let bathrooms = '';

  lines.forEach(line => {
    line = line.trim();
    if (line) {
      if (line.endsWith(':')) {
        currentCategory = line.slice(0, -1);
      } else {
        const { roomType, quantity, details } = extractRoomInfo(line);

        if (/bathroom|bath/.test(roomType.toLowerCase())) {
          bathrooms = quantity;
        } else {
          const { type, quantity: normalizedQuantity } = normalizeRoomType(roomType);
          parsedRooms.push({
            category: currentCategory,
            name: type,
            quantity: quantity || normalizedQuantity,
            details: details
          });
        }
      }
    }
  });

  // Process bathrooms if bathrooms variable is set
  if (bathrooms !== '') {
    const processedBathrooms = processBathrooms(bathrooms);
    parsedRooms.push(...processedBathrooms);
  }

  return parsedRooms;
}
