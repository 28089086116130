// src/components/WeeklyJobsView.js

import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Stack,
  Card,
  CardContent,
  Chip,
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  Today as TodayIcon
} from '@mui/icons-material';
import moment from 'moment';

const WeeklyJobsView = ({ jobs, onJobClick, statusColors, getStatusDetails }) => {
  const [currentDate, setCurrentDate] = useState(moment());

  const nextWeek = () => setCurrentDate(moment(currentDate).add(1, 'week'));
  const prevWeek = () => setCurrentDate(moment(currentDate).subtract(1, 'week'));
  const today = () => setCurrentDate(moment());

  const weekDays = [];
  const startOfWeek = moment(currentDate).startOf('week');
  for (let i = 0; i < 7; i++) {
    weekDays.push(moment(startOfWeek).add(i, 'days'));
  }

  const formatTimeRange = (job) => {
    const start = moment(job.appointmentDate.toDate()).format('h:mm A');
    const end = job.scheduledEndTime 
      ? moment(job.scheduledEndTime.toDate()).format('h:mm A')
      : null;
    return end ? `${start} - ${end}` : start;
  };

  const getJobsForDay = (date) => {
    return jobs.filter(job => 
      moment(job.appointmentDate.toDate()).format('YYYY-MM-DD') === date.format('YYYY-MM-DD')
    ).sort((a, b) => 
      moment(a.appointmentDate.toDate()).diff(moment(b.appointmentDate.toDate()))
    );
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Paper sx={{ p: 2, mb: 2 }}>
        {/* Header with navigation */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h5">
            Week of {currentDate.format('MMM D, YYYY')}
          </Typography>
          <Box>
            <IconButton onClick={prevWeek}>
              <ChevronLeft />
            </IconButton>
            <IconButton onClick={today}>
              <TodayIcon />
            </IconButton>
            <IconButton onClick={nextWeek}>
              <ChevronRight />
            </IconButton>
          </Box>
        </Box>

        {/* Scrollable container for the week view */}
        <Box sx={{ overflow: 'auto' }}>
          <Stack 
            direction="row" 
            spacing={2} 
            sx={{ 
              minWidth: { xs: '800px', md: '100%' }, // Minimum width to prevent squishing
              '& > *': { 
                width: `${100/7}%`,
                minWidth: '200px', // Minimum width for each day column
              }
            }}
          >
            {weekDays.map((day) => (
              <Paper 
                elevation={2} 
                key={day.format('YYYY-MM-DD')}
                sx={{
                  height: '100%',
                  bgcolor: moment().isSame(day, 'day') ? 'action.hover' : 'background.paper'
                }}
              >
                {/* Day header */}
                <Box sx={{ p: 1, bgcolor: 'primary.main', color: 'primary.contrastText' }}>
                  <Typography variant="subtitle2" align="center">
                    {day.format('ddd')}
                  </Typography>
                  <Typography variant="h6" align="center">
                    {day.format('MMM D')}
                  </Typography>
                </Box>

                {/* Jobs list */}
                <Box sx={{ maxHeight: 400, overflow: 'auto', p: 1 }}>
                  {getJobsForDay(day).map((job) => (
                        <Card 
                        key={job.id}
                        sx={{ 
                          mb: 1, 
                          cursor: 'pointer',
                          '&:hover': { bgcolor: 'action.hover' },
                          borderLeft: '4px solid',
                          borderColor: getStatusDetails(job.status).chipColor,
                        }}
                        onClick={() => onJobClick(job)}
                      >
                      <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          {formatTimeRange(job)}
                        </Typography>
                        <Typography variant="body2" noWrap>
                          {job.customerName}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" noWrap>
                          {job.cleanerName}
                        </Typography>
                        <Box sx={{ mt: 0.5, display: 'flex', gap: 0.5 }}>
                        {job.recurring && (
                          <Chip 
                            label="Recurring" 
                            size="small" 
                            color="#008080" 
                            sx={{ height: 20 }}
                          />
                        )}
                        <Chip 
                          label={getStatusDetails(job.status).label} 
                          size="small"
                          sx={{ 
                            height: 20,
                            backgroundColor: getStatusDetails(job.status).chipColor,
                            color: 'white',
                          }}
                        />
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              </Paper>
            ))}
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
};

export default WeeklyJobsView;