// src/components/JobEditForm.js

import React, { useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Checkbox,
  FormControlLabel,
  Alert
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import RecurrenceSelect from './RecurrenceSelect';
import { formatRecurrenceRule } from '../utils/formatRecurrenceRule';
import moment from 'moment';

const JobEditForm = ({
  job,
  handleEditInputChange,
  handleAddRooms,
  handleDeleteRoom,
  openAddRoomsModal,
  setOpenAddRoomsModal,
  availableRooms,
  handleApplyToRecurringChange,
  applyToRecurring
}) => {

  const [timeError, setTimeError] = useState('');

  const isCompletedJob = job.status?.toLowerCase() === 'completed';

  // Format service cost from job data
  const getInitialServiceCost = () => {
    const cost = job.serviceCost || job.price || 0;
    const numericCost = parseFloat(String(cost).replace(/[^0-9.-]+/g, ''));
    return isNaN(numericCost) ? 0 : numericCost;
  };

  // Get the formatted date and times from the job data
  const getFormattedDateTime = () => {
    try {
      const startMoment = moment(job.appointmentDate.toDate?.() || job.appointmentDate);
      const endMoment = moment(
        (job.scheduledEndTime || job.appointmentEndDate)?.toDate?.() ||
          job.scheduledEndTime ||
          job.appointmentEndDate
      );

      const actualStartMoment = job.startTime?.toDate ? 
        moment(job.startTime.toDate()) : 
        job.startTime ? moment(job.startTime) : null;
      
      const actualEndMoment = job.endTime?.toDate ? 
        moment(job.endTime.toDate()) : 
        job.endTime ? moment(job.endTime) : null;

      return {
        date: startMoment.format('YYYY-MM-DD'),
        startTime: startMoment.format('HH:mm'),
        endTime: endMoment.format('HH:mm'),
        actualStartTime: actualStartMoment ? actualStartMoment.format('HH:mm') : '',
        actualEndTime: actualEndMoment ? actualEndMoment.format('HH:mm') : '',
        actualDate: actualStartMoment ? actualStartMoment.format('YYYY-MM-DD') : startMoment.format('YYYY-MM-DD')
      };
    } catch (error) {
      console.error('Error formatting date/time:', error);
      const currentMoment = moment();
      return {
        date: currentMoment.format('YYYY-MM-DD'),
        startTime: '09:00',
        endTime: '10:00',
        actualStartTime: '',
        actualEndTime: '',
        actualDate: currentMoment.format('YYYY-MM-DD')
      };
    }
  };

  const { date, startTime, endTime, actualStartTime, actualEndTime, actualDate } = getFormattedDateTime();

  // Get current actual times for display
  const currentTimes = {
    start: job.startTime ? moment(job.startTime.toDate?.() || job.startTime).format('LLLL') : 'Not set',
    end: job.endTime ? moment(job.endTime.toDate?.() || job.endTime).format('LLLL') : 'Not set'
  };

  // Validate time sequence
  const validateTimes = (date, start, end) => {
    if (!start || !end) return true;
    
    const startDateTime = moment(`${date} ${start}`);
    const endDateTime = moment(`${date} ${end}`);
    
    if (endDateTime.isBefore(startDateTime)) {
      setTimeError('End time cannot be before start time');
      return false;
    }
    
    setTimeError('');
    return true;
  };

  const formatTimeDisplay = (time) => {
    if (!time) return '';
    const formatted = moment(time, 'HH:mm').format('hh:mm A');
    return formatted;
  };

  const handleDateChange = (newDate) => {
    try {
      const startMoment = moment(`${newDate} ${startTime}`);
      const endMoment = moment(`${newDate} ${endTime}`);

      handleEditInputChange('appointmentDate', startMoment.toDate());
      handleEditInputChange('scheduledEndTime', endMoment.toDate());
    } catch (error) {
      console.error('Error updating dates:', error);
    }
  };

  const handleActualDateChange = (newDate) => {
    try {
      if (actualStartTime) {
        const startMoment = moment(`${newDate} ${actualStartTime}`);
        handleEditInputChange('startTime', startMoment.toDate());
      }
      if (actualEndTime) {
        const endMoment = moment(`${newDate} ${actualEndTime}`);
        handleEditInputChange('endTime', endMoment.toDate());
      }
    } catch (error) {
      console.error('Error updating actual dates:', error);
    }
  };

  const handleStartTimeChange = (newTime) => {
    try {
      const startMoment = moment(`${date} ${newTime}`);
      handleEditInputChange('appointmentDate', startMoment.toDate());
    } catch (error) {
      console.error('Error updating start time:', error);
    }
  };

  const handleEndTimeChange = (newTime) => {
    try {
      const endMoment = moment(`${date} ${newTime}`);
      handleEditInputChange('scheduledEndTime', endMoment.toDate());
    } catch (error) {
      console.error('Error updating end time:', error);
    }
  };

  const handleActualStartTimeChange = (newTime) => {
    try {
      const startMoment = moment(`${actualDate} ${newTime}`);
      handleEditInputChange('startTime', startMoment.toDate());
    } catch (error) {
      console.error('Error updating actual start time:', error);
    }
  };

  const handleActualEndTimeChange = (newTime) => {
    try {
      const endMoment = moment(`${actualDate} ${newTime}`);
      handleEditInputChange('endTime', endMoment.toDate());
    } catch (error) {
      console.error('Error updating actual end time:', error);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
      <Typography variant="h5" gutterBottom>
        Edit Job Details
      </Typography>

      <Grid container spacing={2}>
        {/* Customer Name */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Customer Name"
            value={job.customerName || ''}
            onChange={(e) => handleEditInputChange('customerName', e.target.value)}
            fullWidth
          />
        </Grid>
        {/* Cleaner Name */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Cleaner Name"
            value={job.cleanerName || ''}
            onChange={(e) => handleEditInputChange('cleanerName', e.target.value)}
            fullWidth
          />
        </Grid>
        {/* Address */}
        <Grid item xs={12}>
          <TextField
            label="Address"
            value={job.address || ''}
            onChange={(e) => handleEditInputChange('address', e.target.value)}
            fullWidth
          />
        </Grid>

        {/* Service Type and Cost */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="service-type-label">Service Type</InputLabel>
            <Select
              labelId="service-type-label"
              value={job.serviceType || 'one-time'}
              label="Service Type"
              onChange={(e) => {
                const newType = e.target.value;
                handleEditInputChange('serviceType', newType);
                handleEditInputChange('recurring', newType === 'recurring');
                if (newType === 'one-time') {
                  handleEditInputChange('recurrenceRule', '');
                }
              }}
            >
              <MenuItem value="one-time">One Time Service</MenuItem>
              <MenuItem value="recurring">Recurring Service</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Service Cost ($)"
            type="number"
            value={getInitialServiceCost()}
            onChange={(e) => {
              const value = e.target.value;
              handleEditInputChange('serviceCost', value);
              handleEditInputChange('price', value);
            }}
            InputProps={{
              inputProps: { min: 0, step: '0.01' },
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          {job.serviceType === 'recurring' && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyToRecurring}
                  onChange={(e) => handleApplyToRecurringChange(e.target.checked)}
                />
              }
              label="Apply changes to all future recurring instances"
            />
          )}
        </Grid>

        {/* Recurrence Information */}
        {job.serviceType === 'recurring' && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Recurrence Information
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {formatRecurrenceRule(job.recurrenceRule)}
            </Typography>
          </Grid>
        )}

        {/* Scheduled Date and Time Selection */}
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Scheduled Time
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Appointment Date"
            type="date"
            value={date}
            onChange={(e) => handleDateChange(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Start Time"
            type="time"
            value={startTime}
            onChange={(e) => handleStartTimeChange(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="End Time"
            type="time"
            value={endTime}
            onChange={(e) => handleEndTimeChange(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        {/* Recurrence Select - Only show if service type is recurring */}
        {job.serviceType === 'recurring' && (
          <Grid item xs={12}>
            <RecurrenceSelect
              value={job.recurrenceRule}
              onChange={(value) => handleEditInputChange('recurrenceRule', value)}
            />
          </Grid>
        )}

        {/* Total Size */}
        <Grid item xs={12}>
          <TextField
            label="Total Size (SqFt)"
            type="number"
            value={job.totalSize || ''}
            onChange={(e) => handleEditInputChange('totalSize', e.target.value)}
            InputProps={{
              inputProps: { min: 0, step: '1' },
            }}
            fullWidth
            placeholder="Enter total size in square feet"
          />
        </Grid>

        {/* Divider before Rooms */}
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>

        {/* Collapsible Rooms Section */}
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="rooms-content"
              id="rooms-header"
            >
              <Typography variant="h6">Rooms</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" justifyContent="flex-end" mb={1}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => setOpenAddRoomsModal(true)}
                >
                  Add/Edit Rooms
                </Button>
              </Box>
              <List>
                {job.rooms?.map((room, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={() => handleDeleteRoom(index)}
                        startIcon={<DeleteIcon />}
                      >
                        Delete
                      </Button>
                    }
                  >
                    <ListItemText
                      primary={`${room.name} (${room.quantity})`}
                      secondary={
                        room.tasks?.map((task, taskIndex) => (
                          <Typography
                            key={taskIndex}
                            variant="body2"
                            component="div"
                            sx={{ ml: 2 }}
                          >
                            • {task.description}
                          </Typography>
                        ))
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
        
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>

        {/* Actual Time Section with Helper Text */}
        {isCompletedJob && (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Actual Time
              </Typography>
              {timeError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {timeError}
                </Alert>
              )}
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Current Start Time: {currentTimes.start}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Current End Time: {currentTimes.end}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                label="Actual Date"
                type="date"
                value={actualDate}
                onChange={(e) => handleActualDateChange(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Actual Start Time"
                type="time"
                value={actualStartTime}
                onChange={(e) => handleActualStartTimeChange(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                helperText={`Current: ${formatTimeDisplay(actualStartTime)}`}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Actual End Time"
                type="time"
                value={actualEndTime}
                onChange={(e) => handleActualEndTimeChange(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                helperText={`Current: ${formatTimeDisplay(actualEndTime)}`}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>

        {/* Notes Section */}
        <Grid item xs={12}>
          <TextField
            label="Notes"
            value={job.notes || ''}
            onChange={(e) => handleEditInputChange('notes', e.target.value)}
            fullWidth
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Important Notes"
            value={job.importantNotes || ''}
            onChange={(e) => handleEditInputChange('importantNotes', e.target.value)}
            fullWidth
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default JobEditForm;
