// src/App.js
import React, { useState, useEffect, useMemo } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import {
  AuthProvider,
  useAuth,
  useRequireCleaner,
  useRequireMember,
} from "./context/AuthContext"; // Added imports here
import { TaskBoardProvider } from "./context/TaskBoardContext";
import { JobProvider } from "./context/JobContext";
import { GoogleMapsProvider } from "./components/GoogleMapsProvider";
import ClientSalesTools from "./components/ClientSalesTools";
import Layout from "./components/Layout";
import TaskBoard from "./components/TaskBoard";
import OrgSettings from "./components/OrgSettings";
import { TimezoneProvider } from "./context/TimeZoneContext";
import CleanerJobView from "./components/CleanerJobView";
import CleanerJobsOverview from "./components/CleanerJobsOverview";
import CleanerDashboard from "./components/CleanerDashboard";
import CreateJobForm from "./components/CreateJobForm";
import CustomerProfileManagement from "./components/CustomerProfileManagement";
import MobileNavigation from "./components/MobileNavigation";
import store from "./store";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import SignUp from "./components/SignUp";
import "./styles/global.css";
import axios from "axios";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { lightTheme, darkTheme } from "./theme";

axios.defaults.baseURL = "https://service.gemcitycleaningtools.com/dev/api";

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? children : <Navigate to="/login" />;
};

const CleanerRoute = ({ children }) => {
  const { user, loading } = useRequireCleaner();

  if (loading) {
    return <div>Loading...</div>;
  }

  return user?.role === "cleaner" ? children : null;
};

const MemberRoute = ({ children }) => {
  const { user, loading } = useRequireMember();

  if (loading) {
    return <div>Loading...</div>;
  }

  return user && user.role !== "cleaner" ? children : null;
};

const TaskBoardWithProvider = () => {
  const { user } = useAuth();

  if (!user) return null;

  return (
    <TaskBoardProvider organizationId={user.uid}>
      <TaskBoard />
    </TaskBoardProvider>
  );
};

const App = () => {
  const [isDarkMode, setDarkMode] = useState(() => {
    return localStorage.getItem("darkMode") === "true";
  });

  // Sync theme mode with body class and CSS variables
  useEffect(() => {
    const root = document.documentElement;
    root.setAttribute("data-mui-color-scheme", isDarkMode ? "dark" : "light");
    document.body.classList.toggle("dark-mode", isDarkMode);
  }, [isDarkMode]);

  // Toggle theme mode
  const toggleMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("darkMode", newMode);
      return newMode;
    });
  };

  const theme = useMemo(
    () => (isDarkMode ? darkTheme : lightTheme),
    [isDarkMode]
  );

  return (
    <Provider store={store}>
      <AuthProvider>
        <JobProvider>
          <TimezoneProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <GoogleMapsProvider>
                <Router>
                  <Routes>
                    {/* Auth Routes */}
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route path="/signup" element={<SignUp />} />

                    {/* Cleaner Routes */}
                    <Route
                      path="/cleaner-dashboard/:cleanerId"
                      element={
                        <PrivateRoute>
                          <CleanerRoute>
                            <CleanerDashboard />
                            <MobileNavigation
                              isDarkMode={isDarkMode}
                              toggleDarkMode={toggleMode}
                            />
                          </CleanerRoute>
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/cleaner-jobs/:cleanerId"
                      element={
                        <PrivateRoute>
                          <CleanerRoute>
                            <CleanerJobsOverview />
                            <MobileNavigation
                              isDarkMode={isDarkMode}
                              toggleDarkMode={toggleMode}
                            />
                          </CleanerRoute>
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/org/:orgId/job/:jobId"
                      element={
                        <PrivateRoute>
                          <CleanerRoute>
                            <CleanerJobView />
                            <MobileNavigation
                              isDarkMode={isDarkMode}
                              toggleDarkMode={toggleMode}
                            />
                          </CleanerRoute>
                        </PrivateRoute>
                      }
                    />

                    {/* Member/Admin Routes */}
                    <Route
                      path="/"
                      element={
                        <PrivateRoute>
                          <MemberRoute>
                            <Layout
                              isDarkMode={isDarkMode}
                              toggleDarkMode={toggleMode}
                            />
                          </MemberRoute>
                        </PrivateRoute>
                      }
                    >
                      <Route index element={<ClientSalesTools />} />
                      <Route
                        path="/task-board"
                        element={<TaskBoardWithProvider />}
                      />
                      <Route path="/org-settings" element={<OrgSettings />} />
                      <Route path="/jobs/*" element={<CreateJobForm />} />
                      <Route
                        path="/customer/:customerId"
                        element={<CustomerProfileManagement />}
                      />
                    </Route>

                    {/* Catch-all redirect */}
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </Router>
              </GoogleMapsProvider>
            </ThemeProvider>
          </TimezoneProvider>
        </JobProvider>
      </AuthProvider>
    </Provider>
  );
};

export default App;
