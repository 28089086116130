// src/components/CreateCustomerModal.js
import React, { useState, useEffect, useCallback } from 'react';
import { Autocomplete as GoogleAutocomplete } from '@react-google-maps/api';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
  Alert,
  Snackbar,
} from '@mui/material';
import styles from '../styles/CreateCustomerModal.module.css';
import { usePlaces } from '../hooks/usePlaces';

const CreateCustomerModal = ({ 
  open, 
  handleClose, 
  handleCreateCustomer, 
  customFields = [],
  isEditing = false,
  initialData = null
}) => {
  const defaultFormData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    serviceAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: ''
    },
    useSameAddress: true,
    billingAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: ''
    },
    customFieldValues: {},
    notes: '',
    isPrivateNote: false
  };

  // Form State
  const [formData, setFormData] = useState(defaultFormData);
  const [serviceAddressInput, setServiceAddressInput] = useState('');
  const [billingAddressInput, setBillingAddressInput] = useState('');

  // Places Autocomplete State
  const [serviceAutocomplete, setServiceAutocomplete] = useState(null);
  const [billingAutocomplete, setBillingAutocomplete] = useState(null);

  // Feedback State
  const [feedback, setFeedback] = useState({ open: false, message: '', severity: 'info' });

  // Places Hook
  const { loading: placesLoading, error: placesError, handlePlaceSelect: processPlace } = usePlaces();

  useEffect(() => {
    if (initialData && isEditing) {
      setFormData(initialData);
      setServiceAddressInput(initialData.serviceAddress?.complete || '');
      setBillingAddressInput(initialData.billingAddress?.complete || '');
    } else {
      setFormData(defaultFormData);
      setServiceAddressInput('');
      setBillingAddressInput('');
    }
  }, [initialData, isEditing, open]);

  const handleServiceAutocompleteLoad = useCallback((autocomplete) => {
    console.log('Service Autocomplete loaded:', autocomplete);
    setServiceAutocomplete(autocomplete);
  }, []);

  const handleBillingAutocompleteLoad = useCallback((autocomplete) => {
    console.log('Billing Autocomplete loaded:', autocomplete);
    setBillingAutocomplete(autocomplete);
  }, []);

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleAddressChange = (type, field, value) => {
    setFormData(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        [field]: value
      }
    }));
  };

  const handlePlaceSelect = async (type) => {
    const autocomplete = type === 'serviceAddress' ? serviceAutocomplete : billingAutocomplete;
    if (!autocomplete) {
      setFeedback({
        open: true,
        message: 'Address autocomplete not initialized properly',
        severity: 'error'
      });
      return;
    }

    try {
      const place = autocomplete.getPlace();
      console.log(`${type} place selected:`, place);

      if (!place.address_components) {
        setFeedback({
          open: true,
          message: 'Please select an address from the dropdown',
          severity: 'warning'
        });
        return;
      }

      const addressData = await processPlace(place);
      if (!addressData) {
        setFeedback({
          open: true,
          message: 'Could not process the selected address',
          severity: 'error'
        });
        return;
      }

      handleAddressChange(type, 'line1', addressData.line1);
      handleAddressChange(type, 'line2', addressData.line2);
      handleAddressChange(type, 'city', addressData.city);
      handleAddressChange(type, 'state', addressData.state);
      handleAddressChange(type, 'zip', addressData.zip);

      if (type === 'serviceAddress') {
        setServiceAddressInput(addressData.complete);
      } else {
        setBillingAddressInput(addressData.complete);
      }

    } catch (error) {
      console.error('Error processing place selection:', error);
      setFeedback({
        open: true,
        message: 'Error processing selected address',
        severity: 'error'
      });
    }
  };

  const handleSubmit = () => {
    const fullName = `${formData.firstName} ${formData.lastName}`.trim();
    
    const serviceAddressComplete = `${formData.serviceAddress.line1}, ${
      formData.serviceAddress.line2 ? formData.serviceAddress.line2 + ', ' : ''
    }${formData.serviceAddress.city}, ${formData.serviceAddress.state} ${formData.serviceAddress.zip}`.trim();

    const billingAddressComplete = formData.useSameAddress
      ? serviceAddressComplete
      : `${formData.billingAddress.line1}, ${
          formData.billingAddress.line2 ? formData.billingAddress.line2 + ', ' : ''
        }${formData.billingAddress.city}, ${formData.billingAddress.state} ${formData.billingAddress.zip}`.trim();

    const customerData = {
      name: fullName,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      serviceAddress: {
        ...formData.serviceAddress,
        complete: serviceAddressComplete
      },
      billingAddress: {
        ...(formData.useSameAddress ? formData.serviceAddress : formData.billingAddress),
        complete: billingAddressComplete
      },
      customFieldValues: formData.customFieldValues,
      notes: formData.notes,
      isPrivateNote: formData.isPrivateNote,
      ...(isEditing ? { updatedAt: new Date() } : { createdAt: new Date() })
    };

    handleCreateCustomer(customerData);
    handleClose();
    setFormData(defaultFormData);
    setServiceAddressInput('');
    setBillingAddressInput('');
  };

  const renderAutocompleteField = (type) => (
    <GoogleAutocomplete
      onLoad={type === 'serviceAddress' ? handleServiceAutocompleteLoad : handleBillingAutocompleteLoad}
      onPlaceChanged={() => handlePlaceSelect(type)}
      options={{
        componentRestrictions: { country: 'us' },
        fields: ['address_components', 'formatted_address', 'geometry'],
        types: ['address']
      }}
    >
      <TextField
        fullWidth
        label={`Search ${type === 'serviceAddress' ? 'Service' : 'Billing'} Address`}
        value={type === 'serviceAddress' ? serviceAddressInput : billingAddressInput}
        onChange={(e) => {
          if (type === 'serviceAddress') {
            setServiceAddressInput(e.target.value);
          } else {
            setBillingAddressInput(e.target.value);
          }
        }}
        id={`${type === 'serviceAddress' ? 'service' : 'billing'}-address-search`}
        error={!!placesError}
        helperText={placesError}
      />
    </GoogleAutocomplete>
  );

  const renderAddressFields = (type, disabled = false) => (
    <Box className="space-y-4">
      <div className="google-places-autocomplete">
        {renderAutocompleteField(type)}
      </div>
      <TextField
        label="Address Line 1"
        fullWidth
        value={formData[type].line1}
        onChange={(e) => handleAddressChange(type, 'line1', e.target.value)}
        disabled={disabled}
      />
      <TextField
        label="Address Line 2"
        fullWidth
        value={formData[type].line2}
        onChange={(e) => handleAddressChange(type, 'line2', e.target.value)}
        disabled={disabled}
      />
      <Box className="grid grid-cols-3 gap-4">
        <TextField
          label="City"
          fullWidth
          value={formData[type].city}
          onChange={(e) => handleAddressChange(type, 'city', e.target.value)}
          disabled={disabled}
        />
        <TextField
          label="State"
          fullWidth
          value={formData[type].state}
          onChange={(e) => handleAddressChange(type, 'state', e.target.value)}
          disabled={disabled}
        />
        <TextField
          label="ZIP Code"
          fullWidth
          value={formData[type].zip}
          onChange={(e) => handleAddressChange(type, 'zip', e.target.value)}
          disabled={disabled}
        />
      </Box>
    </Box>
  );

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{isEditing ? 'Edit Customer' : 'Create New Customer'}</DialogTitle>
        <DialogContent className="space-y-6">
          <Box className="grid grid-cols-2 gap-4 mt-4">
            <TextField
              label="First Name"
              fullWidth
              value={formData.firstName}
              onChange={(e) => handleChange('firstName', e.target.value)}
            />
            <TextField
              label="Last Name"
              fullWidth
              value={formData.lastName}
              onChange={(e) => handleChange('lastName', e.target.value)}
            />
          </Box>

          <Box className="grid grid-cols-2 gap-4">
            <TextField
              label="Email Address"
              type="email"
              fullWidth
              value={formData.email}
              onChange={(e) => handleChange('email', e.target.value)}
            />
            <TextField
              label="Phone Number"
              fullWidth
              value={formData.phone}
              onChange={(e) => handleChange('phone', e.target.value)}
            />
          </Box>

          <Divider />
          <Typography variant="h6">Service Address</Typography>
          {renderAddressFields('serviceAddress')}

          <FormControlLabel
            control={
              <Checkbox
                checked={formData.useSameAddress}
                onChange={(e) => handleChange('useSameAddress', e.target.checked)}
              />
            }
            label="Billing Address same as Service Address"
          />

          {!formData.useSameAddress && (
            <>
              <Typography variant="h6">Billing Address</Typography>
              {renderAddressFields('billingAddress')}
            </>
          )}

          <Divider />
          <Typography variant="h6">Custom Fields</Typography>
          {customFields.length > 0 ? (
            <Box className="space-y-4">
              {customFields.map((field) => (
                <TextField
                  key={field.id}
                  label={field.label}
                  fullWidth
                  value={formData.customFieldValues[field.id] || ''}
                  onChange={(e) => {
                    setFormData(prev => ({
                      ...prev,
                      customFieldValues: {
                        ...prev.customFieldValues,
                        [field.id]: e.target.value
                      }
                    }));
                  }}
                />
              ))}
            </Box>
          ) : (
            <Typography color="textSecondary">No Custom Fields Created</Typography>
          )}

          <Divider />
          <Typography variant="h6">Customer Notes</Typography>
          <TextField
            multiline
            rows={4}
            fullWidth
            value={formData.notes}
            onChange={(e) => handleChange('notes', e.target.value)}
            placeholder="Enter customer notes here..."
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.isPrivateNote}
                onChange={(e) => handleChange('isPrivateNote', e.target.checked)}
              />
            }
            label="Make this note private"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            onClick={handleSubmit}
            disabled={!formData.firstName || !formData.lastName || !formData.serviceAddress.line1}
            variant="contained"
            color="primary"
          >
            {isEditing ? 'Save Changes' : 'Create Customer'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={() => setFeedback(prev => ({ ...prev, open: false }))}
      >
        <Alert severity={feedback.severity} onClose={() => setFeedback(prev => ({ ...prev, open: false }))}>
          {feedback.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateCustomerModal;