// /src/components/CustomerManagement.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { 
  Typography, Button, List, ListItem, ListItemText, Box, CircularProgress, Alert,
  IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField,
  Pagination, Paper,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import ClientCSVUpload from './ClientCSVUpload';
import CreateCustomerModal from './CreateCustomerModal';
import styles from '../styles/CustomerManagement.module.css';

const ITEMS_PER_PAGE = 10;

const CustomerManagement = ({ orgId }) => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [customFields, setCustomFields] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchCustomers();
    fetchCustomFields();
  }, []);

  const handleCustomerClick = (e, customer) => {
    // Prevent triggering click when clicking edit/delete buttons
    if (e.target.closest('.MuiIconButton-root')) {
      e.stopPropagation();
      return;
    }
    navigate(`/jobs/clients/${customer.id}`);
  };

  const fetchCustomers = async () => {
    try {
      const customersRef = collection(db, 'organizations', orgId, 'customers');
      const snapshot = await getDocs(customersRef);
      const customerList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCustomers(customerList.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (err) {
      setError('Error fetching customers: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomFields = async () => {
    try {
      const fieldsRef = collection(db, 'organizations', orgId, 'customFields');
      const snapshot = await getDocs(fieldsRef);
      const fieldsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCustomFields(fieldsList);
    } catch (err) {
      console.error('Error fetching custom fields:', err);
      setCustomFields([]);
    }
  };

  // Filter customers based on search term
  const filteredCustomers = customers.filter(customer => 
    customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.serviceAddress?.complete?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.billingAddress?.complete?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditCustomer = (customer) => {
    // Transform customer data to match form structure
    const editData = {
      firstName: customer.firstName || '',
      lastName: customer.lastName || '',
      email: customer.email || '',
      phone: customer.phone || '',
      serviceAddress: {
        line1: customer.serviceAddress?.line1 || '',
        line2: customer.serviceAddress?.line2 || '',
        city: customer.serviceAddress?.city || '',
        state: customer.serviceAddress?.state || '',
        zip: customer.serviceAddress?.zip || ''
      },
      useSameAddress: customer.billingAddress?.complete === customer.serviceAddress?.complete,
      billingAddress: {
        line1: customer.billingAddress?.line1 || '',
        line2: customer.billingAddress?.line2 || '',
        city: customer.billingAddress?.city || '',
        state: customer.billingAddress?.state || '',
        zip: customer.billingAddress?.zip || ''
      },
      customFieldValues: customer.customFieldValues || {},
      notes: customer.notes || '',
      isPrivateNote: customer.isPrivateNote || false
    };
    setEditingCustomer({ ...customer, ...editData });
    setOpenEditModal(true);
  };

  const handleUpdateCustomer = async (updatedData) => {
    setLoading(true);
    try {
      // Combine first and last name
      const fullName = `${updatedData.firstName} ${updatedData.lastName}`.trim();
      
      // Create complete addresses
      const serviceAddressComplete = `${updatedData.serviceAddress.line1}, ${
        updatedData.serviceAddress.line2 ? updatedData.serviceAddress.line2 + ', ' : ''
      }${updatedData.serviceAddress.city}, ${updatedData.serviceAddress.state} ${updatedData.serviceAddress.zip}`.trim();

      const billingAddressComplete = updatedData.useSameAddress
        ? serviceAddressComplete
        : `${updatedData.billingAddress.line1}, ${
            updatedData.billingAddress.line2 ? updatedData.billingAddress.line2 + ', ' : ''
          }${updatedData.billingAddress.city}, ${updatedData.billingAddress.state} ${updatedData.billingAddress.zip}`.trim();

      const customerData = {
        name: fullName,
        firstName: updatedData.firstName,
        lastName: updatedData.lastName,
        email: updatedData.email,
        phone: updatedData.phone,
        serviceAddress: {
          ...updatedData.serviceAddress,
          complete: serviceAddressComplete
        },
        billingAddress: {
          ...(updatedData.useSameAddress ? updatedData.serviceAddress : updatedData.billingAddress),
          complete: billingAddressComplete
        },
        customFieldValues: updatedData.customFieldValues,
        notes: updatedData.notes,
        isPrivateNote: updatedData.isPrivateNote,
        updatedAt: new Date()
      };

      const customerRef = doc(db, 'organizations', orgId, 'customers', editingCustomer.id);
      await updateDoc(customerRef, customerData);
      await fetchCustomers();
      setOpenEditModal(false);
      setEditingCustomer(null);
    } catch (err) {
      setError('Error updating customer: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCustomer = async (customerId) => {
    if (window.confirm('Are you sure you want to delete this customer?')) {
      setLoading(true);
      try {
        await deleteDoc(doc(db, 'organizations', orgId, 'customers', customerId));
        await fetchCustomers();
      } catch (err) {
        setError('Error deleting customer: ' + err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCustomersCreated = async (newCustomers) => {
    setLoading(true);
    try {
      const customersRef = collection(db, 'organizations', orgId, 'customers');
      for (const customer of newCustomers) {
        await addDoc(customersRef, customer);
      }
      fetchCustomers();
      alert(`${newCustomers.length} customers added successfully!`);
    } catch (err) {
      setError('Error adding customers: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateCustomer = async (newCustomer) => {
    setLoading(true);
    try {
      const customersRef = collection(db, 'organizations', orgId, 'customers');
      await addDoc(customersRef, newCustomer);
      await fetchCustomers();
      setOpenCreateModal(false);
    } catch (err) {
      setError('Error adding customer: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress className={styles.loader} />;
  if (error) return <Alert severity="error" className={styles.alert}>{error}</Alert>;

  const paginatedCustomers = filteredCustomers.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );

  return (
    <Box className={styles.container}>
      <Typography variant="h4" className={styles.title}>
        Customer Management
      </Typography>
      
      <Box className={styles.actions}>
        <Button 
          variant="contained" 
          onClick={() => setOpenCreateModal(true)}
          className={styles.createButton}
        >
          Create New Customer
        </Button>
        <Box className={styles.uploadSection}>
          <ClientCSVUpload 
            onCustomersCreated={handleCustomersCreated} 
            className={styles.uploadButton}
          />
          <Typography variant="body2" className={styles.csvInfo}>
            (CSV headers: FirstName, LastName, Email, Phone, ServiceAddress, BillingAddress, Notes)
          </Typography>
        </Box>
      </Box>

      <Box className={styles.searchContainer}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search customers..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          className={styles.searchField}
        />
      </Box>

      <Paper className={styles.customersList}>
        <List>
          {paginatedCustomers.map((customer) => (
            <ListItem 
              key={customer.id}
              className={styles.customerItem}
              onClick={(e) => handleCustomerClick(e, customer)}
              sx={{ cursor: 'pointer' }}
              secondaryAction={
                <Box className={styles.itemActions} onClick={e => e.stopPropagation()}>
                  <IconButton 
                    onClick={() => handleEditCustomer(customer)}
                    className={styles.editButton}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton 
                    onClick={() => handleDeleteCustomer(customer.id)}
                    className={styles.deleteButton}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              }
            >
              <ListItemText 
                primary={customer.name}
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="textSecondary">
                      {customer.email && `${customer.email} • `}
                      {customer.phone && `${customer.phone} • `}
                      {customer.serviceAddress?.complete}
                    </Typography>
                  </>
                }
                className={styles.customerText}
              />
            </ListItem>
          ))}
        </List>
        
        <Box className={styles.pagination}>
          <Pagination 
            count={Math.ceil(filteredCustomers.length / ITEMS_PER_PAGE)}
            page={page}
            onChange={(e, value) => setPage(value)}
            color="primary"
          />
        </Box>
      </Paper>

      {/* Create Modal */}
      <CreateCustomerModal
        open={openCreateModal}
        handleClose={() => setOpenCreateModal(false)}
        handleCreateCustomer={handleCreateCustomer}
        customFields={customFields}
      />

      {/* Edit Modal */}
      <CreateCustomerModal
        open={openEditModal}
        handleClose={() => {
          setOpenEditModal(false);
          setEditingCustomer(null);
        }}
        handleCreateCustomer={handleUpdateCustomer}
        customFields={customFields}
        isEditing={true}
        initialData={editingCustomer}
      />
      </Box>
  );
};

export default CustomerManagement;